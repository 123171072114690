.modal-faq {
    counter-reset: my-counter;

    .v-pad {

        &:first-child {
            padding-top: 0;
        }

        .question-title {
            &:before {
                content: counter(my-counter) ".";
                counter-increment: my-counter;
                position: relative;
                left: 0;
                top: 0;
                width: 25px;
            }
        }

        .answer {
            padding: 20px 0 5px 21px;
        }
    }

    .grey-line {
        bottom: 0;
        left: -$gutter-horizontal;
        position: relative;
        width: calc(100% + #{$gutter-horizontal * 2});
        border-bottom: 1px solid palette($grey, 'light');
    }
}
