@import "mixins";

/*
##Block
```
<!-- Sometimes we really need to block something -->
<span class="block center">text</span>
```
*/
.block {
    display: block;
}

/*
##Hidden
```
<!-- Hide element.  -->
<span class="hidden">text</span>
```
*/
.hidden {
    display: none;
}

/*
##Accessible hidden
```
<!-- When .  -->
<span class="accessible-hidden">text</span>
```
*/
.accessible-hidden {
    position: absolute !important;
    top: -9999px;
    left: 0; /* A negative value here would calculate the element's height incorrectly. Very weird */
}

.pad {
    padding: 20px;
}

.pad-big {
    padding: 30px;
}

.pad-small {
    padding: 15px;
}

.pad-mini {
    padding: 10px;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.align-middle > * {
    vertical-align: middle;
}

.centerize {
    margin-left: auto;
    margin-right: auto;
    float: none;
}

.clickable {
    cursor: pointer;
}

.slides {
    overflow: hidden;
    position: relative;
    .slide {
        float: left;
        position: relative;
    }
}

.verticalize {
    display: table;
    height: 100%;

    .cell,
    .content {
        display: table-cell;
        vertical-align: middle;
    }
}

.no-overflow {
    overflow: hidden;
}

//------------------------------------
// Typography
//------------------------------------

.uppercase {
    text-transform: uppercase;
}

.break-all {
    word-break: break-all;
}

.no-wrap {
    white-space: nowrap;
}

.center {
    text-align: center;
}

.align-right {
    text-align: right;
}