.balance-widget {
    text-align: center;

    .icon {
        background-image: url('/images/icons/deposit-widget-icon.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 26px;
        margin: 0 auto 8px;

    }
}
