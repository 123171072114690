@import "toolkit";

.page-404 {
    background-color: palette($grey, lighter);
    min-height: 400px;
    padding: 100px 0;
    img {
        width: 80%;
    }
}
