//------------------------------------
// Media queries
//------------------------------------

@mixin respond-to($media) {
    @if ($media == normal) {
        @content;
    } @else if map-get($breakpoints, $media) {
        @media #{map-get($breakpoints, $media)} {
            @content;
        }
    }
}

@mixin respond-with($values-map, $property) {
    @each $media, $value in $values-map {
        @include respond-to($media) {
            #{$property}: $value;
        }
    }
}

//------------------------------------
// Global helpers
//------------------------------------

@mixin padding {
    padding: $gutter-vertical $gutter-horizontal;
}

@mixin transform($value) {
    // TODO (luke) Should be removed and using the property instead.
    transform: $value;
}

@mixin transition($values...) {
    @if length($values) == 0 {
        $values: all 0.3s ease;
    }
    transition: $values;
}
