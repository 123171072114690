.list {

    .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid palette($grey, lighter);
        @include padding;

        &:active:not(.inactive), &.active {
            // custom color as there is nothing in the palette that fits this need and doesnt fade into the overlay background on tablet
            background: #e6eeee;
        }

        &:first-child {
            border-top: 1px solid palette($grey, lighter);
        }

        > * {
            margin-right: $gutter;
        }

        > *:last-child {
            margin-right: 0;
        }

        .item-body {
            flex: 1;
        }

        &.disabled {
            img {
                opacity: 0.5;
            }

            .icon {
                opacity: 0.25;
            }
        }
    }

    .before-list-item {
        margin: $gutter-vertical $gutter-horizontal 0;
    }

    .list-item-actions {
        display: flex;

        > * {
            margin-right: $gutter-horizontal;
        }

        > *:last-child {
            margin-right: 0;
        }
    }

    &.grey {
        background-color: palette($grey, lighter);

        .list-item {
            border-color: palette($grey, light);
        }
    }
}

.spaced-item-list {
    li {
        margin: 0 em(5);
    }

    li:first-child {
        margin-left: 0;
    }

    li:last-child {
        margin-right: 0;
    }
}

.list-inline {
    display: inline-block;
}

.objectives-list {
    color: palette($grey, dark);

    .list-item {
        padding: $gutter-vertical ($gutter-horizontal / 2);
        border-top: 1px solid palette($grey);

        &:first-child {
            border: 0;
        }
    }

    .list-item-current {
        background: palette($orange);
        color: $white;
        border-top: 0;

        + .list-item {
            border-top: 0;
        }
    }

    .list-item-completed {
        background: palette($grey, light);
        color: palette($grey, dark);
    }

    .progress-count {
        color: palette($orange);
        background: $white;
        padding: 0.4em 0.7em;
        border-radius: 2em;
        margin-right: 0.25em;
    }
}
