.offline-message {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include respond-with($offline-message-heights, height);
    @include respond-with($offline-message-heights, line-height);

    transform: translate3d(0, -100%, 0);

    background: palette($red);
    padding: 0 $gutter-horizontal / 2;
    color: palette($grey, lightest);
    font-size: $font-size-small;
    transition: transform 300ms ease;
}

.offline {

    .modal-context, .fade, .pages-container, .main-menu {
        @include respond-with($offline-message-heights, top);
    }

    .offline-message {
        transform: translate3d(0, 0, 0);
    }
}