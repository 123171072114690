.top-bar.faq {
    @include respond-to(tablet) {
        background-color: palette($red);
        width: 100%;
        background-image: url($wave_image) !important;
        border: none !important;
        background-size: 40px;
        background-repeat: repeat-x;
        background-position: bottom;

        .top-bar-text {
            width: 100%;
            color: palette($purple, 'darkest');
        }
    }

    @include respond-to(mobile) {
        .top-bar-text {
            display: none;
        }
    }
}

.page-faq {

    .headline-bar {
        height: 100px;
    }

    .header-wave {
        background-image: url($wave_image);
        background-size: 40px;
        background-repeat: repeat-x;
        background-position: bottom;
        height: 100%;
        display: flex;
    }

    .page-heading {
        align-self: center;
    }

    .item-block {
        height: 75px;
        border-bottom: 1px solid palette($grey, 'light');
        padding-left: $gutter-horizontal * 1.5;
        padding-right: $gutter-vertical;
    }

    .question-group-container {
        margin-top: 40px;

        .question-group {
            counter-reset: my-counter;

            .questions-and-answers {
                min-height: 30px;
                border-bottom: 1px solid palette($grey, 'light');
                margin: 10px 0;
                padding-bottom: 10px;

                .question-title {
                    &:before {
                        content: counter(my-counter) ".";
                        counter-increment: my-counter;
                        position: relative;
                        left: 0;
                        top: 0;
                        width: 25px;
                    }
                }
            }
        }
    }
}

.page-faq, .modal-faq {

    .question-title {
        align-items: stretch;

        .question-text {
            flex: 1 100%;
            padding-right: 5px;
        }
    }
}
