.modal-tabs {
    display: flex;
    text-align: center;
}

.modal-tab {
    flex: 1;
    background: palette($grey, lighter);
    padding: $gutter-vertical 0;

    margin-right: 2px;
    border-bottom: 2px solid transparent;

    &:last-child {
        margin-right: 0;
    }

    .text {
        border-top: 3px solid transparent;
        border-bottom: 3px solid palette($grey, lighter);
        padding: 3px 2px;
    }

    &.selected {
        border-bottom-color: palette($grey, darker);
    }
}
