@import 'toolkit';

$challenge-color: palette($orange);
$lighter-challenge-color: palette($grey, lighter);
$challenge-completed-color: palette($teal, lightest);
$challenge-completed-color-lighter: palette($teal, lighter);
$challenge-completed-border: palette($teal, light);

.quest-promo {
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.current-quest-container {
    height: 360px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;

    &.smaller-quest-container {
        height: 210px;
    }
}

.quest-path-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 52px;
    margin-top: -10px;
    transition: all;

    .quest-scroll {
        max-width: 100px;
    }
}

.current-quest-box {
    background: $challenge-color;
}

.page .current-quest-box {
    background: $challenge-color;
    z-index: 1;
    border-radius: 3px 3px 0 0;
    max-width: 350px;
    margin: 0 30px;
    flex: 1;
    position: relative;
}

.quest-scroll {
    margin: 0 auto;
    max-width: 260px;

    .quest-scroll-top,
    .quest-scroll-bottom {
        height: 12px;
        margin: 0 -20px;
        background-image: url('/images/quests/scroll-end.gif');
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.25);
        position: relative;
        z-index: 1;
        border-radius: 1px;
    }

    .quest-scroll-content {
        position: relative;
        background: palette($yellow, lightest);
        padding: 20px;
        text-align: center;

        &:after,
        &:before {
            content: '';
            position: absolute;
            top: 0;
            width: 20px;
            height: 100%;
            background-repeat: repeat-y;
        }

        &:before {
            background-image: url('/images/quests/scroll-border-left.png');
            left: -20px;
        }

        &:after {
            background-image: url('/images/quests/scroll-border-right.png');
            right: -20px;
        }
    }
}

.quests {
    display: flex;
    flex-flow: row wrap;
    z-index: 0;
    position: relative;

    .quest-icon {
        font-size: 32px;
    }

    .quest-icon--big {
        font-size: 42px;
    }
}

.quest-tile {
    width: 50%;
}

.quest {
    display: inline-block;
    margin: 3px 17px;
    width: 60px;
    height: 60px;
    background: palette($grey, lighter);
    border: 2px solid palette($grey);
    color: palette($grey, dark);
    border-radius: 10px;
    @include transform(rotate(-45deg));
    @include transition(all 0.5s ease);
    position: relative;
    box-sizing: border-box;
}

.quest-inner {
    display: inline-block;
    @include transform(rotate(45deg));
    height: 100%;
    width: 100%;
}

.quest-completed {
    background: $challenge-completed-color-lighter;
    border-color: $challenge-completed-border;
    color: $challenge-completed-color;
    background-clip: padding-box;

    .icon-state-normal {
        opacity: 0;
    }

    .icon-state-completed {
        opacity: 1;
    }
}

.quest-disabled {
    background: palette($grey, lighter);
    border-color: palette($grey, light);
    color: palette($grey, light);
    background-clip: padding-box;

    .icon-state-disabled {
        opacity: 1;
    }

    .icon-state-normal {
        opacity: 0;
    }
}

.quest-tile--current {
    z-index: 999 !important;
    position: relative;

    .quest {
        background: $challenge-color;
        border-color: rgba(0, 0, 0, 0);
    }

    .icon-state-normal {
        color: $white;
    }
}

.half {
    text-align: right;
}

.half:nth-child(2n) {
    text-align: left;
}

.full {
    width: 100%;
    text-align: center;
}

.half + .full,
.full + .half,
.full + .half + .half {
    margin-top: -19px;
}

.quest-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translateX(-50%) translateY(-50%));
    opacity: 0;
    transition: opacity 0.5s ease;
}

.icon-state-normal {
    opacity: 1;
}

.quest-valuable {
    margin-top: 5px;
    width: 46px;
}

.quests--small {
    .quest {
        width: 46px;
        height: 46px;
        margin: 0 13px;
    }

    .quest-valuable {
        width: 32px;
    }

    .half + .full,
    .full + .half,
    .full + .half + .half {
        margin-top: -12px;
    }

    .quest-icon {
        font-size: 24px;
    }

    .quest-icon--big {
        font-size: 32px;
    }
}

.circle-button {
    background: $white;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 1;
}

.quest-leaderboard-dialog {
    .prize-image-block {
        padding-top: 1rem;

        img {
            display: block;
            margin: 0 auto;
        }
    }
}
