.split-value {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;

    .split-value-label {
        text-align: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 34px;
        transition: all 0.3s;

        @include respond-to(tablet-landscape) {
            font-size: $font-size-large;
        }

        label {
            display: block;
        }
    }

    .split-value-value {
        width: 100%;

        @include respond-to(tablet) {
            width: 70%;
            margin: 0 auto;
        }

        .value-wrapper {
            display: flex;
            padding: 10px;
            justify-content: center;
        }

        span {
            font-size: em(18);
            text-align: center;
            max-width: 1.4em;
            justify-content: center;
            flex: 1;
            flex-shrink: 0;
            display: flex;
            margin: 0 em(1);
            min-height: 1.3em;
            border-bottom: 2px solid palette($grey, light);

            .creditcard & {
                &:nth-of-type(4n+1) {
                    margin-left: em(6);
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &:not(:empty) {
                border-bottom-color: palette($grey, darkest);

                &.is-bad {
                    border-bottom-color: palette($red);
                }
            }

            &:empty {
                animation: cursor 0.5s alternate infinite;

                &::after {
                    color: palette($grey, light);
                    content: attr(data-placeholder);
                }

                ~ span {
                    border-bottom-color: palette($grey, light);
                    animation: none;
                }
            }
        }
    }
}

@keyframes cursor {
    0% {
        border-bottom-color: palette($grey, light);
    }

    100% {
        border-bottom-color: $teal;
    }
}
