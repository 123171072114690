/* Fonts */

/* Layout */

/* Fonts */

/* Touch */

/* Animations */

.help-container {
    display: none;
}

.help-container.open {
    display: block;
    z-index: 99999;
    width: 100%;
}

.help-wrapper {
    background: #ffffff;
    transition: transform 0.2s ease;
}

@mixin olark-tablet-height($values-map) {
    @each $media, $value in $values-map {
        @include respond-to($media) {
            height: calc(100vh - (3 * #{$value} + 100px));
        }
    }
}

.casumo-bar {
    transform: translate3d(0, 0, 0);
    width: 100%;
    position: absolute;
    bottom: -1px !important;
    min-height: 420px;

    @include olark-tablet-height($heights-menu-item);
}

.casumo-bar.help-wrapper {
    z-index: 31;
    bottom: 0;
    transform: translateX(-100%);
    border-right: solid 2px #EAF4F5;
}

.casumo-bar.help-wrapper:hover, .casumo-bar.help-wrapper.visible {
    transform: translateX(-100%);
}

.casumo-bar.help-wrapper:hover:not(.open), .casumo-bar.help-wrapper.visible:not(.open) {
    cursor: pointer;
}

.casumo-bar.help-wrapper.open {
    transform: translateX(0);
}

.casumo-bar.help-wrapper.open:after {
    display: none;
}

.help-link {
    background: rgba(239, 246, 246, 0.9);
    color: #444f4f;
    bottom: 0;
    left: 0;
    height: 50px;
    z-index: 31;
}

@media (max-height: 784px) {
    .help-link {
        height: 40px;
    }
}

.help-link .help-toggler {
    padding: 0 25px;
    line-height: 50px;
}

@media (max-height: 784px) {
    .help-link .help-toggler {
        line-height: 40px;
    }
}

.help-link .help-toggler:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2%;
    height: 100%;
    background: #aab8b8;
}

#habla_window_div {
    margin: 0 !important;
}

#habla_both_div {
    display: none !important;
}

#habla_pre_chat_div {
    height: auto !important;
}

#habla_wcsend_input {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

#hbl_body_message,
#habla_pre_chat_span,
#habla_offline_message_span {
    text-align: center;
    display: block;
    margin-bottom: 20px;
}

#habla_pre_chat_span {
    margin: $gutter-vertical 0 $gutter-vertical * 2 0;
    color: palette($grey);
    padding: 0 $gutter-horizontal * 2;
}

#hbl_body_message a,
#habla_pre_chat_span a,
#habla_offline_message_span a {
    margin-bottom: 10px;
}

#hbl_body_message {
    color: palette($grey, dark);
}

.habla_offline_submit_input {
    margin-top: 20px;
}

#olark-messages {
    overflow: auto;
}

.habla_conversation_person1,
.habla_conversation_person2 {
    display: block;
    padding: 0.85em 0.85em 0 0.85em;
    margin: 2px 30px 0 20px;
    border-radius: 5px 5px 0 0;
    font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #13813d;
    background: #19ac51;
}

.olark-avatar {
    left: 24px;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    z-index: 99;
}

.habla_conversation_text_span {
    display: block;
    position: relative;
    padding: 0 0.85em 0.85em 0.85em;
    margin: -1px 30px 0 20px;
    border-radius: 0 0 5px 5px;
    color: #ffffff;
    background: #19ac51;
}

.habla_conversation_text_span a {
    color: #303838;
    text-decoration: underline;
    word-break: break-all;
}

.habla_conversation_text_span:before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    height: 10px;
    width: 16px;
    background: url('/images/olark/speach-bubble-left.png') 0 0 no-repeat;
}

.habla_conversation_person1 {
    height: 0;
    text-indent: -999px;
    background: #cad7d7;
    margin: 0 20px 0 30px;

    & + .habla_conversation_text_span {
        background: #cad7d7;
        color: #444f4f;
        margin: -2px 20px 0 30px;
        word-wrap: break-word;

        a {
            color: #444f4f;
            text-decoration: underline;
        }

        &:before {
            left: auto;
            right: 0;
            background-image: url('/images/olark/speach-bubble-right.png');
        }
    }
}

.olark-composing-message {
    display: none;
}

.habla_conversation_p_item + .habla_conversation_p_item {
    margin-top: -10px;
}

.habla_conversation_p_item + .habla_conversation_p_item.olrk_new_sender {
    margin-top: 2px;
    margin-bottom: 2px;
}

.olark-feedback-question {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
}

.olark-feedback-low-answer {
    text-indent: -900em;
    background-image: url('/images/olark/sad.jpg');
    width: 20px;
    height: 20px;
    float: left;
}

.olark-feedback-high-answer {
    text-indent: -900em;
    background-image: url('/images/olark/happy.jpg');
    width: 20px;
    height: 20px;
    float: right;
}

.olark-feedback-question-number {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 10px;
    margin: 10px 0 0 0;
    color: #0d5629;
}

.olark-feedback-submit {
    background-color: #ffc930;
    color: #96603c !important;
    border-color: #ffc930;
    width: 100% !important;
}

.olark-feedback-choices-wrap {
    width: 100% !important;
    float: left;
    display: flex;
    justify-content: space-between;
}

.olark-feedback-choices-wrap .olark-feedback-radio {
    width: 15px;
    height: 15px;
    border-radius: 200px;
    background-color: white;
    margin: 10px auto 0 auto;
    display: inline-block;
}

.olark-feedback-text {
    border-radius: 6px;
    font-size: 12px;
    width: 176px;
    padding: 10px;
    border: 0;
    margin: 0;
}

//------------------------------------
// Olark poop
//------------------------------------
.habla_wcsend_field {
    color: palette($grey, darkest);
    @extend .bold;
    overflow: hidden;
    outline: 0;
    background: none;
    resize: none;
    @include font($light);
    border: 0;
    border-bottom: 1px solid palette($grey, 'lighter');
    display: block;
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    height: 1.8rem !important;
    &:focus {
        border-color: palette($grey);
    }
    .light & {
        color: $white;
        border-color: palette($grey);
        &:focus {
            border-color: $white;
        }
    }
}

.is-good {
    color: palette($green);
}

#habla_chatform_form .habla_wcsend_field, #habla_offline_message_div .habla_wcsend_field {
    height: $gutter-horizontal * 2.2 !important;
    padding: 1.2em $gutter-horizontal + $gutter-vertical / 2 1.2em $gutter-horizontal;
    background: palette($grey, 'lighter');
    color: palette($grey, 'darkest')
}

#habla_pre_chat_name_input, #habla_pre_chat_email_input {
    margin-bottom: $gutter-vertical * 1.5;
}

#habla_offline_body_input {
    height: 7em !important; //Olark adds inline styles
}

.habla_button,
.habla_offline_submit_input,
#habla_pre_chat_span a,
#hbl_body_message a,
#habla_offline_message_span a,
.habla_conversation_p_item button {
    text-align: center;
    padding: 0;
    display: flex;
    margin: $gutter-horizontal * 2 auto;
}

.habla_offline_submit_input {
    min-width: 16.2em;
    justify-content: center;
}

.habla_offline_submit_input,
.habla_conversation_p_item button[data-action="decline"] {
    @include button-background(button-color($button-color-green));
    &:active {
        @include button-background(darken(button-color($button-color-green), 5%));
    }
}

#habla_pre_chat_span a,
#hbl_body_message a,
#habla_offline_message_span a {
    @include button-background(button-color($button-grey-light));
    &:active {
        @include button-background(darken(button-color($button-grey-light), 5%));
    }
    color: palette($grey,
        dark);
}

.habla_conversation_p_item button {
    color: $white;
    margin: em(16) $gutter-vertical / 4 em(8);
    padding: em(10);
    color: palette($green, darker);
    width: 70%;
    &[data-action="start"],
    &[data-action="decline"] {
        width: 45%;
        display: block;
        display: inline-block;
    }
    &[data-action="start"] {

    }
    &[data-action="decline"] {
        border-color: $white;
        color: $white;
    }
}

span.habla_conversation_text_span.hbl_pal_main_fg.habla_conversation_notification {
    padding-left: $gutter-horizontal - $gutter-vertical + $gutter-vertical / 2;
}

span.habla_conversation_text_span.hbl_pal_main_fg.habla_conversation_notification span {
    text-align: left !important;
}

&.help-wrapper {
    z-index: $z-help;
    bottom: 0;
    @include transform(translateX(-100%));
    &:hover,
    &.visible {
        @include transform(translateX(-96%));
        &:not(.open) {
            cursor: pointer;
        }
    }
    &.open {
        @include transform(translateX(0));
        &:after {
            display: none;
        }
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 4%;
        background: palette($grey, lighter);
    }
}

.modal-help {
    .help {
        padding: 0;
    }
    .intro {
        color: palette($grey);
        padding: 0 $gutter-horizontal * 2;
    }
    .habla_offline_submit_input {
        padding: 0 $gutter-horizontal;
    }
}

.operator {
    margin: 0 0 $gutter-vertical / 2 $gutter-vertical / 2;
}

.margin-text {
    margin-left: 64px;
}

.operator-name {
    margin-top: $gutter-vertical / 4;
}

.operator-message {
    margin-top: $gutter-vertical / 2;
    color: palette($grey, lighter);
    word-break: break-all;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-3px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(3px, 0, 0);
    }
}

.olark-chat {
    color: palette($grey, dark);
    font-size: 2.2em !important;
}

.startChatBtn {
    margin: $gutter-vertical * 2 auto;
    text-align: center;
    display: block;
}

.habla_conversation_p_item {
    @include respond-to(mobile) {
        .habla_conversation_person2 {
            display: none;
        }
        .habla_conversation_text_span {
            padding-left: 72px;
        }
    }

    @include respond-to(tablet) {
        .habla_conversation_person2 {
            display: none;
        }
    }

    @include respond-to(tablet-landscape) {
        .habla_conversation_person2 {
            display: none;
        }
        .habla_conversation_text_span {
            padding-left: 72px;
        }
    }
}

.habla_conversation_person2 + .habla_conversation_text_span {
    padding-top: $gutter-horizontal / 2;
}

.habla_conversation_p_item:first-child .habla_conversation_person1 ~ .habla_conversation_text_span {
    padding-left: $gutter-horizontal - $gutter-vertical / 2;
}

@keyframes bounceIn {
    from, 20%, 40%, 60%, 80%, to {
        animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    20% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        transform: scale3d(.9, .9, .9);
    }

    60% {
        opacity: 1;
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        transform: scale3d(.97, .97, .97);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.bounceIn {
    animation-name: bounceIn;
}

.habla_conversation_p_item {
    animation: bounceIn 0.82s;
    position: relative;
}

.habla_conversation_p_item.olrk_new_sender, .habla_conversation_p_item:first-child {
    @include respond-to(mobile) {
        .habla_conversation_person2 {
            display: block;
            padding-top: 1em;
            padding-left: 72px;
        }
        .olark-person2:before {
            border: 2px solid #09903D;
            border-radius: 50px;
            height: 50px;
            width: 50px;
            content: '';
            float: left;
            margin-left: -62px;
            margin-top: -2px;
            position: absolute;
            z-index: 9999999;
            background-repeat: no-repeat;
            background-position: center;
            will-change: transform;
        }
    }

    @include respond-to(tablet-landscape) {
        .habla_conversation_person2 {
            display: block;
            padding-top: 1em;
            padding-left: 72px;
        }
        .olark-person2:before {
            border: 2px solid #09903D;
            border-radius: 50px;
            height: 50px;
            width: 50px;
            content: '';
            float: left;
            margin-left: -62px;
            margin-top: -2px;
            position: absolute;
            z-index: 9999999;
            background-repeat: no-repeat;
            background-position: center;
            will-change: transform;
        }
    }

    @include respond-to(tablet) {
        .habla_conversation_person2 {
            display: block;
        }
    }

}

@include respond-to(mobile) {
    .habla_conversation_p_item:last-child .habla_conversation_person2:before {
        border: 2px solid #09903D;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        content: '';
        float: left;
        margin-left: -62px;
        margin-top: -2px;
        position: absolute;
        z-index: 9999999;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@include respond-to(tablet-landscape) {
    .habla_conversation_p_item:last-child .habla_conversation_person2:before {
        border: 2px solid #09903D;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        content: '';
        float: left;
        margin-left: -62px;
        margin-top: -2px;
        position: absolute;
        z-index: 9999999;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.olrk_avatar {
    display: none;
}

.habla_conversation_p_item + .habla_conversation_p_item .habla_conversation_person1 ~ .habla_conversation_text_span {
    padding-left: 14px;
}

// chat flexing
#habla_middle_div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

$field_hight: 52.8px;
$field_outside: calc(100% - #{$field_hight});

#habla_middle_wrapper_div {
    flex: 1;
    position: relative;
    min-height: $field_outside;
    max-height: $field_outside;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
}

#habla_chatform_form {
    .hbl_txt_wrapper {
        margin: 0;
    }

    #habla_wcsend_input:placeholder {
        border: 5px solid red;
    }
}

#habla_conversation_div {
    box-sizing: border-box;
    transition: all 0.4s ease;
    transform: translate3d(0, 0, 1px);
    overflow: visible !important;
    height: auto !important;
}

#habla_input_div {
    position: relative;
}

.olark-send {
    color: palette($grey, dark);
    color: #6f7b7b;
    padding: 0 10px;
    box-sizing: content-box;
    display: block;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    line-height: 100%;
    width: 1em;

    &::before {
        position: absolute;
        top: 50%; left: 50%;
        margin: -0.5em auto auto -0.5em;
    }
}

.olark-send-active {
    color: palette($grey, darkest);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
}

.olark-login {
    position: absolute;
    width: 100%;
    left: 0;
    box-content: border-box;
    z-index: 1;

    & + .olrk-noquirks {
        position: absolute;
        width: 100%;
        height: calc(100% - 94px);
        top: 94px;
        left: 0;
    }

    @include respond-to(tablet) {
        & + .olrk-noquirks {
            top: 44px;
            height: calc(100% - 44px);
        }
    }
}

/*# sourceMappingURL=help.css.map */
