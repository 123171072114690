.games-search {
    .input {
        background-color: palette($grey, lighter);
        @include padding;

        input {
            width: 80%;
            border: 0;
            background-color: palette($grey, lighter);
            height: $base-icon-font-size;
        }
    }

    .search-results {
        li {
            border-bottom: 2px solid palette($grey, lighter);
        }

        .search-list-item {
            height: 60px;

            span.icon {
                padding: $gutter;
            }

            .game-name {
                width: 80%;

                &:active {
                    background-color: palette($grey, darkest);
                    color: palette($grey, lighter) !important;

                    & + .game-link {
                        color: palette($grey, lighter) !important;
                    }
                }
            }

            .game-link {
                width: 20%;

                &:active {
                    background-color: palette($grey, darkest);
                    color: palette($grey, lighter) !important;
                }

                span.icons-more {
                    text-align: center;
                }
            }

        }

    }
}
