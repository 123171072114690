.login-form {
    background: url('/images/backgrounds/space.png') 50% 0;
    background-size: 100% auto;

    input {
        color: $white !important;
    }

    .password-visibility-toggle {
        font-size: $font-size-large;
    }

    .welcome-text {
        @include respond-to(tablet) {
            margin-top: 20px;
        }
    }

    .login-fields {
        @include respond-to(tablet) {
            padding: 78px 0;
        }
    }
}

.login-page {
    .list-item {
        height: em(62);
    }
}
