.trophies-page {
    position: relative;
}

.trophies-container {
    width: 100%;

    .trophy-info-box {
        &.ghost {
            position: fixed;
            height: auto;
            left: -1000px;
            top: -1000px;
        }

        align-items: center;
        position: absolute;
        z-index: 1;
        left: 0;
        background-color: palette($purple);
        width: 100%;
        color: palette($grey, lightest);
        min-height: 80px;
        padding: 10px;

        .text-area {
            padding: 0 ($gutter-horizontal / 2);
            text-align: left;
        }

        .purple-text {
            color: palette($purple);
        }
    }

    .trophy-container {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        margin-bottom: 10px;
        text-align: center;
        margin-left: -2px; //Dependant on font-size, but safe in our case
        margin-right: -2px; //Dependant on font-size, but safe in our case

        @include respond-to(mobile-landscape) {
            width: 16.7%;
        }

        @include respond-to(tablet-landscape) {
            width: 16.7%;
        }

        .empty-trophy {
            display: block;
            background-color: palette($grey, light);
            width: 64px;
            height: 64px;
            border-radius: 10px;
            margin: 0 auto;
        }
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid palette($purple);
        margin: 0 auto;
    }
}
