.casumo-holding-valuables {

    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 140%;

    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        @include position(absolute, 0, 0, 0, 0);
        background: transparent no-repeat center center;
        background-size: 100%;
    }

    &:before {
        background-image: url('/images/casumos/casumo-holding-valuables-body.png');
    }

    &:after {
        background-image: url('/images/casumos/casumo-holding-valuables-arms.png');
    }

    .valuables {
        @include position(absolute, 0, 10%, 19%, 10%);
        z-index: 0;

        > * {
            position: absolute;
        }

        li {
            width: 100%;
            padding-bottom: 100%;
        }

        img {
            width: 100%;
            position: absolute;
            z-index: 1;

        }

        @for $i from 1 through 7 {
            > *:nth-child(#{$i}) {
                z-index: 7 - ($i - 1);
                bottom: ($i - 1) * 17%;
            }
        }
    }

}
