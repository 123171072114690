.top-bar.playokay {
    @include respond-to(tablet) {
        background-color: palette($green);
        width: 100%;
        background-image: url($wave_image) !important;
        border: none !important;
        background-size: 40px;
        background-repeat: repeat-x;
        background-position: bottom;

        .top-bar-text {
            width: 100%;
            color: palette($purple, 'darkest');
        }
    }

    @include respond-to(mobile) {
        .top-bar-text {
            display: none;
        }
    }
}

.page-playokay {

    .headline-bar {
        background-color: palette($green);
        height: 100px;
    }

    .header-wave {
        background-image: url($wave_image);
        background-size: 40px;
        background-repeat: repeat-x;
        background-position: bottom;
        height: 100%;
        display: flex;
    }

    .page-heading {
        align-self: center;
    }

    .item-block {
        height: 75px;
        border-bottom: 1px solid palette($grey, 'light');
    }

}

.page-playokay, .modal-playokay {
    .items {
        .item:last-child {
            .link {
                margin-bottom: 0;
            }
        }
    }

    .item {
        display: flex;
        .title {
            margin: $gutter-vertical / 4 0;
        }
    }

    .list-item {
        &:first-child {
            @extend .gutter-top;
        }
        color: palette($blue);
        border-left: solid 4px palette($blue);
        margin: 4px (- $gutter-horizontal);
        padding: $gutter-horizontal / 2 $gutter-vertical / 2 + 4;
        flex-direction: column;
    }

    .link-item {
        @extend .flex-vertical;
        border: none;
        .text {
            color: black;
        }
        .link {
            margin: $gutter-vertical $gutter-horizontal * -1;
        }
    }
}
