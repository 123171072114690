.adventure-page {
    position: relative;
    padding-bottom: 50px;
}

.player-top-header-container {
    height: 200px;
    position: relative;
    overflow: hidden;

    @include respond-to(tablet-landscape) {
        overflow: hidden;
        width: 100%;
        height: 324px;
    }

    @include respond-to(tablet-portrait) {
        width: 100%;
        height: 240px;
    }

    .progress-donut {
        margin-top: 4%;
    }

    .player-top-image-space {
        width: 100%;
        height: 100%;
        background-size: cover;
        position: absolute;

        @include respond-to(tablet-landscape) {
            height: 578px;
            width: 100%;
        }

        @include respond-to(tablet-portrait) {
            height: 430px;
            width: 100%;
        }
    }

    .player-top-image-planet {
        @include position(absolute, 0, 0, 0, 0);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center 100%;
    }

    .header {
        color: $white;
        z-index: 1;
        position: absolute;
        width: 100%;
        padding-top: 10px;

        @include respond-to(tablet-landscape) {
            position: relative;
            top: 25px;
        }

        @include respond-to(tablet-portrait) {
            position: relative;
            top: 25px;
        }
    }
}
