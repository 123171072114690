//Get a color
//--------------------------------------------------------------------------------------------
//Parameters:
//
//    - Color: Any of the color variables above
//    - Shade (optional): Any of the shade variables above. Defaults to `$base`.
//
//Usages:
//
//    .element {
//        color: palette($teal);
//    }
//
//    .element {
//        background: palette($blue, $light);
//    }

@function palette($color, $shade: base) {
    $shadeIndex: index($shadeNames, $shade);
    $colors: nth($palette, $color);
    @return nth($colors, $shadeIndex);
}

@function get($array, $grid) {
    @return nth($array, $grid);
}

@function strip-unit($number) {
    @return $number / ($number * 0 + 1);
}

@function em($px, $base: $default-font-size) {
    @return ($px * 1px) / $base * 1em;
}