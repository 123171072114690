@import 'toolkit/functions';
@import 'mixins';

// The progress donut must always be positioned inside a square container. Use
// margins, paddings or the .responsive-square class to achieve this.

.progress-donut {

    position: relative;
    width: 100px;
    height: 100%;
    margin: 0 auto;
    margin-top: 5px;
    z-index: 1;

    @include respond-to(tablet-portrait) {
        width: 25%;
    }

    @include respond-to(tablet-landscape) {
        width: 25%;
    }

    @include respond-to(mobile-landscape) {
        width: 17%;
    }

    .casumo-progress-container {

        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 50%;
        z-index: 2;

        &.container-casumo {
            @include position(absolute, 15%, 15%, 15%, 15%);
        }

        &.container-spaceship {
            @include position(absolute, 0, 0, 0, 0);
            background-image: image('images/casumos/spaceship.png') !important;
        }
    }

    .donut-wrapper {

        // promote to own layer for performance
        transform: translate3d(0, 0, 0);

        @include position(absolute, 0, 0, 0, 0);
        z-index: 1;

        svg {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .donut {
        @include transform(rotate(-170deg));
        width: 100%;
        height: 100%;

        circle {
            stroke-linecap: round;
            @include transition(stroke 0.3s ease);

            &.transparent {
                fill: rgba(0, 0, 0, 0);
            }

            &.half-transparent {
                fill: rgba(0, 0, 0, 0.5);
            }
        }

        &.animating {
            .fill {
                @include transition(stroke 0s ease);
                stroke: $white;
            }
        }
    }

    .level {
        @include position(absolute, 46%, auto, auto, 0);

        left: -16%;
        height: 23%;
        display: flex;
        // scss-lint:disable DuplicateProperty
        justify-content: space-between; //Android 2.3 flex spec fallback
        justify-content: space-around;
        // scss-lint:enable DuplicateProperty
        flex-direction: column;
        margin-top: -0.5em;
        color: palette($teal);
        text-align: center;
        border: 1px solid;
        border-radius: 20px;
        background-color: $white;
        width: 35px;

        .text {
            color: palette($grey, darkest);
            line-height: 1;
        }

        @include respond-to(tablet-portrait) {
            width: 40px;
        }

        @include respond-to(tablet-landscape) {
            width: 55px;
            left: -16%;
        }
    }

}
