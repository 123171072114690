@import "toolkit";

table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 1px;
    border-collapse: separate;
    border: 0;

    th,
    td {
        padding: 5px 8px;
    }

    .no-pad {
        padding: 0;
    }

    td:not([align]) {
        text-align: left;
    }

    tr:nth-child(2n) td {
        background: palette($grey, lighter);
    }

    th {
        @include font($bold);
        background: palette($grey, light);
    }
}

.leaderboard-table {
    table-layout: auto;
}

.white-table {
    border-collapse: collapse;

    th, tr {
        background-color: $white;
        text-align: left;
    }

    tr:nth-child(2n) td {
        background-color: $white;
    }
}
