* {
    box-sizing: border-box;
}

body {
    background: palette($grey, light);
}

body,
.app,
.pages,
.main-menu,
.pages-container {
    @include position(absolute, 0, 0, 0, 0);
    overflow: hidden;
}

.main-menu {
    @include respond-with($widths-menu, width);
    z-index: $z-index-menu;
    transform: translate3d(-30%, 0, 0);
    transition: all 0.3s ease;

    .menu-active & {
        transform: translate3d(0, 0, 0);
    }

    @include respond-to(tablet) {
        transform: translate3d(0, 0, 0);
        right: auto;
    }
}

.pages-container {
    z-index: $z-index-pages-container;
    background: $white;
    transition: all 0.3s ease;
    @include respond-with($widths-menu, left);

    .menu-active & {
        transform: translate3d(100%, 0, 0);
    }

    @include respond-to(tablet) {
        right: 0;

        .menu-active & {
            transform: translate3d(0, 0, 0);
        }
    }

    .top-bar {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: $z-index-top-bar;
        @include respond-with($top-bar-heights, height);
        @include respond-with($widths-main-content, width);

        @include respond-to(tablet) {
            left: 50%;
            transform: translateX(-50%);
            padding: 5px 0;
        }
    }

    .pages {
        @include respond-with($top-bar-heights, padding-top);
    }

    .page {
        @include respond-with($widths-main-content, width);

        @include respond-to(tablet) {
            margin: 0 auto;
        }

        &.fit {
            @include respond-with($top-bar-heights, top);
            @include respond-to(tablet) {
                position: static;
                min-height: 600px;
            }
        }
    }
}

.menu-footer {
    background: $white;
}

.menu-footer-wave {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==');
    background-size: contain;
    height: 6px;
}

.page-footer {
    padding: $gutter-vertical 0;

    .footer-inner {
        @include respond-with($widths-main-content, width);
        margin: 0 auto;
    }

    .divider {
        border-bottom: 3px solid palette($grey, lighter);
        padding-bottom: $gutter-vertical;
        margin-bottom: $gutter-vertical;
    }

    .terms-container {
        margin-left: $gutter-horizontal;
        font-size: $font-size-smallest;
    }
}

.page-footer-wave {
    margin-top: 50px;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTMwIiB2aWV3Qm94PSIwIDAgNTAwIDEzMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTE1CiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDExNSAxMDAsMTUgMjUwLDE1CiAgICAgICAgICAgICAgICAgICAgICAgICBNMjUwLDE1CiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDE1IDQwMCwxMTUgNTAwLDExNSIgc3Ryb2tlLXdpZHRoPSIzMCIgc3Ryb2tlPSIjZWZmNmY2IiBmaWxsPSJub25lIj48L3BhdGg+Cjwvc3ZnPg==');
    background-size: contain;
    height: 9px;
}

.global-loader-container {
    position: absolute;
    z-index: $z-index-loader;
    bottom: 0;
    height: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease;

    &.loading {
        transition-delay: 200ms;
        transform: translateY(-50px);

        .ring {
            animation: loader-ring 1s infinite ease-in;
        }
    }
}

