@import 'toolkit';

.decorated {
    text-decoration: underline;
}

.seperate-row {
    border-top: 1px solid palette($grey, lighter);
    border-bottom: 1px solid palette($grey, lighter);
}

.separate-bottom {
    border-bottom: 1px solid palette($grey, lighter);
}

.separate-top {
    border-top: 1px solid palette($grey, lighter);
}

.separate-children {

    > * {
        border-bottom: 1px solid palette($grey, lighter);

        &:last-child {
            border-bottom: none;
        }
    }
}

.white-background {
    background-color: $white;
}

.circled {
    border-radius: 50%;
    border: 2px solid currentColor;
    padding: 3px;
}

.imprinted-code {
    display: inline-block;
    background-image: url('../../images/elements/coupon-code-bg.png');
    background-repeat: repeat-x;
    padding: 0 15px;
    color: palette($teal);
    font-weight: bold;
    height: 36px;
    line-height: 36px;
}

.separator {
    border-bottom: 2px solid palette($grey, lighter);
    height: 0;
    font-size: 0;
}

.drop-shadow {
    @include vendor(filter, drop-shadow(6px 12px 0 rgba(0, 0, 0, 0.4)));
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0.5);
    }

    to {
        opacity: 0;
        transform: scale(2.5);
    }
}

.ink {
    display: block;
    position: absolute;
    background-color: rgba($white, 0.3);
    transform: scale(0);
    z-index: 1;

    border-radius: 10000px; // Android 2.3 hack
    opacity: 0; // Android hack

    &.animate {
        animation: ripple 0.3s linear;
    }
}

.can-expand {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: palette($grey, darkest) transparent transparent;
        border-width: 8px 5px;
        margin-top: -4px;
    }

    &.is-expanded:after {
        border-color: transparent transparent palette($grey, darkest);
        margin-top: -12px;
    }
}

@media (orientation: landscape) {

    .landscape-group {

        font-size: 1em;
        display: inline;

        &.end.gutter-bottom-big:after {
            content: '';
            display: block;
            height: 0;
            margin-bottom: $gutter-vertical * 2;
        }

        &.end.gutter-bottom-small:after {
            content: '';
            display: block;
            height: 0;
            margin-bottom: $gutter-vertical / 2;
        }
    }

}
