.global-loader {
    width: 100px;
    height: 100px;
    background-color: palette($grey, lighter);
    border-radius: 50%;
    position: relative;
    left: 50%;
    margin-left: -50px;
    transform: rotate(45deg);

    .ring {
        border-radius: 50%;
        position: absolute;
        border-width: 10px;
        border-style: solid;
        top: 50%;
        left: 50%;

        &:nth-child(1) {
            border-color: transparent palette($orange) palette($orange) transparent;
            width: 44px;
            height: 44px;
            margin-left: -22px;
            margin-top: -22px;
            animation-delay: 200ms;
        }

        &:nth-child(2) {
            border-color: transparent palette($yellow) palette($yellow) transparent;
            width: 64px;
            height: 64px;
            margin-left: -32px;
            margin-top: -32px;
            animation-delay: 100ms;
        }

        &:nth-child(3) {
            border-color: transparent palette($teal) palette($teal) transparent;
            width: 84px;
            height: 84px;
            margin-left: -42px;
            margin-top: -42px;
        }
    }
}

@keyframes loader-ring {
    0% {
        transform: rotate(0);
    }

    30% {
        transform: rotate(170deg);
    }

    70% {
        transform: rotate(190deg);
    }

    100% {
        transform: rotate(359deg);
    }
}
