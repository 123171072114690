.top-bar {
    display: flex;
    flex-direction: row;
    background-color: palette($grey, darkest);

    &.background-logo {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 120px;
    }

    .top-bar-action {

        display: flex;

        a {
            width: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 32px;
        }

    }

    .top-bar-text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: palette($grey, light);
    }

    .sub-nav {
        height: 100%;
        display: flex;
        flex: 1;
        flex-basis: auto;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 10px;

        li {
            position: relative;
            font-size: $font-size-smaller;
            display: flex;

            @include respond-to(tablet) {
                margin: 10px 0;
            }
        }

        a {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            color: palette($grey);
            padding: 0 0.7em;

            .icon {
                margin-bottom: 5px;
                display: block; // necessary for android to render correctly
            }

            &.active {
                border-bottom: 4px solid currentColor;
                padding-top: 4px;
            }
        }

        @include respond-to(tablet) {
            background-color: $white;

            a {
                color: $default-text-color;
            }
        }
    }

    @include respond-to(tablet) {
        background: $white;
        border-bottom: 1px solid palette($grey, lighter);

        .sub-nav {
            justify-content: center;

            [class^="icons-"],
            [class*=" icons-"] {
                font-size: 32px;
            }

            a {
                margin: 0 10px;
            }
        }
    }

    .icons-custom-menu {
        @include css-accelerate;
        background: $custom-grey-dark;
        border-radius: 50%;

        span {
            background: palette($yellow);
        }

        span::before {
            background: palette($teal);
        }

        span::after {
            background: palette($orange);
        }
    }
}

@include respond-to(mobile) {
    .top-bar.player {
        .top-bar-action,
        .active {
            color: palette($teal);
        }
    }

    .top-bar.cash {
        .top-bar-action,
        .active {
            color: palette($yellow);
        }
    }

    .top-bar.games {
        .top-bar-action,
        .active {
            color: palette($orange);
        }
    }
}
