.top-banner {
    padding-bottom: 60%;
    background-size: cover;
    position: relative;
}

.selling-points {
    background: palette($blue, darkest);

    ul {
        text-align: left;
        display: inline-block;
        list-style-type: disc;
    }

    img {
        width: 25%;
        height: auto;
        margin-bottom: 5px;
    }
}

.selling-point-row {
    padding: $gutter-vertical 0;
    margin: 0 em(35);
    border-top: 2px solid palette($teal);

    &:first-child {
        border: 0;
    }
}

.start-page {
    .logos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .logo-group {
        text-align: center;
        width: 100%;
    }

    .award-logos {
        img {
            width: 25%;
            margin-right: 5%;
            max-width: 70px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .payment-logos {
        max-width: 420px;
        margin-top: $gutter-vertical / 2;

        img {
            margin: 2%;
        }
    }

    @include respond-to(tablet-landscape) {
        .logos {
            flex-direction: row;
        }

        .logo-group {
            text-align: left;
            width: 50%;
        }

        .payment-logos {
            margin-top: 0;
            text-align: right;

            img {
                text-align: right;
            }
        }
    }
}
