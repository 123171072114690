@import 'toolkit';

@function button-color($button) {
    @return nth($button, 2);
}

@mixin button-background($color) {
    background-color: $color;
    border-color: $color;
}

// We really prefer having this one like a "table".
// scss-lint:disable SpaceAfterComma
$button-grey-lighter:           "grey-lighter",     palette($grey, lighter),    palette($grey, light),             palette($grey);
$button-grey-light:             "grey-light",       palette($grey, light),      palette($grey);
$button-grey-dark:              "grey-dark",        palette($grey, dark),       palette($grey, darker);
$button-grey-darker:            "grey-darker",      palette($grey, darker),     palette($grey, darkest);
$button-color-purple-darker:    "purple-darker",    palette($purple, darker),   palette($purple, darkest);
$button-color-blue:             "blue",             palette($blue),             palette($blue, dark);
$button-color-blue-darkest:     "blue-darkest",     palette($blue, darkest),    palette($blue, darker);
$button-color-teal:             "teal",             palette($teal),             palette($teal, dark);
$button-color-teal-darkest:     "teal-darkest",     palette($teal, darkest),    palette($teal, dark);
$button-color-green:            "green",            palette($green),            palette($green, dark);
$button-color-green-light:      "green-light",      palette($green, light),     palette($green);
$button-color-yellow:           "yellow",           palette($yellow),           palette($yellow, dark),             palette($grey, darkest);
$button-color-yellow-dark:      "yellow-dark",      palette($yellow, dark),   palette($yellow, darker),          palette($grey, darkest);
$button-color-yellow-darkest:   "yellow-darkest",   palette($yellow, darkest),  palette($yellow, darker);
$button-color-orange:           "orange",           palette($orange),           palette($orange, dark);
$button-color-red-light:        "red-light",        palette($red, light),       palette($red);
$button-color-red:              "red",              palette($red),              palette($red, dark);
$button-color-red-dark:         "red-dark",         palette($red, dark),        palette($red, darker);
$button-color-purple:           "purple",           palette($purple),           palette($purple, dark);
$button-color-white:            "white",            $white,                     palette($grey, lighter);
// scss-lint:enable SpaceAfterComma

//Construct buttons array
$buttons:
    $button-grey-lighter,
    $button-grey-light,
    $button-grey-dark,
    $button-grey-darker,
    $button-color-blue,
    $button-color-teal,
    $button-color-teal-darkest,
    $button-color-green,
    $button-color-green-light,
    $button-color-orange,
    $button-color-yellow,
    $button-color-yellow-dark,
    $button-color-yellow-darkest,
    $button-color-white,
    $button-color-red-light,
    $button-color-red,
    $button-color-red-dark;

@keyframes loading {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 12px 0;
    }
}

@each $button in $buttons {
    // ## #{nth($button, 1)}
    // ```
    // <p class="gutter-bottom-small">
    //     <a class="button #{nth($button, 1)}">#{nth($button, 1)} normal button</a>
    //     <a class="button #{nth($button, 1)} hollow">#{nth($button, 1)} hollow button</a>
    //     <a class="button #{nth($button, 1)} loading">#{nth($button, 1)} loading button</a>
    // </p>
    // <p class="gutter-bottom-small">
    //     <a class="button #{nth($button, 1)} big">#{nth($button, 1)} big button</a>
    // </p>
    // <p class="gutter-bottom-small"><a class="button #{nth($button, 1)} full-width center">#{nth($button, 1)} full width button</a></p>
    // ```
    .button.#{nth($button, 1)}, button.#{nth($button, 1)} {
        @include button-background(button-color($button));

        &:active:not(:disabled) {
            @include button-background(darken(button-color($button), 5%));
        }

        &.hollow {
            border-color: button-color($button);
            color: button-color($button);
            background: transparent;
        }

        &.link {
            border: 0;
            color: button-color($button);
            background: transparent;
        }

        &.loading {
            @include button-background(nth($button, 3));
        }

        @if length($button) == 4 {
            color: nth($button, 4);
        }
    }
}

button,
button.button {
    text-align: center;
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
}

button,
button.button {
    display: inline-block; // button elements on mobile wont center text when inline-flex
}

.square-button {
    @include font($bold);
    font-size: $font-size-regular;
    min-width: 50px;
    min-height: 50px;
    line-height: 1em;
    text-align: center;
    background-color: palette($grey, 'lighter');
    padding: em(22) $gutter-horizontal;

    &.half-width {
        width: 50%;
    }

    &.left-white-border {
        border-left: 2px solid $white;
    }

    &.active {
        border-bottom: 2px solid palette($grey, 'darkest');
    }
}

button,
.button, .habla_offline_submit_input {
    @include font($bold);
    font-size: $font-size-regular;
    color: $white;
    min-width: 50px;
    min-height: 50px;
    line-height: 1em;
    border-width: 2px;
    border-style: solid;
    position: relative;
    border-radius: 2em;
    text-align: center;
    padding: 0 15px;

    &:disabled {
        border-color: palette($grey);
        background-color: palette($grey);
        color: $white;
    }

    &.loading,
    &.busy {
        color: transparent;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkMxQjAwOEVGMjJGMTFFMzkxREJBRkFDQTYzOUNFNzAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkMxQjAwOEZGMjJGMTFFMzkxREJBRkFDQTYzOUNFNzAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyQzFCMDA4Q0YyMkYxMUUzOTFEQkFGQUNBNjM5Q0U3MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyQzFCMDA4REYyMkYxMUUzOTFEQkFGQUNBNjM5Q0U3MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhwTbkAAAAAhSURBVHjaYmRgYFBjwAL+//9/C5s4EwOJYFTDSNEAEGAA4U4EFWD7wBAAAAAASUVORK5CYII=);
        animation: loading 1s linear infinite;
    }

    &.full-width {
        width: 100%;
    }

    &.square {
        border-radius: 0;
    }

    &.big {
        min-width: 60px;
        min-height: 60px;
    }

    &.short {
        min-width: 40px;
        min-height: 40px;
    }

    &.wide {
        min-width: 155px;
    }

    &.h-spacing {
        margin-left: em(10);
        margin-right: em(10);
    }

    &.h-spacing-big {
        margin-left: em(20);
        margin-right: em(20);
    }

    [class^='icons-'],
    [class*=' icons-'] {
        &:first-child {
            margin-right: 5px;
        }

        &:last-child {
            margin-left: 5px;
        }
    }
}

.choices {
    margin: $gutter-vertical 0;

    .button {
        @include font($light);
        border: 0;
        padding: 0 20px;
        color: palette($grey, dark);
        background: palette($grey, lighter);
        min-width: 20%;

        &:active {
            color: palette($grey, darker);
            background: palette($grey, light);
        }

        &.selected {
            background: palette($grey, dark);
            color: $white;

            &:active {
                background: palette($grey, darker);
            }
        }
    }

}
