.valuables {

    .valuables-header,
    .no-valuables-message {
        height: 84px;
        background-color: palette($grey, lighter);
        border-bottom: 1px solid palette($grey, light);
    }

    .valuables-header {
        @include respond-to(tablet) {
            display: none;
        }
    }
}
