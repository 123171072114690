.page-history {
    a {
        color: palette($grey, 'darkest');
    }

    .date-picker-container {
        height: 50px;
        border-bottom: 1px solid palette($grey, 'lighter');
        margin: 2px 0 0 15px;

        input {
            font-size: $font-size-small;
        }
    }

    table th, table td {
        width: 20%;

        &:first-child {
            width: 40%;
        }
    }

    .game-name {
        border-top: 1px solid palette($grey, 'lighter');
        height: 40px;
        padding: 0 7px;
        margin: 5px 0;
        background-color: palette($grey, 'lighter');
    }
}
