.games-list {
    overflow: auto; // Clearing floats
}

// Also used in play page but this seemed the best place to put it
.game-maintenance-mode {
    background: rgba(palette($grey, light), 0.85);
    @include position(absolute, 0, 0, 0, 0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        max-width: 100px;
        text-align: center;
    }
}

.game-maintenance-mode-title {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 17px;
}

.game-tile {
    float: left;
    width: 50%;
    overflow: hidden;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    z-index: 2;
    @include css-accelerate;

    &:nth-child(4n+1),
    &:nth-child(4n) {
        background-color: palette($grey, lighter);
    }

    .tile-inner {
        width: 100%;
        padding-bottom: 120%;
    }

    @include respond-to(tablet-landscape) {
        width: 25%;

        &:nth-child(4n+1),
        &:nth-child(4n) {
            background-color: $white;
        }

        &:nth-child(8n+1),
        &:nth-child(8n+3),
        &:nth-child(8n+6),
        &:nth-child(8n+8) {
            background-color: palette($grey, lighter);
        }
    }

    @include respond-to(tablet-portrait) {
        width: 33.3%;

        &:nth-child(4n+1),
        &:nth-child(4n) {
            background-color: $white;
        }

        &:nth-child(6n+1),
        &:nth-child(6n+3),
        &:nth-child(6n+5) {
            background-color: palette($grey, lighter);
        }
    }

    @include respond-to(mobile-landscape) {
        width: 25%;

        &:nth-child(4n+1),
        &:nth-child(4n) {
            background-color: $white;
        }

        &:nth-child(8n+1),
        &:nth-child(8n+3),
        &:nth-child(8n+6),
        &:nth-child(8n+8) {
            background-color: palette($grey, lighter);
        }
    }

    .game-logo {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .game-title {
        font-size: 12px;
        height: 20px;
        display: flex;
        align-items: center;

        a {
            color: $white;
        }
    }

    .game-info-layer {
        padding: 15% $gutter-horizontal;
        color: $white;
        @include position(absolute, 0, 0, 0, 0);
        background: rgba(palette($teal), 0.9);
        display: flex;
        z-index: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        visibility: hidden;

        @include respond-to(tablet) {
            padding: 10% $gutter-horizontal;
        }
    }

    .game-link {
        color: palette($grey, darkest);
        background: $white;
        border-radius: 50%;
    }

    .play-button {
        color: palette($grey, darkest);
        padding: 22px;
        background: $white;
        border-radius: 50%;
    }

    .more-info {
        height: 35px;
        width: 35px;
    }

    &.selected {
        .game-info-layer {
            visibility: visible;
        }
    }
}
