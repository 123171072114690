@import 'mixins';
@import 'toolkit/functions';

//------------------------------------
// Fonts
//------------------------------------

$font-light: Circular-Pro-Book, Helvetica, Arial, sans-serif;
$font-bold: Circular-Pro-Black, Helvetica, Arial, sans-serif;
$font-bold-italic: Circular-Pro-BlackItalic, Helvetica, Arial, sans-serif;

$fonts: $font-light, $font-bold, $font-bold-italic;

$light: 1;
$bold: 2;
$bold-italic: 3;

$base-font-size: 14px;
$base-icon-font-size: 24px;
$default-font-size: $base-font-size;
$zoomed-font-size: 16px;

$default-text-color: palette($grey, darkest);

$font-size-smallest: em(10);
$font-size-smaller: em(11);
$font-size-small: em(12);
$font-size-regular: em(14);
$font-size-medium: em(16);
$font-size-large: em(18);
$font-size-larger: em(21);

//------------------------------------
// Breakpoints
//------------------------------------
$breakpoints: map-get($config, breakpoints);

//------------------------------------
// Transition timings
//------------------------------------
$transition-timings: map-get($config, transitionTimings);

//------------------------------------
// Dimensions
//------------------------------------
$widths-menu: (
    tablet-landscape: 260px,
    tablet-portrait: 180px
);

$max-widths-keypad: (
    tablet-landscape: 480px,
    tablet-portrait: 410px
);

$widths-main-content: (
    tablet-landscape: 578px,
    tablet-portrait: 408px
);

$heights-menu-item: (
    short: 94px,
    normal: 115px,
    tablet-landscape: 100px,
    tablet-portrait: 125px
);

$heights-sub-menu-item: (
    short: 85px,
    normal: 106px,
    tablet-landscape: 91px,
    tablet-portrait: 116px
);

$heights-payments-toolbar: (
    normal: 49px
);

$gutters-payments-bar: (
    normal: 2px,
    tablet: 12px
);

$top-bar-heights: (
    normal: 75px,
    mobile-portrait-short: 64px,
    mobile-landscape: 64px,
    tablet: 100px
);

$offline-message-heights: (
    normal: 20px
);

$widths-modal: (
    normal: 100%,
    tablet-landscape: 400px,
    tablet-portrait: 375px
);

$heights-modal: (
    normal: 100%,
    tablet-landscape: 480px,
    tablet-portrait: 500px
);

$widths-modal-large: (
    tablet-landscape: 600px,
    tablet-portrait: 450px
);

$heights-modal-large: (
    tablet-landscape: 500px,
    tablet-portrait: 600px
);

$heights-modal-header: (
    normal: 75px,
    short: 64px,
    tablet: 62px
);

$gutter-horizontal: 24px;
$gutter-vertical: 20px;

$height-normal: 2;
$menu-heights: 276px, 352px, 430px;
$help-heights: 40px, 50px, 50px;

//------------------------------------
// z-indexes
//------------------------------------
$z-index-menu: 0;
$z-index-pages-container: 10;
$z-index-top-bar: 20;
$z-index-modals-fade: 30;
$z-index-splash: 29;
$z-index-modals: 40;
$z-index-alerts: 50;
$z-index-loader: 59;
$z-index-notifications: 60;
$z-help: 31;
$z-help-toggler: 30;

//------------------------------------
// Custom color variables
//------------------------------------
$custom-grey-dark: #242a2a;

//------------------------------------
// Image variables
//------------------------------------
$wave_image: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==';
