.wizard {

    .step {
        min-height: 10em;
    }

    .summary {

        .is-good .value {
            color: palette($green);
        }

        .is-bad .value,
        .is-bad .edit {
            color: palette($red);
        }

        .errors {
            padding: $gutter-vertical / 2 $gutter-horizontal / 2;
            border-radius: 2em;

            background-color: palette($red);
            color: $white;
            text-align: center;
        }

    }

}

.welcome-offer {

    display: flex;
    align-items: center;

    .casumo-holding-valuables {

        @include respond-to(mobile) {
            transform: scale(0.7);
        }
    }

    .text {
        flex: 1;
    }

}

.terms-dialog {

    h4 {
        font-size: $font-size-large;
    }

    p {
        margin: $font-size-regular 0;
    }

}
