.modal-coupon {

    .redeem-options {
        overflow: auto;
        width: 100%;

        li {
            line-height: 1.6em;
            text-align: center;
        }

        &.longer-list {
            display: inline-block;
            column-count: 2;
            column-rule: 1px solid palette($grey, lighter);

            li {
                text-align: left;
                padding-left: 10px;
            }
        }
    }
}
