//Variables
//-------------------------------------------------------------------------

$gutter: 20px;

$default-text-color: #333;
$grey-text-color: #848484;
$light-grey-text-color: #bbb;
$light-text-color: #d0d0d0;
$green-text-color: #40a9a5;
$lime-text-color: #76b12f;
$red-text-color: #e15555;
$orange-text-color: #ff8555;
$gold-text-color: #ffe27e;

$theme-teal: #119d9d;
$theme-yellow: #ffae56;
$theme-red: #ff8554;
$theme-green: #0e913f;

$h1-font-size: 48px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 18px;

$large-font-size: $h3-font-size;
$big-font-size: $h4-font-size;
$default-font-size: 15px;
$small-font-size: 13px;
$mini-font-size: 11px;

$default-font: "SupriaSansRegular", Arial, sans-serif;
$italic-font: "SupriaSansRegularItalic", Arial, sans-serif;
$bold-font: "SupriaSansBold", Arial, sans-serif;
$bold-italic-font: "SupriaSansBoldItalic", Arial, sans-serif;

/* Fonts */

$fonts: $default-font, $italic-font, $bold-font, $bold-italic-font;

$normal: 1;
$italic: 2;
$bold: 3;
$bold-italic: 4;
