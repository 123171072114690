//Casumo color palette

//--------------------------------------------------------------------------------------------
//list      lightest    lighter     light       base        dark        darker      darkest
//--------------------------------------------------------------------------------------------
$greys:     #ffffff,    #eff6f6,    #cad7d7,    #aab8b8,    #6f7b7b,    #444f4f,    #303838;
$purples:   #fae1ff,    #d8abdd,    #b47db7,    #a85aa6,    #844e87,    #603b63,    #503254;
$reds:      #f7d7d7,    #f4b0b0,    #f48989,    #f05e5e,    #bc4a4a,    #9e3f3f,    #843535;
$oranges:   #f9e3dc,    #ffc3b3,    #ffa383,    #ff8a50,    #d36442,    #b24f3b,    #964133;
$yellows:   #fff0d9,    #ffdf9f,    #ffd073,    #ffc930,    #d6a01a,    #af7820,    #965c30;
$browns:    #fcebe0,    #efc3a5,    #c18156,    #96603c,    #764b33,    #603f2f,    #472f26;
$greens:    #d1eedc,    #a3deb9,    #47bd74,    #19ac51,    #13813d,    #0d5629,    #0a411f;
$teals:     #d9f2f1,    #b2e5e4,    #8cd9d6,    #3ebeba,    #2f8f8c,    #20605e,    #184847;
$blues:     #cbedff,    #a0dcfc,    #4facdb,    #0085c1,    #0073a0,    #015f7b,    #004854;

//White alias instead of ($grey, $lightest)
$white: nth($greys, 1);

//Color variables used as parameters in the `palette` function. (grid vertical lookup)
$grey: 1;
$purple: 2;
$red: 3;
$orange: 4;
$yellow: 5;
$brown: 6;
$green: 7;
$teal: 8;
$blue: 9;

//Helper variables to generate class names, which you can't do from variable names
$colorNames: "grey", "purple", "red", "orange", "yellow", "brown", "green", "teal", "blue";
$shadeNames: "lightest", "lighter", "light", "base", "dark", "darker", "darkest";

//Shade helper
$shadeBaseIndex: index($shadeNames, base);

//Construct the palette
$palette: $greys, $purples, $reds, $oranges, $yellows, $browns, $greens, $teals, $blues;
