.numeric-keypad-container {
    max-height: 290px;
    min-height: 170px;
    height: 60%;

    position: relative;

    @include respond-to(tablet) {
        margin: 0 auto;
        min-height: 250px;
    }

    @include respond-with($max-widths-keypad, max-width);
}

.numeric-keypad {
    @include position(absolute, 0, 0, 0, 0);
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 5px;

    .key-row {
        display: flex;
        flex: 1;
        justify-content: space-around;
    }

    button.key {
        display: inline-block;
        text-align: center;
        border: 0;
        border-radius: 0;
        padding: 0;
        min-height: 0;
    }

    .key {
        color: palette($grey, dark);
        font-size: em(18);
        margin: 2px;
        text-spacing: 0;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        user-select: none;
        background-color: palette($grey, lighter);
        transition: all 25ms;

        &.is-good {
            background-color: palette($green);
            color: $white;
        }

        &:active {
            background-color: palette($green, light);
            color: $white;
        }

        &:disabled {
            color: $white;
        }
    }
}
