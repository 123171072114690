@import 'toolkit';

body,
input,
textarea,
select {
    @include font($light);
    font-size: $base-font-size;
    font-smoothing: subpixel-antialiased;

    @include respond-to(tablet) {
        font-size: $zoomed-font-size;
    }
}

h1,
h2,
h3,
h4 {
    text-rendering: optimizelegibility;
}

h1,
.h1 {
    font-size: $font-size-larger;
    line-height: 1.2;
}

h2,
.h2 {
    font-size: $font-size-large;
    line-height: 1.2;
}

h3,
.h3 {
    font-size: $font-size-regular;
    line-height: 1.2;
}

h4,
.h4 {
    font-size: $font-size-small;
    line-height: 1.2;
}

b,
strong,
.bold {
    @include font($bold);
}

.fs-smallest {
    font-size: $font-size-smallest;
}

.fs-small {
    font-size: $font-size-small;
}

.fs-medium {
    font-size: $font-size-medium;
}

.fs-large {
    font-size: $font-size-large;
}

.fs-larger {
    font-size: $font-size-larger;
}

.heading {
    text-align: center;
    padding: 30px $gutter-horizontal;
    clear: both;
    line-height: 1.2;
}

[role="link"] {
    text-decoration: underline;
}

.block-heading {
    display: block;
    padding: em(22) $gutter-horizontal;
    background-color: palette($grey, lighter);
}

.ellipsis {
    @include ellipsis;
}

@include all-color-helpers(color, 'color-');
