@charset "UTF-8";
/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
/* -- undo browser HTML defaults -- */
:link, :visited {
  text-decoration: none; }

ul, ol {
  list-style: none; }

h1, h2, h3, h4, h5, h6, pre, code, p, input {
  font-size: 1em;
  font-weight: normal; }

ul, ol, dl, li, dt, dd, h1, h2, h3, h4, h5, h6, pre, form, body, html, p, blockquote, fieldset, input {
  margin: 0;
  padding: 0; }

a img, :link img, :visited img {
  border: none; }

form {
  display: inline; }

input::-moz-focus-inner {
  border: 0; }

/*!
 * Bootstrap v2.0.0
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:hover, a:active {
  outline: 0; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

button,
input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle; }

button, input {
  *overflow: visible;
  line-height: normal; }

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

/* -- clearfix -- */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0; }

.clearfix {
  display: inline-block; }

/* Hides from IE-mac */
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
@font-face {
  font-family: 'Circular-Pro-Book';
  src: url("../../fonts/lineto-circular-pro-book.eot");
  src: url("../../fonts/lineto-circular-pro-book.eot?#iefix") format("embedded-opentype"), url("../../fonts/lineto-circular-pro-book.woff") format("woff"), url("../../fonts/lineto-circular-pro-book.ttf") format("truetype"), url("../../fonts/lineto-circular-pro-book.svg#lineto-circular-pro-book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Circular-Pro-Black';
  src: url("../../fonts/lineto-circular-pro-black.eot");
  src: url("../../fonts/lineto-circular-pro-black.eot?#iefix") format("embedded-opentype"), url("../../fonts/lineto-circular-pro-black.woff") format("woff"), url("../../fonts/lineto-circular-pro-black.ttf") format("truetype"), url("../../fonts/lineto-circular-pro-black.svg#lineto-circular-pro-black") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Circular-Pro-BlackItalic";
  src: url("../../fonts/lineto-circular-pro-boldItalic.eot");
  src: url("../../fonts/lineto-circular-pro-boldItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/lineto-circular-pro-boldItalic.woff") format("woff"), url("../../fonts/lineto-circular-pro-boldItalic.ttf") format("truetype"), url("../../fonts/lineto-circular-pro-boldItalic.svg#lineto-circular-pro-blackItalic") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
.pop-in {
  animation: pop-in 150ms ease-in-out;
  transform-origin: 50% 50%; }

.pop-out {
  transform-origin: 50% 50%;
  animation: pop-out 100ms ease-out forwards; }

.fade-in {
  animation: fade-in 150ms ease forwards; }

.fade-out {
  animation: fade-out 100ms ease forwards; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes pop-out {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.9); } }

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translateY(-15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fade-out-down {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(15px); } }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
body {
  color: #303838; }

a {
  color: #0085c1; }

br {
  height: 0; }

*:not(input):not(textarea) {
  user-select: none;
  touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

input[type="checkbox"] {
  vertical-align: 1px; }

* {
  -webkit-backface-visibility: hidden; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
body,
input,
textarea,
select {
  font-family: Circular-Pro-Book, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-smoothing: subpixel-antialiased; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    body,
    input,
    textarea,
    select {
      font-size: 16px; } }

h1,
h2,
h3,
h4 {
  text-rendering: optimizelegibility; }

h1,
.h1 {
  font-size: 1.5em;
  line-height: 1.2; }

h2,
.h2 {
  font-size: 1.28571em;
  line-height: 1.2; }

h3,
.h3 {
  font-size: 1em;
  line-height: 1.2; }

h4,
.h4 {
  font-size: 0.85714em;
  line-height: 1.2; }

b,
strong,
.bold,
.habla_wcsend_field {
  font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }

.fs-smallest {
  font-size: 0.71429em; }

.fs-small {
  font-size: 0.85714em; }

.fs-medium {
  font-size: 1.14286em; }

.fs-large {
  font-size: 1.28571em; }

.fs-larger {
  font-size: 1.5em; }

.heading {
  text-align: center;
  padding: 30px 24px;
  clear: both;
  line-height: 1.2; }

[role="link"] {
  text-decoration: underline; }

.block-heading {
  display: block;
  padding: 1.57143em 24px;
  background-color: #eff6f6; }

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.color-grey {
  color: #aab8b8 !important; }

.color-grey-lightest {
  color: #ffffff !important; }

.color-grey-lighter {
  color: #eff6f6 !important; }

.color-grey-light {
  color: #cad7d7 !important; }

.color-grey-dark {
  color: #6f7b7b !important; }

.color-grey-darker {
  color: #444f4f !important; }

.color-grey-darkest {
  color: #303838 !important; }

.color-purple {
  color: #a85aa6 !important; }

.color-purple-lightest {
  color: #fae1ff !important; }

.color-purple-lighter {
  color: #d8abdd !important; }

.color-purple-light {
  color: #b47db7 !important; }

.color-purple-dark {
  color: #844e87 !important; }

.color-purple-darker {
  color: #603b63 !important; }

.color-purple-darkest {
  color: #503254 !important; }

.color-red {
  color: #f05e5e !important; }

.color-red-lightest {
  color: #f7d7d7 !important; }

.color-red-lighter {
  color: #f4b0b0 !important; }

.color-red-light {
  color: #f48989 !important; }

.color-red-dark {
  color: #bc4a4a !important; }

.color-red-darker {
  color: #9e3f3f !important; }

.color-red-darkest {
  color: #843535 !important; }

.color-orange {
  color: #ff8a50 !important; }

.color-orange-lightest {
  color: #f9e3dc !important; }

.color-orange-lighter {
  color: #ffc3b3 !important; }

.color-orange-light {
  color: #ffa383 !important; }

.color-orange-dark {
  color: #d36442 !important; }

.color-orange-darker {
  color: #b24f3b !important; }

.color-orange-darkest {
  color: #964133 !important; }

.color-yellow {
  color: #ffc930 !important; }

.color-yellow-lightest {
  color: #fff0d9 !important; }

.color-yellow-lighter {
  color: #ffdf9f !important; }

.color-yellow-light {
  color: #ffd073 !important; }

.color-yellow-dark {
  color: #d6a01a !important; }

.color-yellow-darker {
  color: #af7820 !important; }

.color-yellow-darkest {
  color: #965c30 !important; }

.color-brown {
  color: #96603c !important; }

.color-brown-lightest {
  color: #fcebe0 !important; }

.color-brown-lighter {
  color: #efc3a5 !important; }

.color-brown-light {
  color: #c18156 !important; }

.color-brown-dark {
  color: #764b33 !important; }

.color-brown-darker {
  color: #603f2f !important; }

.color-brown-darkest {
  color: #472f26 !important; }

.color-green {
  color: #19ac51 !important; }

.color-green-lightest {
  color: #d1eedc !important; }

.color-green-lighter {
  color: #a3deb9 !important; }

.color-green-light {
  color: #47bd74 !important; }

.color-green-dark {
  color: #13813d !important; }

.color-green-darker {
  color: #0d5629 !important; }

.color-green-darkest {
  color: #0a411f !important; }

.color-teal {
  color: #3ebeba !important; }

.color-teal-lightest {
  color: #d9f2f1 !important; }

.color-teal-lighter {
  color: #b2e5e4 !important; }

.color-teal-light {
  color: #8cd9d6 !important; }

.color-teal-dark {
  color: #2f8f8c !important; }

.color-teal-darker {
  color: #20605e !important; }

.color-teal-darkest {
  color: #184847 !important; }

.color-blue {
  color: #0085c1 !important; }

.color-blue-lightest {
  color: #cbedff !important; }

.color-blue-lighter {
  color: #a0dcfc !important; }

.color-blue-light {
  color: #4facdb !important; }

.color-blue-dark {
  color: #0073a0 !important; }

.color-blue-darker {
  color: #015f7b !important; }

.color-blue-darkest {
  color: #004854 !important; }

.color-white {
  color: #ffffff !important; }

* {
  box-sizing: border-box; }

body {
  background: #cad7d7; }

body,
.app,
.pages,
.main-menu,
.pages-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden; }

.main-menu {
  z-index: 0;
  transform: translate3d(-30%, 0, 0);
  transition: all 0.3s ease; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .main-menu {
      width: 260px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .main-menu {
      width: 180px; } }
  .menu-active .main-menu {
    transform: translate3d(0, 0, 0); }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .main-menu {
      transform: translate3d(0, 0, 0);
      right: auto; } }

.pages-container {
  z-index: 10;
  background: #ffffff;
  transition: all 0.3s ease; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pages-container {
      left: 260px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .pages-container {
      left: 180px; } }
  .menu-active .pages-container {
    transform: translate3d(100%, 0, 0); }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .pages-container {
      right: 0; }
      .menu-active .pages-container {
        transform: translate3d(0, 0, 0); } }
  .pages-container .top-bar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 20;
    height: 75px; }
    @media (max-height: 480px) and (orientation: portrait) {
      .pages-container .top-bar {
        height: 64px; } }
    @media (max-height: 375px) and (orientation: landscape) {
      .pages-container .top-bar {
        height: 64px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .pages-container .top-bar {
        height: 100px; } }
    @media (min-width: 1024px) and (orientation: landscape) {
      .pages-container .top-bar {
        width: 578px; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .pages-container .top-bar {
        width: 408px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .pages-container .top-bar {
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 0; } }
  .pages-container .pages {
    padding-top: 75px; }
    @media (max-height: 480px) and (orientation: portrait) {
      .pages-container .pages {
        padding-top: 64px; } }
    @media (max-height: 375px) and (orientation: landscape) {
      .pages-container .pages {
        padding-top: 64px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .pages-container .pages {
        padding-top: 100px; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pages-container .page {
      width: 578px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .pages-container .page {
      width: 408px; } }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .pages-container .page {
      margin: 0 auto; } }
  .pages-container .page.fit {
    top: 75px; }
    @media (max-height: 480px) and (orientation: portrait) {
      .pages-container .page.fit {
        top: 64px; } }
    @media (max-height: 375px) and (orientation: landscape) {
      .pages-container .page.fit {
        top: 64px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .pages-container .page.fit {
        top: 100px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .pages-container .page.fit {
        position: static;
        min-height: 600px; } }

.menu-footer {
  background: #ffffff; }

.menu-footer-wave {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==");
  background-size: contain;
  height: 6px; }

.page-footer {
  padding: 20px 0; }
  .page-footer .footer-inner {
    margin: 0 auto; }
    @media (min-width: 1024px) and (orientation: landscape) {
      .page-footer .footer-inner {
        width: 578px; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .page-footer .footer-inner {
        width: 408px; } }
  .page-footer .divider {
    border-bottom: 3px solid #eff6f6;
    padding-bottom: 20px;
    margin-bottom: 20px; }
  .page-footer .terms-container {
    margin-left: 24px;
    font-size: 0.71429em; }

.page-footer-wave {
  margin-top: 50px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTMwIiB2aWV3Qm94PSIwIDAgNTAwIDEzMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTE1CiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDExNSAxMDAsMTUgMjUwLDE1CiAgICAgICAgICAgICAgICAgICAgICAgICBNMjUwLDE1CiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDE1IDQwMCwxMTUgNTAwLDExNSIgc3Ryb2tlLXdpZHRoPSIzMCIgc3Ryb2tlPSIjZWZmNmY2IiBmaWxsPSJub25lIj48L3BhdGg+Cjwvc3ZnPg==");
  background-size: contain;
  height: 9px; }

.global-loader-container {
  position: absolute;
  z-index: 59;
  bottom: 0;
  height: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease; }
  .global-loader-container.loading {
    transition-delay: 200ms;
    transform: translateY(-50px); }
    .global-loader-container.loading .ring {
      animation: loader-ring 1s infinite ease-in; }

/*
##Block
```
<!-- Sometimes we really need to block something -->
<span class="block center">text</span>
```
*/
.block {
  display: block; }

/*
##Hidden
```
<!-- Hide element.  -->
<span class="hidden">text</span>
```
*/
.hidden {
  display: none; }

/*
##Accessible hidden
```
<!-- When .  -->
<span class="accessible-hidden">text</span>
```
*/
.accessible-hidden {
  position: absolute !important;
  top: -9999px;
  left: 0;
  /* A negative value here would calculate the element's height incorrectly. Very weird */ }

.pad {
  padding: 20px; }

.pad-big {
  padding: 30px; }

.pad-small {
  padding: 15px; }

.pad-mini {
  padding: 10px; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.align-middle > * {
  vertical-align: middle; }

.centerize {
  margin-left: auto;
  margin-right: auto;
  float: none; }

.clickable {
  cursor: pointer; }

.slides {
  overflow: hidden;
  position: relative; }
  .slides .slide {
    float: left;
    position: relative; }

.verticalize {
  display: table;
  height: 100%; }
  .verticalize .cell,
  .verticalize .content {
    display: table-cell;
    vertical-align: middle; }

.no-overflow {
  overflow: hidden; }

.uppercase {
  text-transform: uppercase; }

.break-all {
  word-break: break-all; }

.no-wrap {
  white-space: nowrap; }

.center {
  text-align: center; }

.align-right {
  text-align: right; }

.block {
  width: 100%; }

.scroll-y {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.scroll-x {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

.fit {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.absolute-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flex-horizontal {
  display: flex; }

.flex-vertical, .page-playokay .link-item, .modal-playokay .link-item {
  display: flex;
  flex-direction: column; }
  .flex-vertical > *, .page-playokay .link-item > *, .modal-playokay .link-item > * {
    position: relative; }

.fj-space-between {
  justify-content: space-between; }

.fj-space-around {
  justify-content: space-between;
  justify-content: space-around; }

.fj-begin {
  justify-content: flex-start; }

.fj-center {
  justify-content: center; }

.fj-end {
  justify-content: flex-end; }

.spaced-row {
  display: flex;
  justify-content: space-between;
  justify-content: space-around;
  align-items: center; }

.spread-row {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.media-flex {
  display: flex;
  align-items: center; }

.media-flex-image {
  margin-right: 1rem; }

.media-flex-content {
  flex: 1; }

.pad {
  padding: 20px 24px; }

.pad-big {
  padding: 40px 48px; }

.pad-small {
  padding: 10px 12px; }

.pad-mini {
  padding: 5px 6px; }

.pad-top {
  padding-top: 20px; }

.pad-bottom {
  padding-bottom: 20px; }

.h-pad {
  padding-left: 24px;
  padding-right: 24px; }

.v-pad {
  padding-top: 20px;
  padding-bottom: 20px; }

.pad-top-small {
  padding-top: 10px; }

.h-pad-big {
  padding-left: 36px;
  padding-right: 36px; }

.h-pad-small {
  padding-left: 12px;
  padding-right: 12px; }

.v-pad-big {
  padding-top: 30px;
  padding-bottom: 30px; }

.pad-left {
  padding-left: 24px; }

.pad-right {
  padding-right: 24px; }

.pad-right-small {
  padding-right: 12px; }

.pad-left-small {
  padding-left: 12px; }

.v-gutter-mini {
  margin-top: 5px;
  margin-bottom: 5px; }

.v-gutter-small {
  margin-top: 10px;
  margin-bottom: 10px; }

.v-gutter {
  margin-top: 20px;
  margin-bottom: 20px; }

.h-gutter {
  margin-left: 24px;
  margin-right: 24px; }

.h-gutter-small {
  margin-left: 12px;
  margin-right: 12px; }

.gutter-top, .page-playokay .list-item:first-child, .modal-playokay .list-item:first-child {
  margin-top: 20px; }

.gutter-top-big {
  margin-top: 40px; }

.gutter-bottom {
  margin-bottom: 20px; }

.gutter-bottom-big {
  margin-bottom: 40px; }

.gutter-small {
  margin-top: 10px;
  margin-bottom: 10px; }

.gutter-top-small {
  margin-top: 10px; }

.gutter-bottom-small {
  margin-bottom: 10px; }

.gutter-top-mini {
  margin-top: 5px; }

.gutter-bottom-mini {
  margin-bottom: 5px; }

.gutter-right {
  margin-right: 24px; }

.gutter-right-small {
  margin-right: 12px; }

.gutter-right-mini {
  margin-right: 6px; }

.gutter-left {
  margin-left: 24px; }

.flex-0 {
  flex: 0; }

.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-3 {
  flex: 3; }

.flex-4 {
  flex: 4; }

.flex-5 {
  flex: 5; }

.flex-6 {
  flex: 6; }

.flex-7 {
  flex: 7; }

.flex-8 {
  flex: 8; }

.flex-9 {
  flex: 9; }

.flex-10 {
  flex: 10; }

.icon-14 {
  font-size: 16px !important; }

.icon-24 {
  font-size: 24px !important; }

.icon-32 {
  font-size: 32px !important; }

.icon-64 {
  font-size: 64px !important; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.round-slightly {
  border-radius: 4px; }

.bg-grey {
  background-color: #aab8b8 !important; }

.bg-grey-lightest {
  background-color: #ffffff !important; }

.bg-grey-lighter {
  background-color: #eff6f6 !important; }

.bg-grey-light {
  background-color: #cad7d7 !important; }

.bg-grey-dark {
  background-color: #6f7b7b !important; }

.bg-grey-darker {
  background-color: #444f4f !important; }

.bg-grey-darkest {
  background-color: #303838 !important; }

.bg-purple {
  background-color: #a85aa6 !important; }

.bg-purple-lightest {
  background-color: #fae1ff !important; }

.bg-purple-lighter {
  background-color: #d8abdd !important; }

.bg-purple-light {
  background-color: #b47db7 !important; }

.bg-purple-dark {
  background-color: #844e87 !important; }

.bg-purple-darker {
  background-color: #603b63 !important; }

.bg-purple-darkest {
  background-color: #503254 !important; }

.bg-red {
  background-color: #f05e5e !important; }

.bg-red-lightest {
  background-color: #f7d7d7 !important; }

.bg-red-lighter {
  background-color: #f4b0b0 !important; }

.bg-red-light {
  background-color: #f48989 !important; }

.bg-red-dark {
  background-color: #bc4a4a !important; }

.bg-red-darker {
  background-color: #9e3f3f !important; }

.bg-red-darkest {
  background-color: #843535 !important; }

.bg-orange {
  background-color: #ff8a50 !important; }

.bg-orange-lightest {
  background-color: #f9e3dc !important; }

.bg-orange-lighter {
  background-color: #ffc3b3 !important; }

.bg-orange-light {
  background-color: #ffa383 !important; }

.bg-orange-dark {
  background-color: #d36442 !important; }

.bg-orange-darker {
  background-color: #b24f3b !important; }

.bg-orange-darkest {
  background-color: #964133 !important; }

.bg-yellow {
  background-color: #ffc930 !important; }

.bg-yellow-lightest {
  background-color: #fff0d9 !important; }

.bg-yellow-lighter {
  background-color: #ffdf9f !important; }

.bg-yellow-light {
  background-color: #ffd073 !important; }

.bg-yellow-dark {
  background-color: #d6a01a !important; }

.bg-yellow-darker {
  background-color: #af7820 !important; }

.bg-yellow-darkest {
  background-color: #965c30 !important; }

.bg-brown {
  background-color: #96603c !important; }

.bg-brown-lightest {
  background-color: #fcebe0 !important; }

.bg-brown-lighter {
  background-color: #efc3a5 !important; }

.bg-brown-light {
  background-color: #c18156 !important; }

.bg-brown-dark {
  background-color: #764b33 !important; }

.bg-brown-darker {
  background-color: #603f2f !important; }

.bg-brown-darkest {
  background-color: #472f26 !important; }

.bg-green {
  background-color: #19ac51 !important; }

.bg-green-lightest {
  background-color: #d1eedc !important; }

.bg-green-lighter {
  background-color: #a3deb9 !important; }

.bg-green-light {
  background-color: #47bd74 !important; }

.bg-green-dark {
  background-color: #13813d !important; }

.bg-green-darker {
  background-color: #0d5629 !important; }

.bg-green-darkest {
  background-color: #0a411f !important; }

.bg-teal {
  background-color: #3ebeba !important; }

.bg-teal-lightest {
  background-color: #d9f2f1 !important; }

.bg-teal-lighter {
  background-color: #b2e5e4 !important; }

.bg-teal-light {
  background-color: #8cd9d6 !important; }

.bg-teal-dark {
  background-color: #2f8f8c !important; }

.bg-teal-darker {
  background-color: #20605e !important; }

.bg-teal-darkest {
  background-color: #184847 !important; }

.bg-blue {
  background-color: #0085c1 !important; }

.bg-blue-lightest {
  background-color: #cbedff !important; }

.bg-blue-lighter {
  background-color: #a0dcfc !important; }

.bg-blue-light {
  background-color: #4facdb !important; }

.bg-blue-dark {
  background-color: #0073a0 !important; }

.bg-blue-darker {
  background-color: #015f7b !important; }

.bg-blue-darkest {
  background-color: #004854 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-img-centred {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center; }

.bg-cover-center {
  background-position: center center;
  background-size: cover; }

.margin-auto {
  margin: auto; }

.margin-left-auto {
  margin-left: auto; }

.full-height {
  height: 100%; }

.form-field {
  padding-top: 15px;
  position: relative; }
  .form-field > .password-visibility-toggle {
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translateY(50%);
    padding-top: 23px; }
  .form-field.multi-part {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .form-field.multi-part > * {
      flex: 1;
      position: relative;
      margin-right: 6px; }
    .form-field.multi-part > .prefix-part {
      flex: none;
      margin-right: -3px; }
    .form-field.multi-part > .short-part {
      flex: none;
      width: 45px; }
    .form-field.multi-part > :last-child {
      margin-right: 0; }
    .form-field.multi-part label {
      top: -4px; }
  .form-field .phone-prefix-sign {
    text-align: right;
    height: 2.2rem;
    margin-top: 0.8em;
    color: #aeb9b9;
    padding: 7px 0 0; }
  .form-field input {
    border: 0;
    border-bottom: 1px solid #eff6f6;
    display: block;
    width: 100%;
    padding: 5px 6px 6px;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    height: 2.2rem;
    margin-top: 0.8rem; }
    .form-field input:focus {
      border-color: #aab8b8; }
    .light .form-field input {
      color: #ffffff;
      border-color: #aab8b8; }
      .light .form-field input:focus {
        border-color: #ffffff; }
    .form-field input.is-good {
      color: #19ac51; }
    .form-field input.is-bad {
      color: #f05e5e; }
  .form-field label {
    top: 11px;
    color: #aab8b8;
    position: absolute;
    left: 5px;
    opacity: 0;
    transition: opacity 300ms;
    font-size: 0.78571em; }
    .form-field label.active {
      opacity: 1; }
    .form-field label.lower {
      position: relative;
      margin: 0; }
    .light .form-field label {
      color: #eff6f6; }

.password-visibility-toggle {
  min-width: 30px;
  color: #444f4f;
  text-align: center; }
  .light .password-visibility-toggle {
    color: #ffc930; }

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }
  .form-row > * {
    flex: 1;
    position: relative;
    margin-right: 12px; }
  .form-row > button,
  .form-row .button {
    flex: none; }
  .form-row > :last-child {
    margin-right: 0; }

.progress-bar {
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #eff6f6; }
  .progress-bar.baseline {
    position: absolute;
    bottom: 0; }
  .progress-bar > div {
    height: 2px;
    width: 0;
    background-color: #19ac51; }

.flip-switch {
  display: inline-block;
  border: 2px solid #cad7d7;
  border-radius: 1.2em;
  color: #6f7b7b;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: background 0.3s ease, border-color 0.3s ease; }
  .flip-switch input {
    opacity: 0; }
  .flip-switch .flip-switch-inner {
    position: relative;
    overflow: hidden;
    margin: 2px;
    width: 4.42857em;
    height: 1.71429em; }
  .flip-switch .flip-switch-slider {
    position: absolute;
    top: 0;
    left: -2.7em;
    overflow: hidden;
    width: 8em;
    transition: left 0.1s ease-out; }
  .flip-switch.on-state {
    border-color: #19ac51;
    background: #19ac51;
    color: #ffffff; }
    .flip-switch.on-state .switch {
      background: #ffffff; }
    .flip-switch.on-state .flip-switch-slider {
      left: 0; }
  .flip-switch .on, .flip-switch .off {
    float: left;
    width: 2.7em;
    height: 1.71429em;
    text-align: center;
    text-transform: uppercase; }
  .flip-switch .text {
    padding: 0 5px;
    line-height: 1.71429em; }
  .flip-switch .switch {
    float: left;
    width: 1.71429em;
    height: 1.71429em;
    border-radius: 1em;
    background: #cad7d7;
    transform: translate3d(0, 0, 0); }

.form--inverted .flip-switch {
  color: #ffffff; }
  .form--inverted .flip-switch .switch {
    background: #ffffff; }
  .form--inverted .flip-switch.on-state {
    border-color: #ffffff;
    background: #ffffff;
    color: #19ac51; }
    .form--inverted .flip-switch.on-state .switch {
      background: #19ac51; }
    .form--inverted .flip-switch.on-state .flip-switch-slider {
      left: 0; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
@keyframes loading {
  0% {
    background-position: 0 0; }
  100% {
    background-position: 12px 0; } }

.button.grey-lighter, button.grey-lighter {
  background-color: #eff6f6;
  border-color: #eff6f6;
  color: #aab8b8; }
  .button.grey-lighter:active:not(:disabled), button.grey-lighter:active:not(:disabled) {
    background-color: #dfeded;
    border-color: #dfeded; }
  .button.grey-lighter.hollow, button.grey-lighter.hollow {
    border-color: #eff6f6;
    color: #eff6f6;
    background: transparent; }
  .button.grey-lighter.link, button.grey-lighter.link {
    border: 0;
    color: #eff6f6;
    background: transparent; }
  .button.grey-lighter.loading, button.grey-lighter.loading {
    background-color: #cad7d7;
    border-color: #cad7d7; }

.button.grey-light, button.grey-light {
  background-color: #cad7d7;
  border-color: #cad7d7; }
  .button.grey-light:active:not(:disabled), button.grey-light:active:not(:disabled) {
    background-color: #bbcccc;
    border-color: #bbcccc; }
  .button.grey-light.hollow, button.grey-light.hollow {
    border-color: #cad7d7;
    color: #cad7d7;
    background: transparent; }
  .button.grey-light.link, button.grey-light.link {
    border: 0;
    color: #cad7d7;
    background: transparent; }
  .button.grey-light.loading, button.grey-light.loading {
    background-color: #aab8b8;
    border-color: #aab8b8; }

.button.grey-dark, button.grey-dark {
  background-color: #6f7b7b;
  border-color: #6f7b7b; }
  .button.grey-dark:active:not(:disabled), button.grey-dark:active:not(:disabled) {
    background-color: #636e6e;
    border-color: #636e6e; }
  .button.grey-dark.hollow, button.grey-dark.hollow {
    border-color: #6f7b7b;
    color: #6f7b7b;
    background: transparent; }
  .button.grey-dark.link, button.grey-dark.link {
    border: 0;
    color: #6f7b7b;
    background: transparent; }
  .button.grey-dark.loading, button.grey-dark.loading {
    background-color: #444f4f;
    border-color: #444f4f; }

.button.grey-darker, button.grey-darker {
  background-color: #444f4f;
  border-color: #444f4f; }
  .button.grey-darker:active:not(:disabled), button.grey-darker:active:not(:disabled) {
    background-color: #384141;
    border-color: #384141; }
  .button.grey-darker.hollow, button.grey-darker.hollow {
    border-color: #444f4f;
    color: #444f4f;
    background: transparent; }
  .button.grey-darker.link, button.grey-darker.link {
    border: 0;
    color: #444f4f;
    background: transparent; }
  .button.grey-darker.loading, button.grey-darker.loading {
    background-color: #303838;
    border-color: #303838; }

.button.blue, button.blue {
  background-color: #0085c1;
  border-color: #0085c1; }
  .button.blue:active:not(:disabled), button.blue:active:not(:disabled) {
    background-color: #0073a8;
    border-color: #0073a8; }
  .button.blue.hollow, button.blue.hollow {
    border-color: #0085c1;
    color: #0085c1;
    background: transparent; }
  .button.blue.link, button.blue.link {
    border: 0;
    color: #0085c1;
    background: transparent; }
  .button.blue.loading, button.blue.loading {
    background-color: #0073a0;
    border-color: #0073a0; }

.button.teal, button.teal {
  background-color: #3ebeba;
  border-color: #3ebeba; }
  .button.teal:active:not(:disabled), button.teal:active:not(:disabled) {
    background-color: #38aba7;
    border-color: #38aba7; }
  .button.teal.hollow, button.teal.hollow {
    border-color: #3ebeba;
    color: #3ebeba;
    background: transparent; }
  .button.teal.link, button.teal.link {
    border: 0;
    color: #3ebeba;
    background: transparent; }
  .button.teal.loading, button.teal.loading {
    background-color: #2f8f8c;
    border-color: #2f8f8c; }

.button.teal-darkest, button.teal-darkest {
  background-color: #184847;
  border-color: #184847; }
  .button.teal-darkest:active:not(:disabled), button.teal-darkest:active:not(:disabled) {
    background-color: #123534;
    border-color: #123534; }
  .button.teal-darkest.hollow, button.teal-darkest.hollow {
    border-color: #184847;
    color: #184847;
    background: transparent; }
  .button.teal-darkest.link, button.teal-darkest.link {
    border: 0;
    color: #184847;
    background: transparent; }
  .button.teal-darkest.loading, button.teal-darkest.loading {
    background-color: #2f8f8c;
    border-color: #2f8f8c; }

.button.green, button.green {
  background-color: #19ac51;
  border-color: #19ac51; }
  .button.green:active:not(:disabled), button.green:active:not(:disabled) {
    background-color: #169647;
    border-color: #169647; }
  .button.green.hollow, button.green.hollow {
    border-color: #19ac51;
    color: #19ac51;
    background: transparent; }
  .button.green.link, button.green.link {
    border: 0;
    color: #19ac51;
    background: transparent; }
  .button.green.loading, button.green.loading {
    background-color: #13813d;
    border-color: #13813d; }

.button.green-light, button.green-light {
  background-color: #47bd74;
  border-color: #47bd74; }
  .button.green-light:active:not(:disabled), button.green-light:active:not(:disabled) {
    background-color: #3ead68;
    border-color: #3ead68; }
  .button.green-light.hollow, button.green-light.hollow {
    border-color: #47bd74;
    color: #47bd74;
    background: transparent; }
  .button.green-light.link, button.green-light.link {
    border: 0;
    color: #47bd74;
    background: transparent; }
  .button.green-light.loading, button.green-light.loading {
    background-color: #19ac51;
    border-color: #19ac51; }

.button.orange, button.orange {
  background-color: #ff8a50;
  border-color: #ff8a50; }
  .button.orange:active:not(:disabled), button.orange:active:not(:disabled) {
    background-color: #ff7937;
    border-color: #ff7937; }
  .button.orange.hollow, button.orange.hollow {
    border-color: #ff8a50;
    color: #ff8a50;
    background: transparent; }
  .button.orange.link, button.orange.link {
    border: 0;
    color: #ff8a50;
    background: transparent; }
  .button.orange.loading, button.orange.loading {
    background-color: #d36442;
    border-color: #d36442; }

.button.yellow, button.yellow {
  background-color: #ffc930;
  border-color: #ffc930;
  color: #303838; }
  .button.yellow:active:not(:disabled), button.yellow:active:not(:disabled) {
    background-color: #ffc217;
    border-color: #ffc217; }
  .button.yellow.hollow, button.yellow.hollow {
    border-color: #ffc930;
    color: #ffc930;
    background: transparent; }
  .button.yellow.link, button.yellow.link {
    border: 0;
    color: #ffc930;
    background: transparent; }
  .button.yellow.loading, button.yellow.loading {
    background-color: #d6a01a;
    border-color: #d6a01a; }

.button.yellow-dark, button.yellow-dark {
  background-color: #d6a01a;
  border-color: #d6a01a;
  color: #303838; }
  .button.yellow-dark:active:not(:disabled), button.yellow-dark:active:not(:disabled) {
    background-color: #bf8f17;
    border-color: #bf8f17; }
  .button.yellow-dark.hollow, button.yellow-dark.hollow {
    border-color: #d6a01a;
    color: #d6a01a;
    background: transparent; }
  .button.yellow-dark.link, button.yellow-dark.link {
    border: 0;
    color: #d6a01a;
    background: transparent; }
  .button.yellow-dark.loading, button.yellow-dark.loading {
    background-color: #af7820;
    border-color: #af7820; }

.button.yellow-darkest, button.yellow-darkest {
  background-color: #965c30;
  border-color: #965c30; }
  .button.yellow-darkest:active:not(:disabled), button.yellow-darkest:active:not(:disabled) {
    background-color: #83502a;
    border-color: #83502a; }
  .button.yellow-darkest.hollow, button.yellow-darkest.hollow {
    border-color: #965c30;
    color: #965c30;
    background: transparent; }
  .button.yellow-darkest.link, button.yellow-darkest.link {
    border: 0;
    color: #965c30;
    background: transparent; }
  .button.yellow-darkest.loading, button.yellow-darkest.loading {
    background-color: #af7820;
    border-color: #af7820; }

.button.white, button.white {
  background-color: #ffffff;
  border-color: #ffffff; }
  .button.white:active:not(:disabled), button.white:active:not(:disabled) {
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .button.white.hollow, button.white.hollow {
    border-color: #ffffff;
    color: #ffffff;
    background: transparent; }
  .button.white.link, button.white.link {
    border: 0;
    color: #ffffff;
    background: transparent; }
  .button.white.loading, button.white.loading {
    background-color: #eff6f6;
    border-color: #eff6f6; }

.button.red-light, button.red-light {
  background-color: #f48989;
  border-color: #f48989; }
  .button.red-light:active:not(:disabled), button.red-light:active:not(:disabled) {
    background-color: #f27272;
    border-color: #f27272; }
  .button.red-light.hollow, button.red-light.hollow {
    border-color: #f48989;
    color: #f48989;
    background: transparent; }
  .button.red-light.link, button.red-light.link {
    border: 0;
    color: #f48989;
    background: transparent; }
  .button.red-light.loading, button.red-light.loading {
    background-color: #f05e5e;
    border-color: #f05e5e; }

.button.red, button.red {
  background-color: #f05e5e;
  border-color: #f05e5e; }
  .button.red:active:not(:disabled), button.red:active:not(:disabled) {
    background-color: #ee4747;
    border-color: #ee4747; }
  .button.red.hollow, button.red.hollow {
    border-color: #f05e5e;
    color: #f05e5e;
    background: transparent; }
  .button.red.link, button.red.link {
    border: 0;
    color: #f05e5e;
    background: transparent; }
  .button.red.loading, button.red.loading {
    background-color: #bc4a4a;
    border-color: #bc4a4a; }

.button.red-dark, button.red-dark {
  background-color: #bc4a4a;
  border-color: #bc4a4a; }
  .button.red-dark:active:not(:disabled), button.red-dark:active:not(:disabled) {
    background-color: #ad4040;
    border-color: #ad4040; }
  .button.red-dark.hollow, button.red-dark.hollow {
    border-color: #bc4a4a;
    color: #bc4a4a;
    background: transparent; }
  .button.red-dark.link, button.red-dark.link {
    border: 0;
    color: #bc4a4a;
    background: transparent; }
  .button.red-dark.loading, button.red-dark.loading {
    background-color: #9e3f3f;
    border-color: #9e3f3f; }

button,
button.button {
  text-align: center; }

.button {
  display: inline-flex;
  align-items: center;
  justify-content: space-around; }

button,
button.button {
  display: inline-block; }

.square-button {
  font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  min-width: 50px;
  min-height: 50px;
  line-height: 1em;
  text-align: center;
  background-color: #eff6f6;
  padding: 1.57143em 24px; }
  .square-button.half-width {
    width: 50%; }
  .square-button.left-white-border {
    border-left: 2px solid #ffffff; }
  .square-button.active {
    border-bottom: 2px solid #303838; }

button,
.button, .habla_offline_submit_input {
  font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  color: #ffffff;
  min-width: 50px;
  min-height: 50px;
  line-height: 1em;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-radius: 2em;
  text-align: center;
  padding: 0 15px; }
  button:disabled,
  .button:disabled, .habla_offline_submit_input:disabled {
    border-color: #aab8b8;
    background-color: #aab8b8;
    color: #ffffff; }
  button.loading, button.busy,
  .button.loading,
  .button.busy, .habla_offline_submit_input.loading, .habla_offline_submit_input.busy {
    color: transparent;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MkMxQjAwOEVGMjJGMTFFMzkxREJBRkFDQTYzOUNFNzAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MkMxQjAwOEZGMjJGMTFFMzkxREJBRkFDQTYzOUNFNzAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDoyQzFCMDA4Q0YyMkYxMUUzOTFEQkFGQUNBNjM5Q0U3MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDoyQzFCMDA4REYyMkYxMUUzOTFEQkFGQUNBNjM5Q0U3MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhwTbkAAAAAhSURBVHjaYmRgYFBjwAL+//9/C5s4EwOJYFTDSNEAEGAA4U4EFWD7wBAAAAAASUVORK5CYII=);
    animation: loading 1s linear infinite; }
  button.full-width,
  .button.full-width, .habla_offline_submit_input.full-width {
    width: 100%; }
  button.square,
  .button.square, .habla_offline_submit_input.square {
    border-radius: 0; }
  button.big,
  .button.big, .habla_offline_submit_input.big {
    min-width: 60px;
    min-height: 60px; }
  button.short,
  .button.short, .habla_offline_submit_input.short {
    min-width: 40px;
    min-height: 40px; }
  button.wide,
  .button.wide, .habla_offline_submit_input.wide {
    min-width: 155px; }
  button.h-spacing,
  .button.h-spacing, .habla_offline_submit_input.h-spacing {
    margin-left: 0.71429em;
    margin-right: 0.71429em; }
  button.h-spacing-big,
  .button.h-spacing-big, .habla_offline_submit_input.h-spacing-big {
    margin-left: 1.42857em;
    margin-right: 1.42857em; }
  button [class^='icons-']:first-child,
  button [class*=' icons-']:first-child,
  .button [class^='icons-']:first-child,
  .button [class*=' icons-']:first-child, .habla_offline_submit_input [class^='icons-']:first-child,
  .habla_offline_submit_input [class*=' icons-']:first-child {
    margin-right: 5px; }
  button [class^='icons-']:last-child,
  button [class*=' icons-']:last-child,
  .button [class^='icons-']:last-child,
  .button [class*=' icons-']:last-child, .habla_offline_submit_input [class^='icons-']:last-child,
  .habla_offline_submit_input [class*=' icons-']:last-child {
    margin-left: 5px; }

.choices {
  margin: 20px 0; }
  .choices .button {
    font-family: Circular-Pro-Book, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    border: 0;
    padding: 0 20px;
    color: #6f7b7b;
    background: #eff6f6;
    min-width: 20%; }
    .choices .button:active {
      color: #444f4f;
      background: #cad7d7; }
    .choices .button.selected {
      background: #6f7b7b;
      color: #ffffff; }
      .choices .button.selected:active {
        background: #444f4f; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
.decorated {
  text-decoration: underline; }

.seperate-row {
  border-top: 1px solid #eff6f6;
  border-bottom: 1px solid #eff6f6; }

.separate-bottom {
  border-bottom: 1px solid #eff6f6; }

.separate-top {
  border-top: 1px solid #eff6f6; }

.separate-children > * {
  border-bottom: 1px solid #eff6f6; }
  .separate-children > *:last-child {
    border-bottom: none; }

.white-background {
  background-color: #ffffff; }

.circled {
  border-radius: 50%;
  border: 2px solid currentColor;
  padding: 3px; }

.imprinted-code {
  display: inline-block;
  background-image: url("../../images/elements/coupon-code-bg.png");
  background-repeat: repeat-x;
  padding: 0 15px;
  color: #3ebeba;
  font-weight: bold;
  height: 36px;
  line-height: 36px; }

.separator {
  border-bottom: 2px solid #eff6f6;
  height: 0;
  font-size: 0; }

.drop-shadow {
  -webkit-filter: drop-shadow(6px 12px 0 rgba(0, 0, 0, 0.4));
  -moz-filter: drop-shadow(6px 12px 0 rgba(0, 0, 0, 0.4));
  -ms-filter: drop-shadow(6px 12px 0 rgba(0, 0, 0, 0.4));
  -o-filter: drop-shadow(6px 12px 0 rgba(0, 0, 0, 0.4));
  filter: drop-shadow(6px 12px 0 rgba(0, 0, 0, 0.4)); }

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0.5); }
  to {
    opacity: 0;
    transform: scale(2.5); } }

.ink {
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  transform: scale(0);
  z-index: 1;
  border-radius: 10000px;
  opacity: 0; }
  .ink.animate {
    animation: ripple 0.3s linear; }

.can-expand {
  position: relative; }
  .can-expand:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: #303838 transparent transparent;
    border-width: 8px 5px;
    margin-top: -4px; }
  .can-expand.is-expanded:after {
    border-color: transparent transparent #303838;
    margin-top: -12px; }

@media (orientation: landscape) {
  .landscape-group {
    font-size: 1em;
    display: inline; }
    .landscape-group.end.gutter-bottom-big:after {
      content: '';
      display: block;
      height: 0;
      margin-bottom: 40px; }
    .landscape-group.end.gutter-bottom-small:after {
      content: '';
      display: block;
      height: 0;
      margin-bottom: 10px; } }

.fade {
  transition: top 300ms ease;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(239, 246, 246, 0.9);
  z-index: 30; }

.modal-context {
  transition: left 300ms ease, top 300ms ease;
  z-index: 40;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .modal-context {
      padding-left: 260px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .modal-context {
      padding-left: 180px; } }

.modal {
  font-size: 14px;
  transition: all 300ms ease;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100%; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .modal {
      width: 400px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .modal {
      width: 375px; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .modal {
      min-height: 480px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .modal {
      min-height: 500px; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .modal.big {
      width: 600px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .modal.big {
      width: 450px; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .modal.big {
      height: 500px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .modal.big {
      height: 600px; } }
  .modal .close {
    padding: 10px;
    margin-right: -10px;
    display: block; }
  .modal .choices a {
    margin-top: 10px; }
    .modal .choices a :first-child {
      margin-top: 0; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .modal {
      border-radius: 5px; }
      .modal .modal-header {
        border-radius: 5px 5px 0 0; }
      .modal .close {
        position: fixed;
        top: 24px;
        right: 24px;
        background-color: #aab8b8;
        border-radius: 5px;
        margin: 0;
        color: #ffffff;
        padding: 6px; }
      .modal .modal-header {
        text-align: center; } }

.modal-header {
  padding: 0 24px;
  color: #ffffff;
  height: 75px;
  background-color: #4facdb;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .modal-header {
      height: 62px; } }
  .modal-header.alert {
    background-color: #ff8a50; }

.modal-content {
  flex: 1 1 0; }
  .modal-content.fit {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    top: 75px;
    overflow: hidden; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .modal-content.fit {
        top: 62px; } }

.alert-context {
  z-index: 50; }

.jurisdiction-alert {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  display: flex;
  background: #303838;
  align-items: center;
  flex-direction: column; }
  .jurisdiction-alert .modal-content {
    padding: 10%; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
@font-face {
  font-family: 'casumo-icons';
  src: url("/fonts/casumo-icons.eot?147435353700014743535370005ugh0u");
  src: url("/fonts/casumo-icons.eot?5ugh0u#iefix") format("embedded-opentype"), url("/fonts/casumo-icons.woff2?14743535370005ugh0u") format("woff2"), url("/fonts/casumo-icons.ttf?14743535370005ugh0u") format("truetype"), url("/fonts/casumo-icons.woff?14743535370005ugh0u") format("woff"), url("/fonts/casumo-icons.svg?14743535370005ugh0u#casumo-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^='icons-'],
[class*=' icons-'] {
  font-family: 'casumo-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 24px;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icons-chat:before {
  content: ""; }

.icons-send:before {
  content: ""; }

.icons-question-mark-white:before {
  content: ""; }

.icons-question-mark-filled:before {
  content: ""; }

.icons-triple-big-win:before {
  content: ""; }

.icons-triple-win:before {
  content: ""; }

.icons-prize:before {
  content: ""; }

.icons-playforfun:before {
  content: ""; }

.icons-back-button:before {
  content: ""; }

.icons-keypad:before {
  content: ""; }

.icons-bells:before {
  content: ""; }

.icons-bell:before {
  content: ""; }

.icons-arrows-spin:before {
  content: ""; }

.icons-avatar-head:before {
  content: ""; }

.icons-big:before {
  content: ""; }

.icons-mega:before {
  content: ""; }

.icons-quest:before {
  content: ""; }

.icons-star:before {
  content: ""; }

.icons-win:before {
  content: ""; }

.icons-valuable-tick:before {
  content: ""; }

.icons-eye-open:before {
  content: ""; }

.icons-eye-closed:before {
  content: ""; }

.icons-pending:before {
  content: ""; }

.icons-money-stack:before {
  content: ""; }

.icons-alert:before {
  content: ""; }

.icons-allseeingeye:before {
  content: ""; }

.icons-arrow-down:before {
  content: ""; }

.icons-arrow-left-curved:before {
  content: ""; }

.icons-arrow-left:before {
  content: ""; }

.icons-arrow-right-curved:before {
  content: ""; }

.icons-arrow-right:before {
  content: ""; }

.icons-arrow-up:before {
  content: ""; }

.icons-avatar-naked:before {
  content: ""; }

.icons-avatar:before {
  content: ""; }

.icons-belt:before {
  content: ""; }

.icons-bill-list:before {
  content: ""; }

.icons-bill-minus:before {
  content: ""; }

.icons-bill-plus:before {
  content: ""; }

.icons-briefcase:before {
  content: ""; }

.icons-chat-bubble:before {
  content: ""; }

.icons-christmas-tree:before {
  content: ""; }

.icons-cog:before {
  content: ""; }

.icons-coupon:before {
  content: ""; }

.icons-cross:before {
  content: ""; }

.icons-diamond:before {
  content: ""; }

.icons-direction-down:before {
  content: ""; }

.icons-direction-right:before {
  content: ""; }

.icons-direction-up:before {
  content: ""; }

.icons-dribbble:before {
  content: ""; }

.icons-e-mail:before {
  content: ""; }

.icons-exclamation-mark:before {
  content: ""; }

.icons-eye:before {
  content: ""; }

.icons-facebook:before {
  content: ""; }

.icons-fullscreen:before {
  content: ""; }

.icons-googleplus:before {
  content: ""; }

.icons-guage:before {
  content: ""; }

.icons-heart:before {
  content: ""; }

.icons-info:before {
  content: ""; }

.icons-instagram:before {
  content: ""; }

.icons-level:before {
  content: ""; }

.icons-linkedin:before {
  content: ""; }

.icons-list:before {
  content: ""; }

.icons-logout:before {
  content: ""; }

.icons-menu:before {
  content: ""; }

.icons-mobile:before {
  content: ""; }

.icons-more:before {
  content: ""; }

.icons-pending2:before {
  content: ""; }

.icons-place:before {
  content: ""; }

.icons-planet:before {
  content: ""; }

.icons-play:before {
  content: ""; }

.icons-rocket:before {
  content: ""; }

.icons-search:before {
  content: ""; }

.icons-shapes:before {
  content: ""; }

.icons-skype:before {
  content: ""; }

.icons-tick:before {
  content: ""; }

.icons-trophy:before {
  content: ""; }

.icons-tumblr:before {
  content: ""; }

.icons-twitter:before {
  content: ""; }

.icons-valuable:before {
  content: ""; }

.icons-vimeo:before {
  content: ""; }

.icons-wheel:before {
  content: ""; }

.icons-win2:before {
  content: ""; }

.icons-youtube:before {
  content: ""; }

.icons-lock:before {
  content: ""; }

.markdown {
  line-height: 1.6; }
  .markdown ul {
    margin: 20px 0;
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 20px; }
    .markdown ul:first-child {
      margin-top: 0; }
    .markdown ul:last-child {
      margin-bottom: 0; }
  .markdown * + p {
    margin-top: 20px; }

.date-picker {
  border: 0;
  text-align: right;
  background-color: #ffffff; }

.casumo-holding-valuables {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 140%; }
  .casumo-holding-valuables:before, .casumo-holding-valuables:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent no-repeat center center;
    background-size: 100%; }
  .casumo-holding-valuables:before {
    background-image: url("/images/casumos/casumo-holding-valuables-body.png"); }
  .casumo-holding-valuables:after {
    background-image: url("/images/casumos/casumo-holding-valuables-arms.png"); }
  .casumo-holding-valuables .valuables {
    position: absolute;
    top: 0;
    left: 10%;
    bottom: 19%;
    right: 10%;
    z-index: 0; }
    .casumo-holding-valuables .valuables > * {
      position: absolute; }
    .casumo-holding-valuables .valuables li {
      width: 100%;
      padding-bottom: 100%; }
    .casumo-holding-valuables .valuables img {
      width: 100%;
      position: absolute;
      z-index: 1; }
    .casumo-holding-valuables .valuables > *:nth-child(1) {
      z-index: 7;
      bottom: 0%; }
    .casumo-holding-valuables .valuables > *:nth-child(2) {
      z-index: 6;
      bottom: 17%; }
    .casumo-holding-valuables .valuables > *:nth-child(3) {
      z-index: 5;
      bottom: 34%; }
    .casumo-holding-valuables .valuables > *:nth-child(4) {
      z-index: 4;
      bottom: 51%; }
    .casumo-holding-valuables .valuables > *:nth-child(5) {
      z-index: 3;
      bottom: 68%; }
    .casumo-holding-valuables .valuables > *:nth-child(6) {
      z-index: 2;
      bottom: 85%; }
    .casumo-holding-valuables .valuables > *:nth-child(7) {
      z-index: 1;
      bottom: 102%; }

.icons-custom-menu {
  width: 48px;
  height: 48px;
  text-align: center;
  position: relative; }
  .icons-custom-menu span,
  .icons-custom-menu span::before,
  .icons-custom-menu span::after {
    content: '';
    display: block;
    height: 4px;
    width: 18px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 0; }
  .icons-custom-menu span {
    left: 50%;
    margin: -2px 0 0 -9px; }
  .icons-custom-menu span::before {
    margin-top: -10px; }
  .icons-custom-menu span::after {
    margin-top: 6px; }

.main-menu {
  background: #eff6f6; }
  .main-menu .divider {
    border-top: 2px solid #eff6f6;
    margin: 0 -24px; }
  .main-menu .icons-custom-menu {
    position: absolute;
    z-index: 2;
    top: 38px;
    left: 10px;
    transform: translateY(-50%); }
    @media (max-height: 375px) and (orientation: landscape) {
      .main-menu .icons-custom-menu {
        top: 32px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .main-menu .icons-custom-menu {
        display: none; } }
    .main-menu .icons-custom-menu span,
    .main-menu .icons-custom-menu span::before,
    .main-menu .icons-custom-menu span::after {
      background: #2f8f8c; }

.main-nav {
  background: #444f4f; }
  .main-nav .casumo-logo {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 120px;
    min-height: 75px;
    width: 100%; }
  .main-nav li {
    position: relative;
    overflow: hidden; }
  .main-nav .nav-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #303838;
    margin-bottom: 5px; }
    .main-nav .nav-icon .avatar {
      height: 34px;
      width: 34px; }
  .main-nav .nav-item a {
    font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    height: 115px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    transition: color 0.2s ease; }
    @media (min-width: 1024px) and (orientation: landscape) {
      .main-nav .nav-item a {
        height: 100px; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .main-nav .nav-item a {
        height: 125px; } }
    .main-nav .nav-item a .nav-label {
      display: block; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .main-nav .nav-item a::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 2%;
        height: 100%;
        transition: width 100ms ease-in; } }
  .main-nav .nav-item-home a {
    height: 75px; }
    @media (max-height: 480px) and (orientation: portrait) {
      .main-nav .nav-item-home a {
        height: 64px; } }
    @media (max-height: 375px) and (orientation: landscape) {
      .main-nav .nav-item-home a {
        height: 64px; } }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .main-nav .nav-item-home a {
        height: 100px; } }
  .main-nav .nav-item-player a {
    color: #3ebeba; }
    .main-nav .nav-item-player a::after {
      background: #3ebeba; }
  .main-nav .nav-item-cash a {
    color: #ffc930; }
    .main-nav .nav-item-cash a::after {
      background: #ffc930; }
  .main-nav .nav-item-games a {
    color: #ff8a50; }
    .main-nav .nav-item-games a::after {
      background: #ff8a50; }

.nav-item a * {
  position: relative;
  z-index: 1; }

.nav-item a.selected {
  color: #444f4f; }
  .nav-item a.selected::after {
    transition-delay: 0;
    width: 100%; }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .main-nav .nav-icon {
    margin-bottom: 2px; }
  .nav-item-home a,
  .nav-item-player a {
    background-color: #3ebeba; }
    .nav-item-home a:active,
    .nav-item-player a:active {
      background-color: #8cd9d6; }
  .nav-item-cash a {
    background-color: #ffc930; }
    .nav-item-cash a:active {
      background-color: #ffd073; }
  .nav-item-games a {
    background-color: #ff8a50; }
    .nav-item-games a:active {
      background-color: #ffa383; }
  .main-nav .nav-item a {
    color: #303838; } }

.sub-nav .nav-item a {
  font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 106px;
  color: #aab8b8;
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .sub-nav .nav-item a {
      height: 91px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .sub-nav .nav-item a {
      height: 116px; } }
  .sub-nav .nav-item a:active {
    color: #6f7b7b;
    background-color: #cad7d7; }

.list .list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eff6f6;
  padding: 20px 24px; }
  .list .list-item:active:not(.inactive), .list .list-item.active {
    background: #e6eeee; }
  .list .list-item:first-child {
    border-top: 1px solid #eff6f6; }
  .list .list-item > * {
    margin-right: 20px; }
  .list .list-item > *:last-child {
    margin-right: 0; }
  .list .list-item .item-body {
    flex: 1; }
  .list .list-item.disabled img {
    opacity: 0.5; }
  .list .list-item.disabled .icon {
    opacity: 0.25; }

.list .before-list-item {
  margin: 20px 24px 0; }

.list .list-item-actions {
  display: flex; }
  .list .list-item-actions > * {
    margin-right: 24px; }
  .list .list-item-actions > *:last-child {
    margin-right: 0; }

.list.grey {
  background-color: #eff6f6; }
  .list.grey .list-item {
    border-color: #cad7d7; }

.spaced-item-list li {
  margin: 0 0.35714em; }

.spaced-item-list li:first-child {
  margin-left: 0; }

.spaced-item-list li:last-child {
  margin-right: 0; }

.list-inline {
  display: inline-block; }

.objectives-list {
  color: #6f7b7b; }
  .objectives-list .list-item {
    padding: 20px 12px;
    border-top: 1px solid #aab8b8; }
    .objectives-list .list-item:first-child {
      border: 0; }
  .objectives-list .list-item-current {
    background: #ff8a50;
    color: #ffffff;
    border-top: 0; }
    .objectives-list .list-item-current + .list-item {
      border-top: 0; }
  .objectives-list .list-item-completed {
    background: #cad7d7;
    color: #6f7b7b; }
  .objectives-list .progress-count {
    color: #ff8a50;
    background: #ffffff;
    padding: 0.4em 0.7em;
    border-radius: 2em;
    margin-right: 0.25em; }

.top-bar {
  display: flex;
  flex-direction: row;
  background-color: #303838; }
  .top-bar.background-logo {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 120px; }
  .top-bar .top-bar-action {
    display: flex; }
    .top-bar .top-bar-action a {
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px; }
  .top-bar .top-bar-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #cad7d7; }
  .top-bar .sub-nav {
    height: 100%;
    display: flex;
    flex: 1;
    flex-basis: auto;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 10px; }
    .top-bar .sub-nav li {
      position: relative;
      font-size: 0.78571em;
      display: flex; }
      @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
        .top-bar .sub-nav li {
          margin: 10px 0; } }
    .top-bar .sub-nav a {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      color: #aab8b8;
      padding: 0 0.7em; }
      .top-bar .sub-nav a .icon {
        margin-bottom: 5px;
        display: block; }
      .top-bar .sub-nav a.active {
        border-bottom: 4px solid currentColor;
        padding-top: 4px; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .top-bar .sub-nav {
        background-color: #ffffff; }
        .top-bar .sub-nav a {
          color: #303838; } }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .top-bar {
      background: #ffffff;
      border-bottom: 1px solid #eff6f6; }
      .top-bar .sub-nav {
        justify-content: center; }
        .top-bar .sub-nav [class^="icons-"],
        .top-bar .sub-nav [class*=" icons-"] {
          font-size: 32px; }
        .top-bar .sub-nav a {
          margin: 0 10px; } }
  .top-bar .icons-custom-menu {
    transform: translate3d(0, 0, 0);
    background: #242a2a;
    border-radius: 50%; }
    .top-bar .icons-custom-menu span {
      background: #ffc930; }
    .top-bar .icons-custom-menu span::before {
      background: #3ebeba; }
    .top-bar .icons-custom-menu span::after {
      background: #ff8a50; }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .top-bar.player .top-bar-action,
  .top-bar.player .active {
    color: #3ebeba; }
  .top-bar.cash .top-bar-action,
  .top-bar.cash .active {
    color: #ffc930; }
  .top-bar.games .top-bar-action,
  .top-bar.games .active {
    color: #ff8a50; } }

.numeric-keypad-container {
  max-height: 290px;
  min-height: 170px;
  height: 60%;
  position: relative; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .numeric-keypad-container {
      margin: 0 auto;
      min-height: 250px; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .numeric-keypad-container {
      max-width: 480px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .numeric-keypad-container {
      max-width: 410px; } }

.numeric-keypad {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 5px; }
  .numeric-keypad .key-row {
    display: flex;
    flex: 1;
    justify-content: space-around; }
  .numeric-keypad button.key {
    display: inline-block;
    text-align: center;
    border: 0;
    border-radius: 0;
    padding: 0;
    min-height: 0; }
  .numeric-keypad .key {
    color: #6f7b7b;
    font-size: 1.28571em;
    margin: 2px;
    text-spacing: 0;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    user-select: none;
    background-color: #eff6f6;
    transition: all 25ms; }
    .numeric-keypad .key.is-good {
      background-color: #19ac51;
      color: #ffffff; }
    .numeric-keypad .key:active {
      background-color: #47bd74;
      color: #ffffff; }
    .numeric-keypad .key:disabled {
      color: #ffffff; }

.split-value {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1; }
  .split-value .split-value-label {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 34px;
    transition: all 0.3s; }
    @media (min-width: 1024px) and (orientation: landscape) {
      .split-value .split-value-label {
        font-size: 1.28571em; } }
    .split-value .split-value-label label {
      display: block; }
  .split-value .split-value-value {
    width: 100%; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .split-value .split-value-value {
        width: 70%;
        margin: 0 auto; } }
    .split-value .split-value-value .value-wrapper {
      display: flex;
      padding: 10px;
      justify-content: center; }
    .split-value .split-value-value span {
      font-size: 1.28571em;
      text-align: center;
      max-width: 1.4em;
      justify-content: center;
      flex: 1;
      flex-shrink: 0;
      display: flex;
      margin: 0 0.07143em;
      min-height: 1.3em;
      border-bottom: 2px solid #cad7d7; }
      .creditcard .split-value .split-value-value span:nth-of-type(4n+1) {
        margin-left: 0.42857em; }
      .split-value .split-value-value span:first-child {
        margin-left: 0; }
      .split-value .split-value-value span:not(:empty) {
        border-bottom-color: #303838; }
        .split-value .split-value-value span:not(:empty).is-bad {
          border-bottom-color: #f05e5e; }
      .split-value .split-value-value span:empty {
        animation: cursor 0.5s alternate infinite; }
        .split-value .split-value-value span:empty::after {
          color: #cad7d7;
          content: attr(data-placeholder); }
        .split-value .split-value-value span:empty ~ span {
          border-bottom-color: #cad7d7;
          animation: none; }

@keyframes cursor {
  0% {
    border-bottom-color: #cad7d7; }
  100% {
    border-bottom-color: 8; } }

.global-loader {
  width: 100px;
  height: 100px;
  background-color: #eff6f6;
  border-radius: 50%;
  position: relative;
  left: 50%;
  margin-left: -50px;
  transform: rotate(45deg); }
  .global-loader .ring {
    border-radius: 50%;
    position: absolute;
    border-width: 10px;
    border-style: solid;
    top: 50%;
    left: 50%; }
    .global-loader .ring:nth-child(1) {
      border-color: transparent #ff8a50 #ff8a50 transparent;
      width: 44px;
      height: 44px;
      margin-left: -22px;
      margin-top: -22px;
      animation-delay: 200ms; }
    .global-loader .ring:nth-child(2) {
      border-color: transparent #ffc930 #ffc930 transparent;
      width: 64px;
      height: 64px;
      margin-left: -32px;
      margin-top: -32px;
      animation-delay: 100ms; }
    .global-loader .ring:nth-child(3) {
      border-color: transparent #3ebeba #3ebeba transparent;
      width: 84px;
      height: 84px;
      margin-left: -42px;
      margin-top: -42px; }

@keyframes loader-ring {
  0% {
    transform: rotate(0); }
  30% {
    transform: rotate(170deg); }
  70% {
    transform: rotate(190deg); }
  100% {
    transform: rotate(359deg); } }

.trophies-page {
  position: relative; }

.trophies-container {
  width: 100%; }
  .trophies-container .trophy-info-box {
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 0;
    background-color: #a85aa6;
    width: 100%;
    color: #ffffff;
    min-height: 80px;
    padding: 10px; }
    .trophies-container .trophy-info-box.ghost {
      position: fixed;
      height: auto;
      left: -1000px;
      top: -1000px; }
    .trophies-container .trophy-info-box .text-area {
      padding: 0 12px;
      text-align: left; }
    .trophies-container .trophy-info-box .purple-text {
      color: #a85aa6; }
  .trophies-container .trophy-container {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin-bottom: 10px;
    text-align: center;
    margin-left: -2px;
    margin-right: -2px; }
    @media (max-height: 375px) and (orientation: landscape) {
      .trophies-container .trophy-container {
        width: 16.7%; } }
    @media (min-width: 1024px) and (orientation: landscape) {
      .trophies-container .trophy-container {
        width: 16.7%; } }
    .trophies-container .trophy-container .empty-trophy {
      display: block;
      background-color: #cad7d7;
      width: 64px;
      height: 64px;
      border-radius: 10px;
      margin: 0 auto; }
  .trophies-container .arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #a85aa6;
    margin: 0 auto; }

.games-list {
  overflow: auto; }

.game-maintenance-mode {
  background: rgba(202, 215, 215, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .game-maintenance-mode p {
    max-width: 100px;
    text-align: center; }

.game-maintenance-mode-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 17px; }

.game-tile {
  float: left;
  width: 50%;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  z-index: 2;
  transform: translate3d(0, 0, 0); }
  .game-tile:nth-child(4n+1), .game-tile:nth-child(4n) {
    background-color: #eff6f6; }
  .game-tile .tile-inner {
    width: 100%;
    padding-bottom: 120%; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .game-tile {
      width: 25%; }
      .game-tile:nth-child(4n+1), .game-tile:nth-child(4n) {
        background-color: #ffffff; }
      .game-tile:nth-child(8n+1), .game-tile:nth-child(8n+3), .game-tile:nth-child(8n+6), .game-tile:nth-child(8n+8) {
        background-color: #eff6f6; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .game-tile {
      width: 33.3%; }
      .game-tile:nth-child(4n+1), .game-tile:nth-child(4n) {
        background-color: #ffffff; }
      .game-tile:nth-child(6n+1), .game-tile:nth-child(6n+3), .game-tile:nth-child(6n+5) {
        background-color: #eff6f6; } }
  @media (max-height: 375px) and (orientation: landscape) {
    .game-tile {
      width: 25%; }
      .game-tile:nth-child(4n+1), .game-tile:nth-child(4n) {
        background-color: #ffffff; }
      .game-tile:nth-child(8n+1), .game-tile:nth-child(8n+3), .game-tile:nth-child(8n+6), .game-tile:nth-child(8n+8) {
        background-color: #eff6f6; } }
  .game-tile .game-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .game-tile .game-title {
    font-size: 12px;
    height: 20px;
    display: flex;
    align-items: center; }
    .game-tile .game-title a {
      color: #ffffff; }
  .game-tile .game-info-layer {
    padding: 15% 24px;
    color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(62, 190, 186, 0.9);
    display: flex;
    z-index: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    visibility: hidden; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .game-tile .game-info-layer {
        padding: 10% 24px; } }
  .game-tile .game-link {
    color: #303838;
    background: #ffffff;
    border-radius: 50%; }
  .game-tile .play-button {
    color: #303838;
    padding: 22px;
    background: #ffffff;
    border-radius: 50%; }
  .game-tile .more-info {
    height: 35px;
    width: 35px; }
  .game-tile.selected .game-info-layer {
    visibility: visible; }

.language-selector-item {
  padding: 4px 0 2px;
  border-bottom: 2px solid transparent; }
  .language-selector-item.selected {
    border-bottom-color: #cad7d7; }

.main-menu .language-selector-items {
  padding: 0 0.71429em;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.main-menu .language-selector-item.selected {
  border-bottom-color: #cad7d7; }

.page-footer .language-selector-item {
  display: inline-block;
  margin: 0 0.42857em; }
  .page-footer .language-selector-item.selected {
    border-bottom-color: #303838; }

.progress-donut {
  position: relative;
  width: 100px;
  height: 100%;
  margin: 0 auto;
  margin-top: 5px;
  z-index: 1; }
  @media (min-width: 768px) and (orientation: portrait) {
    .progress-donut {
      width: 25%; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .progress-donut {
      width: 25%; } }
  @media (max-height: 375px) and (orientation: landscape) {
    .progress-donut {
      width: 17%; } }
  .progress-donut .casumo-progress-container {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    z-index: 2; }
    .progress-donut .casumo-progress-container.container-casumo {
      position: absolute;
      top: 15%;
      left: 15%;
      bottom: 15%;
      right: 15%; }
    .progress-donut .casumo-progress-container.container-spaceship {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: image-url("images/casumos/spaceship.png", false, false) !important; }
  .progress-donut .donut-wrapper {
    transform: translate3d(0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1; }
    .progress-donut .donut-wrapper svg {
      display: block;
      margin-left: auto;
      margin-right: auto; }
  .progress-donut .donut {
    transform: rotate(-170deg);
    width: 100%;
    height: 100%; }
    .progress-donut .donut circle {
      stroke-linecap: round;
      transition: stroke 0.3s ease; }
      .progress-donut .donut circle.transparent {
        fill: transparent; }
      .progress-donut .donut circle.half-transparent {
        fill: rgba(0, 0, 0, 0.5); }
    .progress-donut .donut.animating .fill {
      transition: stroke 0s ease;
      stroke: #ffffff; }
  .progress-donut .level {
    position: absolute;
    top: 46%;
    left: 0;
    bottom: auto;
    right: auto;
    left: -16%;
    height: 23%;
    display: flex;
    justify-content: space-between;
    justify-content: space-around;
    flex-direction: column;
    margin-top: -0.5em;
    color: #3ebeba;
    text-align: center;
    border: 1px solid;
    border-radius: 20px;
    background-color: #ffffff;
    width: 35px; }
    .progress-donut .level .text {
      color: #303838;
      line-height: 1; }
    @media (min-width: 768px) and (orientation: portrait) {
      .progress-donut .level {
        width: 40px; } }
    @media (min-width: 1024px) and (orientation: landscape) {
      .progress-donut .level {
        width: 55px;
        left: -16%; } }

.notifications {
  position: absolute;
  z-index: 60;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #19ac51;
  transform: translateY(100%);
  transition: all 150ms ease; }
  .notifications .notifications-inner {
    position: relative;
    margin: 0 auto; }
    @media (min-width: 1024px) and (orientation: landscape) {
      .notifications .notifications-inner {
        width: 578px; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .notifications .notifications-inner {
        width: 408px; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .notifications .notifications-inner {
        width: 100%; } }
  .notifications:active .more-info .icon {
    color: #19ac51;
    background-color: #13813d;
    border-color: #13813d; }
  .notifications:active .dismiss-notifications:active + .more-info .icon {
    color: #13813d;
    background-color: transparent; }
  .notifications .dismiss-notifications,
  .notifications .more-info {
    transition: all 300ms ease;
    color: #13813d; }
    .notifications .dismiss-notifications:active .icon,
    .notifications .more-info:active .icon {
      color: #19ac51;
      background-color: #13813d;
      border-color: #13813d; }
  .notifications .button {
    transition: all 300ms ease;
    color: #19ac51; }
  .notifications.alert {
    background-color: #ff8a50; }
    .notifications.alert:active .more-info .icon {
      color: #ff8a50;
      background-color: #d36442;
      border-color: #d36442; }
    .notifications.alert:active .dismiss-notifications:active + .more-info .icon {
      color: #d36442;
      background-color: transparent; }
    .notifications.alert .dismiss-notifications,
    .notifications.alert .more-info {
      transition: all 300ms ease;
      color: #d36442; }
      .notifications.alert .dismiss-notifications:active .icon,
      .notifications.alert .more-info:active .icon {
        color: #ff8a50;
        background-color: #d36442;
        border-color: #d36442; }
    .notifications.alert .button {
      color: #ff8a50; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .notifications {
      left: 260px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .notifications {
      left: 180px; } }
  .notifications.active {
    transition: all 300ms ease;
    transform: translateY(0); }
  .notifications .dismiss-notifications,
  .notifications .more-info {
    position: absolute;
    top: 15px;
    z-index: 2; }
  .notifications .dismiss-notifications {
    right: 10px; }
  .notifications .more-info {
    left: 10px; }
  .notifications .notification-body,
  .notifications .button {
    margin-top: 20px; }
  .notifications .notification-image {
    min-height: 48px; }
    .notifications .notification-image .icon {
      font-size: 48px; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .notifications .notifications-inner {
      display: flex;
      justify-content: center; }
    .notifications .notification {
      padding-top: 65px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto; }
      .notifications .notification > * {
        margin-left: 24px; }
      .notifications .notification :first-child {
        margin-left: 0; }
      .notifications .notification .notification-body {
        flex: 1;
        text-align: left; }
      .notifications .notification .notification-body,
      .notifications .notification .button {
        margin-top: 0; }
    .notifications .dismiss-notifications {
      right: 50%;
      transform: translateX(150%); }
    .notifications .more-info {
      left: 50%;
      transform: translateX(-150%); } }

.notification-dialog .notification-action {
  width: 3.57143em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.78571em; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
.quest-promo {
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center; }

.current-quest-container {
  height: 360px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden; }
  .current-quest-container.smaller-quest-container {
    height: 210px; }

.quest-path-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 52px;
  margin-top: -10px;
  transition: all; }
  .quest-path-container .quest-scroll {
    max-width: 100px; }

.current-quest-box {
  background: #ff8a50; }

.page .current-quest-box {
  background: #ff8a50;
  z-index: 1;
  border-radius: 3px 3px 0 0;
  max-width: 350px;
  margin: 0 30px;
  flex: 1;
  position: relative; }

.quest-scroll {
  margin: 0 auto;
  max-width: 260px; }
  .quest-scroll .quest-scroll-top,
  .quest-scroll .quest-scroll-bottom {
    height: 12px;
    margin: 0 -20px;
    background-image: url("/images/quests/scroll-end.gif");
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 1;
    border-radius: 1px; }
  .quest-scroll .quest-scroll-content {
    position: relative;
    background: #fff0d9;
    padding: 20px;
    text-align: center; }
    .quest-scroll .quest-scroll-content:after, .quest-scroll .quest-scroll-content:before {
      content: '';
      position: absolute;
      top: 0;
      width: 20px;
      height: 100%;
      background-repeat: repeat-y; }
    .quest-scroll .quest-scroll-content:before {
      background-image: url("/images/quests/scroll-border-left.png");
      left: -20px; }
    .quest-scroll .quest-scroll-content:after {
      background-image: url("/images/quests/scroll-border-right.png");
      right: -20px; }

.quests {
  display: flex;
  flex-flow: row wrap;
  z-index: 0;
  position: relative; }
  .quests .quest-icon {
    font-size: 32px; }
  .quests .quest-icon--big {
    font-size: 42px; }

.quest-tile {
  width: 50%; }

.quest {
  display: inline-block;
  margin: 3px 17px;
  width: 60px;
  height: 60px;
  background: #eff6f6;
  border: 2px solid #aab8b8;
  color: #6f7b7b;
  border-radius: 10px;
  transform: rotate(-45deg);
  transition: all 0.5s ease;
  position: relative;
  box-sizing: border-box; }

.quest-inner {
  display: inline-block;
  transform: rotate(45deg);
  height: 100%;
  width: 100%; }

.quest-completed {
  background: #b2e5e4;
  border-color: #8cd9d6;
  color: #d9f2f1;
  background-clip: padding-box; }
  .quest-completed .icon-state-normal {
    opacity: 0; }
  .quest-completed .icon-state-completed {
    opacity: 1; }

.quest-disabled {
  background: #eff6f6;
  border-color: #cad7d7;
  color: #cad7d7;
  background-clip: padding-box; }
  .quest-disabled .icon-state-disabled {
    opacity: 1; }
  .quest-disabled .icon-state-normal {
    opacity: 0; }

.quest-tile--current {
  z-index: 999 !important;
  position: relative; }
  .quest-tile--current .quest {
    background: #ff8a50;
    border-color: transparent; }
  .quest-tile--current .icon-state-normal {
    color: #ffffff; }

.half {
  text-align: right; }

.half:nth-child(2n) {
  text-align: left; }

.full {
  width: 100%;
  text-align: center; }

.half + .full,
.full + .half,
.full + .half + .half {
  margin-top: -19px; }

.quest-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
  transition: opacity 0.5s ease; }

.icon-state-normal {
  opacity: 1; }

.quest-valuable {
  margin-top: 5px;
  width: 46px; }

.quests--small .quest {
  width: 46px;
  height: 46px;
  margin: 0 13px; }

.quests--small .quest-valuable {
  width: 32px; }

.quests--small .half + .full,
.quests--small .full + .half,
.quests--small .full + .half + .half {
  margin-top: -12px; }

.quests--small .quest-icon {
  font-size: 24px; }

.quests--small .quest-icon--big {
  font-size: 32px; }

.circle-button {
  background: #ffffff;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1; }

.quest-leaderboard-dialog .prize-image-block {
  padding-top: 1rem; }
  .quest-leaderboard-dialog .prize-image-block img {
    display: block;
    margin: 0 auto; }

.tournament-campaign {
  padding: 20px 24px 0; }
  .tournament-campaign .next-tournaments {
    background: #ffffff;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto; }
    .tournament-campaign .next-tournaments .list-item {
      padding: 0.78571em; }
    .tournament-campaign .next-tournaments .tournament-label {
      font-size: 1.14286em;
      margin-bottom: 5px; }

.tournament-points-info .points-info-item {
  display: flex;
  min-height: 4.5em;
  align-items: center;
  padding: 12px; }

.tournament-points-info .powerup-icon {
  width: 22%; }

.tournament-points-info p {
  width: 78%; }

.who-it-works {
  padding-top: 160px; }
  .who-it-works .buttons .button {
    width: 200px; }

.reel-races-buttons {
  text-align: center;
  padding: 2em 0 2em;
  border-top: 1px solid #eff6f6; }
  .reel-races-buttons .button {
    width: 200px; }

.tournament-tracker .tournament-countdown {
  font-size: 1.14286em; }

.tournament-tracker .tournament-details-box {
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  margin: auto; }

.tournament-tracker .icons-box {
  width: 60px;
  border-left: 1px solid #eff6f6; }
  .tournament-tracker .icons-box .border-top {
    border-top: 1px solid #eff6f6; }

.tournament-tracker .tournament-details .stats {
  align-items: center;
  justify-content: center; }
  .tournament-tracker .tournament-details .stats .icon-14 {
    margin-right: 0.3em; }

.tournament-tracker .tournament-details .rank, .tournament-tracker .tournament-details .points {
  border-right: 1px solid #eff6f6; }

.tournament-card .tournament-actions a {
  margin-right: 24px; }
  .tournament-card .tournament-actions a:last-child {
    margin-right: 0; }

.tournament-card .starts-when {
  font-size: 1.28571em; }

.tournament-card .sub-info {
  font-size: 0.85714em;
  color: #aab8b8; }

.tournament-card .tournament-hints ul {
  text-align: left;
  display: inline-block;
  list-style-type: disc;
  color: #47bd74; }

.tournament-card.promoted {
  color: #ffc930; }
  .tournament-card.promoted .sub-info {
    color: #ffd073; }
  .tournament-card.promoted .tournament-hints ul {
    color: #ffa383; }

.tournament-schedule li {
  position: relative; }

.tournament-schedule .time {
  min-width: 65px;
  text-align: right;
  font-family: Circular-Pro-BlackItalic, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400; }

.tournament-schedule .points {
  font-style: italic; }

.tournament-schedule .details .time {
  text-align: left; }

.tournament-schedule .hide-element {
  display: none; }

.tournament-schedule li:first-child .indicator-container:before,
.tournament-schedule li:last-child .indicator-container:after {
  background: inherit; }

.tournament-schedule .indicator-container {
  min-width: 40px; }
  .tournament-schedule .indicator-container:after, .tournament-schedule .indicator-container:before {
    display: block;
    content: '';
    background: #eff6f6;
    position: absolute;
    width: 2px;
    margin-left: 19px; }
  .tournament-schedule .indicator-container:after {
    top: 50%;
    bottom: 0;
    margin-top: 5px; }
  .tournament-schedule .indicator-container:before {
    top: 0%;
    bottom: 50%;
    margin-bottom: 5px; }
  .tournament-schedule .indicator-container.big-indicator:after {
    margin-top: 10px; }
  .tournament-schedule .indicator-container.big-indicator:before {
    margin-bottom: 10px; }

.tournament-schedule .indicator {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 15px; }
  .tournament-schedule .indicator.big-indicator {
    width: 20px;
    height: 20px;
    margin: 0 10px; }

.tournament-details-dialog .time {
  color: #19ac51;
  font-size: 2.28571em;
  text-align: center; }

.tournament-details-dialog .remaining-spins {
  color: #444f4f;
  font-weight: bold; }

.blue-banner-icon-container {
  margin: auto 24px auto auto; }

.tournament-standings .booster {
  position: relative;
  margin: 0 auto;
  width: 64px;
  height: 64px;
  text-align: center; }
  .tournament-standings .booster svg {
    position: absolute;
    top: 0;
    left: 0; }
  .tournament-standings .booster .icon {
    font-size: 32px;
    line-height: 64px; }
  .tournament-standings .booster.triple-win-booster circle {
    fill: none;
    stroke: #aab8b8;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-miterlimit: 10; }
    .tournament-standings .booster.triple-win-booster circle:nth-child(2) {
      stroke: #19ac51;
      stroke-dasharray: 0% 300%;
      transform: rotate(-90deg);
      transform-origin: 50% 50%; }
  .tournament-standings .booster.triple-win-booster[data-streak="1"] circle:nth-child(2) {
    stroke-dasharray: 100% 300%; }
  .tournament-standings .booster.triple-win-booster[data-streak="2"] circle:nth-child(2) {
    stroke-dasharray: 200% 300%; }
  .tournament-standings .booster.double-big-win-booster circle {
    fill: none;
    stroke: #aab8b8;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 128% 300%;
    transform-origin: 50% 50%; }
    .tournament-standings .booster.double-big-win-booster circle:nth-child(1) {
      transform: rotate(-82deg); }
    .tournament-standings .booster.double-big-win-booster circle:nth-child(2) {
      transform: rotate(98deg); }
  .tournament-standings .booster.double-big-win-booster[data-streak="1"] circle:nth-child(1), .tournament-standings .booster.double-big-win-booster[data-streak="2"] circle:nth-child(1) {
    stroke: #19ac51; }
  .tournament-standings .booster.double-big-win-booster[data-streak="2"] circle:nth-child(2) {
    stroke: #19ac51; }
  .tournament-standings .booster.mega-win-booster path {
    fill: none;
    stroke: #aab8b8;
    stroke-width: 15;
    stroke-linecap: round;
    stroke-miterlimit: 10; }

.tournament-standings .tournament-prizes img:nth-child(1) {
  width: 54px;
  height: 54px;
  margin-left: -45px; }

.tournament-standings .tournament-prizes img:nth-child(2) {
  width: 54px;
  height: 54px;
  margin-left: 48px; }

.tournament-standings .tournament-prizes img:nth-child(3) {
  width: 64px;
  height: 64px;
  margin-left: -112px;
  margin-top: -5px; }

.tournament-standings .leaderboard {
  text-align: left;
  padding-top: 10px; }

.tournament-standings .board-item, .tournament-standings .board-header {
  padding: 15px 36px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.tournament-standings .board-header {
  padding-bottom: 0;
  padding-top: 0; }

.tournament-standings.finished .board-item, .tournament-standings.finished .board-header {
  padding-left: 24px;
  padding-right: 24px; }

.tournament-standings .prize {
  text-align: center;
  width: 30%; }

.tournament-standings .rank {
  width: 10%;
  text-align: right;
  position: relative; }

.tournament-standings .player {
  width: 45%;
  position: relative;
  text-overflow: ellipsis; }

.tournament-standings .spins-badge {
  padding: 2px 5px;
  border-radius: 50px;
  min-width: 60px; }

.tournament-standings .score {
  width: 15%;
  text-align: right;
  display: inline-block;
  color: #aab8b8;
  position: relative; }

.tournament-standings .you,
.tournament-standings .you .score {
  background: #ffc930;
  color: #965c30;
  font-weight: bold;
  font-family: Circular-Pro-Black;
  position: relative;
  overflow: hidden; }

.reel-races .modal-tabs .selected-x {
  border-bottom: 2px solid #444f4f; }

.modal-tabs {
  display: flex;
  text-align: center; }

.modal-tab {
  flex: 1;
  background: #eff6f6;
  padding: 20px 0;
  margin-right: 2px;
  border-bottom: 2px solid transparent; }
  .modal-tab:last-child {
    margin-right: 0; }
  .modal-tab .text {
    border-top: 3px solid transparent;
    border-bottom: 3px solid #eff6f6;
    padding: 3px 2px; }
  .modal-tab.selected {
    border-bottom-color: #444f4f; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
table {
  width: 100%;
  table-layout: fixed;
  border-spacing: 1px;
  border-collapse: separate;
  border: 0; }
  table th,
  table td {
    padding: 5px 8px; }
  table .no-pad {
    padding: 0; }
  table td:not([align]) {
    text-align: left; }
  table tr:nth-child(2n) td {
    background: #eff6f6; }
  table th {
    font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    background: #cad7d7; }

.leaderboard-table {
  table-layout: auto; }

.white-table {
  border-collapse: collapse; }
  .white-table th, .white-table tr {
    background-color: #ffffff;
    text-align: left; }
  .white-table tr:nth-child(2n) td {
    background-color: #ffffff; }

.balance-widget {
  text-align: center; }
  .balance-widget .icon {
    background-image: url("/images/icons/deposit-widget-icon.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 26px;
    margin: 0 auto 8px; }

.dgrid-widget {
  display: flex;
  flex-wrap: wrap; }
  .dgrid-widget .dgrid-block {
    min-height: 200px;
    width: 50%; }
    .dgrid-widget .dgrid-block .left-side {
      width: 50%; }
    .dgrid-widget .dgrid-block .right-side {
      width: 50%; }
    .dgrid-widget .dgrid-block.full-width-block {
      width: 100%; }
    .dgrid-widget .dgrid-block .game-thumb {
      width: 135px;
      height: 135px;
      background-size: cover;
      background-position: 50%;
      border-radius: 50%;
      overflow: hidden; }
      .dgrid-widget .dgrid-block .game-thumb .game-logo {
        background-size: cover;
        height: 100%; }
    .dgrid-widget .dgrid-block .last-played-block {
      height: 200px;
      background-color: #ffc930; }
    .dgrid-widget .dgrid-block .valuable-block {
      height: 200px;
      color: #ffffff; }
      .dgrid-widget .dgrid-block .valuable-block.click-state {
        height: auto;
        min-height: 200px; }
      .dgrid-widget .dgrid-block .valuable-block .valuable-icon {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 64px; }
      .dgrid-widget .dgrid-block .valuable-block .deposit-button {
        background-color: #19ac51;
        border-color: #19ac51;
        color: #ffffff; }
    .dgrid-widget .dgrid-block .reel-races-block {
      height: 200px;
      background-color: #ff8a50;
      color: #ffffff; }
      .dgrid-widget .dgrid-block .reel-races-block .valuable-icon {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 64px; }
    .dgrid-widget .dgrid-block .reel-races-big-block {
      height: 200px;
      color: #ffffff; }
      .dgrid-widget .dgrid-block .reel-races-big-block .reel-races-logo {
        overflow: hidden;
        height: 200px;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center; }
      .dgrid-widget .dgrid-block .reel-races-big-block .number-box {
        width: 25px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        background: #8cd9d6;
        border-radius: 5px; }
      .dgrid-widget .dgrid-block .reel-races-big-block .number-box:nth-child(3) {
        background: none;
        width: 5px; }
      .dgrid-widget .dgrid-block .reel-races-big-block .left-side-60 {
        width: 60%; }
      .dgrid-widget .dgrid-block .reel-races-big-block .right-side-40 {
        width: 40%; }
    .dgrid-widget .dgrid-block .trophies-block {
      background-color: #0085c1;
      color: #ffffff;
      height: 200px; }
      .dgrid-widget .dgrid-block .trophies-block .valuable-icon {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 64px; }
    .dgrid-widget .dgrid-block .recommended-game-block {
      height: 200px;
      background-color: #a85aa6; }
      .dgrid-widget .dgrid-block .recommended-game-block.alternative-bg-color {
        background-color: #ffc930; }
    .dgrid-widget .dgrid-block .curated-content-block {
      height: 100%; }
      .dgrid-widget .dgrid-block .curated-content-block .image {
        height: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain; }
      .dgrid-widget .dgrid-block .curated-content-block.background-image {
        background-size: cover; }
    .dgrid-widget .dgrid-block .all-games-block {
      height: 200px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-position-y: 54%; }

.games-header .balance-widget {
  background-color: #eff6f6; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
.help-container {
  display: none; }

.help-container.open {
  display: block;
  z-index: 99999;
  width: 100%; }

.help-wrapper {
  background: #ffffff;
  transition: transform 0.2s ease; }

.casumo-bar {
  transform: translate3d(0, 0, 0);
  width: 100%;
  position: absolute;
  bottom: -1px !important;
  min-height: 420px;
  height: calc(100vh - (3 * 115px + 100px)); }
  @media (min-width: 1024px) and (orientation: landscape) {
    .casumo-bar {
      height: calc(100vh - (3 * 100px + 100px)); } }
  @media (min-width: 768px) and (orientation: portrait) {
    .casumo-bar {
      height: calc(100vh - (3 * 125px + 100px)); } }

.casumo-bar.help-wrapper {
  z-index: 31;
  bottom: 0;
  transform: translateX(-100%);
  border-right: solid 2px #EAF4F5; }

.casumo-bar.help-wrapper:hover, .casumo-bar.help-wrapper.visible {
  transform: translateX(-100%); }

.casumo-bar.help-wrapper:hover:not(.open), .casumo-bar.help-wrapper.visible:not(.open) {
  cursor: pointer; }

.casumo-bar.help-wrapper.open {
  transform: translateX(0); }

.casumo-bar.help-wrapper.open:after {
  display: none; }

.help-link {
  background: rgba(239, 246, 246, 0.9);
  color: #444f4f;
  bottom: 0;
  left: 0;
  height: 50px;
  z-index: 31; }

@media (max-height: 784px) {
  .help-link {
    height: 40px; } }

.help-link .help-toggler {
  padding: 0 25px;
  line-height: 50px; }

@media (max-height: 784px) {
  .help-link .help-toggler {
    line-height: 40px; } }

.help-link .help-toggler:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 2%;
  height: 100%;
  background: #aab8b8; }

#habla_window_div {
  margin: 0 !important; }

#habla_both_div {
  display: none !important; }

#habla_pre_chat_div {
  height: auto !important; }

#habla_wcsend_input {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

#hbl_body_message,
#habla_pre_chat_span,
#habla_offline_message_span {
  text-align: center;
  display: block;
  margin-bottom: 20px; }

#habla_pre_chat_span {
  margin: 20px 0 40px 0;
  color: #aab8b8;
  padding: 0 48px; }

#hbl_body_message a,
#habla_pre_chat_span a,
#habla_offline_message_span a {
  margin-bottom: 10px; }

#hbl_body_message {
  color: #6f7b7b; }

.habla_offline_submit_input {
  margin-top: 20px; }

#olark-messages {
  overflow: auto; }

.habla_conversation_person1,
.habla_conversation_person2 {
  display: block;
  padding: 0.85em 0.85em 0 0.85em;
  margin: 2px 30px 0 20px;
  border-radius: 5px 5px 0 0;
  font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #13813d;
  background: #19ac51; }

.olark-avatar {
  left: 24px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  z-index: 99; }

.habla_conversation_text_span {
  display: block;
  position: relative;
  padding: 0 0.85em 0.85em 0.85em;
  margin: -1px 30px 0 20px;
  border-radius: 0 0 5px 5px;
  color: #ffffff;
  background: #19ac51; }

.habla_conversation_text_span a {
  color: #303838;
  text-decoration: underline;
  word-break: break-all; }

.habla_conversation_text_span:before {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 0;
  height: 10px;
  width: 16px;
  background: url("/images/olark/speach-bubble-left.png") 0 0 no-repeat; }

.habla_conversation_person1 {
  height: 0;
  text-indent: -999px;
  background: #cad7d7;
  margin: 0 20px 0 30px; }
  .habla_conversation_person1 + .habla_conversation_text_span {
    background: #cad7d7;
    color: #444f4f;
    margin: -2px 20px 0 30px;
    word-wrap: break-word; }
    .habla_conversation_person1 + .habla_conversation_text_span a {
      color: #444f4f;
      text-decoration: underline; }
    .habla_conversation_person1 + .habla_conversation_text_span:before {
      left: auto;
      right: 0;
      background-image: url("/images/olark/speach-bubble-right.png"); }

.olark-composing-message {
  display: none; }

.habla_conversation_p_item + .habla_conversation_p_item {
  margin-top: -10px; }

.habla_conversation_p_item + .habla_conversation_p_item.olrk_new_sender {
  margin-top: 2px;
  margin-bottom: 2px; }

.olark-feedback-question {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 10px; }

.olark-feedback-low-answer {
  text-indent: -900em;
  background-image: url("/images/olark/sad.jpg");
  width: 20px;
  height: 20px;
  float: left; }

.olark-feedback-high-answer {
  text-indent: -900em;
  background-image: url("/images/olark/happy.jpg");
  width: 20px;
  height: 20px;
  float: right; }

.olark-feedback-question-number {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 10px;
  margin: 10px 0 0 0;
  color: #0d5629; }

.olark-feedback-submit {
  background-color: #ffc930;
  color: #96603c !important;
  border-color: #ffc930;
  width: 100% !important; }

.olark-feedback-choices-wrap {
  width: 100% !important;
  float: left;
  display: flex;
  justify-content: space-between; }

.olark-feedback-choices-wrap .olark-feedback-radio {
  width: 15px;
  height: 15px;
  border-radius: 200px;
  background-color: white;
  margin: 10px auto 0 auto;
  display: inline-block; }

.olark-feedback-text {
  border-radius: 6px;
  font-size: 12px;
  width: 176px;
  padding: 10px;
  border: 0;
  margin: 0; }

.habla_wcsend_field {
  color: #303838;
  overflow: hidden;
  outline: 0;
  background: none;
  resize: none;
  font-family: Circular-Pro-Book, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  border: 0;
  border-bottom: 1px solid #eff6f6;
  display: block;
  width: 100%;
  border-radius: 0;
  background-color: transparent;
  position: relative;
  height: 1.8rem !important; }
  .habla_wcsend_field:focus {
    border-color: #aab8b8; }
  .light .habla_wcsend_field {
    color: #ffffff;
    border-color: #aab8b8; }
    .light .habla_wcsend_field:focus {
      border-color: #ffffff; }

.is-good {
  color: #19ac51; }

#habla_chatform_form .habla_wcsend_field, #habla_offline_message_div .habla_wcsend_field {
  height: 52.8px !important;
  padding: 1.2em 34px 1.2em 24px;
  background: #eff6f6;
  color: #303838; }

#habla_pre_chat_name_input, #habla_pre_chat_email_input {
  margin-bottom: 30px; }

#habla_offline_body_input {
  height: 7em !important; }

.habla_button,
.habla_offline_submit_input,
#habla_pre_chat_span a,
#hbl_body_message a,
#habla_offline_message_span a,
.habla_conversation_p_item button {
  text-align: center;
  padding: 0;
  display: flex;
  margin: 48px auto; }

.habla_offline_submit_input {
  min-width: 16.2em;
  justify-content: center; }

.habla_offline_submit_input,
.habla_conversation_p_item button[data-action="decline"] {
  background-color: #19ac51;
  border-color: #19ac51; }
  .habla_offline_submit_input:active,
  .habla_conversation_p_item button[data-action="decline"]:active {
    background-color: #169647;
    border-color: #169647; }

#habla_pre_chat_span a,
#hbl_body_message a,
#habla_offline_message_span a {
  background-color: #cad7d7;
  border-color: #cad7d7;
  color: #6f7b7b; }
  #habla_pre_chat_span a:active,
  #hbl_body_message a:active,
  #habla_offline_message_span a:active {
    background-color: #bbcccc;
    border-color: #bbcccc; }

.habla_conversation_p_item button {
  color: #ffffff;
  margin: 1.14286em 5px 0.57143em;
  padding: 0.71429em;
  color: #0d5629;
  width: 70%; }
  .habla_conversation_p_item button[data-action="start"], .habla_conversation_p_item button[data-action="decline"] {
    width: 45%;
    display: block;
    display: inline-block; }
  .habla_conversation_p_item button[data-action="decline"] {
    border-color: #ffffff;
    color: #ffffff; }

span.habla_conversation_text_span.hbl_pal_main_fg.habla_conversation_notification {
  padding-left: 14px; }

span.habla_conversation_text_span.hbl_pal_main_fg.habla_conversation_notification span {
  text-align: left !important; }

.help-wrapper {
  z-index: 31;
  bottom: 0;
  transform: translateX(-100%); }
  .help-wrapper:hover, .help-wrapper.visible {
    transform: translateX(-96%); }
    .help-wrapper:hover:not(.open), .help-wrapper.visible:not(.open) {
      cursor: pointer; }
  .help-wrapper.open {
    transform: translateX(0); }
    .help-wrapper.open:after {
      display: none; }
  .help-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 4%;
    background: #eff6f6; }

.modal-help .help {
  padding: 0; }

.modal-help .intro {
  color: #aab8b8;
  padding: 0 48px; }

.modal-help .habla_offline_submit_input {
  padding: 0 24px; }

.operator {
  margin: 0 0 10px 10px; }

.margin-text {
  margin-left: 64px; }

.operator-name {
  margin-top: 5px; }

.operator-message {
  margin-top: 10px;
  color: #eff6f6;
  word-break: break-all; }

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-3px, 0, 0); }
  40%, 60% {
    transform: translate3d(3px, 0, 0); } }

.olark-chat {
  color: #6f7b7b;
  font-size: 2.2em !important; }

.startChatBtn {
  margin: 40px auto;
  text-align: center;
  display: block; }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .habla_conversation_p_item .habla_conversation_person2 {
    display: none; }
  .habla_conversation_p_item .habla_conversation_text_span {
    padding-left: 72px; } }

@media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
  .habla_conversation_p_item .habla_conversation_person2 {
    display: none; } }

@media (min-width: 1024px) and (orientation: landscape) {
  .habla_conversation_p_item .habla_conversation_person2 {
    display: none; }
  .habla_conversation_p_item .habla_conversation_text_span {
    padding-left: 72px; } }

.habla_conversation_person2 + .habla_conversation_text_span {
  padding-top: 12px; }

.habla_conversation_p_item:first-child .habla_conversation_person1 ~ .habla_conversation_text_span {
  padding-left: 14px; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  animation-name: bounceIn; }

.habla_conversation_p_item {
  animation: bounceIn 0.82s;
  position: relative; }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .habla_conversation_p_item.olrk_new_sender .habla_conversation_person2, .habla_conversation_p_item:first-child .habla_conversation_person2 {
    display: block;
    padding-top: 1em;
    padding-left: 72px; }
  .habla_conversation_p_item.olrk_new_sender .olark-person2:before, .habla_conversation_p_item:first-child .olark-person2:before {
    border: 2px solid #09903D;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    content: '';
    float: left;
    margin-left: -62px;
    margin-top: -2px;
    position: absolute;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: center;
    will-change: transform; } }

@media (min-width: 1024px) and (orientation: landscape) {
  .habla_conversation_p_item.olrk_new_sender .habla_conversation_person2, .habla_conversation_p_item:first-child .habla_conversation_person2 {
    display: block;
    padding-top: 1em;
    padding-left: 72px; }
  .habla_conversation_p_item.olrk_new_sender .olark-person2:before, .habla_conversation_p_item:first-child .olark-person2:before {
    border: 2px solid #09903D;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    content: '';
    float: left;
    margin-left: -62px;
    margin-top: -2px;
    position: absolute;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: center;
    will-change: transform; } }

@media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
  .habla_conversation_p_item.olrk_new_sender .habla_conversation_person2, .habla_conversation_p_item:first-child .habla_conversation_person2 {
    display: block; } }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .habla_conversation_p_item:last-child .habla_conversation_person2:before {
    border: 2px solid #09903D;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    content: '';
    float: left;
    margin-left: -62px;
    margin-top: -2px;
    position: absolute;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: center; } }

@media (min-width: 1024px) and (orientation: landscape) {
  .habla_conversation_p_item:last-child .habla_conversation_person2:before {
    border: 2px solid #09903D;
    border-radius: 50px;
    height: 50px;
    width: 50px;
    content: '';
    float: left;
    margin-left: -62px;
    margin-top: -2px;
    position: absolute;
    z-index: 9999999;
    background-repeat: no-repeat;
    background-position: center; } }

.olrk_avatar {
  display: none; }

.habla_conversation_p_item + .habla_conversation_p_item .habla_conversation_person1 ~ .habla_conversation_text_span {
  padding-left: 14px; }

#habla_middle_div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column; }

#habla_middle_wrapper_div {
  flex: 1;
  position: relative;
  min-height: calc(100% - 52.8px);
  max-height: calc(100% - 52.8px);
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem; }

#habla_chatform_form .hbl_txt_wrapper {
  margin: 0; }

#habla_chatform_form #habla_wcsend_input:placeholder {
  border: 5px solid red; }

#habla_conversation_div {
  box-sizing: border-box;
  transition: all 0.4s ease;
  transform: translate3d(0, 0, 1px);
  overflow: visible !important;
  height: auto !important; }

#habla_input_div {
  position: relative; }

.olark-send {
  color: #6f7b7b;
  color: #6f7b7b;
  padding: 0 10px;
  box-sizing: content-box;
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  line-height: 100%;
  width: 1em; }
  .olark-send::before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -0.5em auto auto -0.5em; }

.olark-send-active {
  color: #303838;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0); }

.olark-login {
  position: absolute;
  width: 100%;
  left: 0;
  box-content: border-box;
  z-index: 1; }
  .olark-login + .olrk-noquirks {
    position: absolute;
    width: 100%;
    height: calc(100% - 94px);
    top: 94px;
    left: 0; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .olark-login + .olrk-noquirks {
      top: 44px;
      height: calc(100% - 44px); } }

/*# sourceMappingURL=help.css.map */
.phone-number-keypad {
  border-bottom: solid 2px #eff6f6;
  padding-bottom: 5px; }
  .light .phone-number-keypad {
    color: #ffffff;
    border-color: #aab8b8; }
    .light .phone-number-keypad:focus {
      border-color: #ffffff; }
  .phone-number-keypad .is-good {
    color: #19ac51; }
  .phone-number-keypad .is-bad {
    color: #f05e5e; }

.offline-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  line-height: 20px;
  transform: translate3d(0, -100%, 0);
  background: #f05e5e;
  padding: 0 12px;
  color: #ffffff;
  font-size: 0.85714em;
  transition: transform 300ms ease; }

.offline .modal-context, .offline .fade, .offline .pages-container, .offline .main-menu {
  top: 20px; }

.offline .offline-message {
  transform: translate3d(0, 0, 0); }

#shop-overlay, #progression, #overlay, div.shop, .overlay-game-launcher, .page-layer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; }

.flex-horizontal .flex-item,
.flex-vertical .flex-item, .page-playokay .link-item .flex-item, .modal-playokay .link-item .flex-item {
  flex: 1;
  display: flex; }

#hud {
  height: 50px;
  background-color: black;
  z-index: 1;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 3; }
  #hud button {
    background: none;
    border: none;
    float: left;
    border-radius: 0; }
    #hud button.active {
      border-bottom: 2px solid #ffffff; }
    #hud button.shop {
      float: right; }
    #hud button.xpRandomMilestones .container-casumo, #hud button.xpMilestones .container-casumo {
      height: 50px;
      width: 45px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
    #hud button.xpRandomMilestones .random .status, #hud button.xpMilestones .random .status {
      align-self: flex-end; }
    #hud button.xpRandomMilestones .milestone .status, #hud button.xpMilestones .milestone .status {
      align-self: center; }

#game {
  z-index: 0;
  height: calc(100% - 50px);
  margin-bottom: 50px; }

#shop-overlay {
  text-align: center;
  z-index: 3;
  margin: 0 auto;
  height: auto;
  width: 500px;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  padding: 5px; }
  @media (max-height: 375px) and (orientation: landscape) {
    #shop-overlay {
      width: 100%; } }
  @media (max-height: 900px) and (orientation: portrait) {
    #shop-overlay {
      width: 100%; } }
  #shop-overlay .wrapper {
    background-color: #ffffff;
    border-radius: 5px; }

#progression {
  z-index: 2; }
  #progression .close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5; }
  @media (max-height: 375px) and (orientation: landscape) {
    #progression .progress-donut {
      width: 100px; } }
  #progression .progress-donut .level {
    top: 50%;
    transform: translateY(-50%); }
    @media (min-width: 1024px) and (orientation: landscape) {
      #progression .progress-donut .level {
        width: calc(32% + 15px);
        left: -16%; } }
  #progression .overview {
    height: 100%; }
    #progression .overview > div {
      align-items: center;
      justify-content: center; }
    #progression .overview .belt-info {
      text-align: center; }
    #progression .overview .legend {
      font-size: 0.8em; }
      #progression .overview .legend > .flex-item {
        min-width: 100px; }
    #progression .overview .progression {
      position: relative;
      display: inline-block; }
      #progression .overview .progression ul {
        color: #ffffff;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        border: 1px solid #ffffff;
        padding: 6px 35px;
        border-radius: 30px;
        position: relative; }
        #progression .overview .progression ul li {
          float: left;
          margin-left: 35px; }
          #progression .overview .progression ul li:first-child {
            margin: 0; }
          #progression .overview .progression ul li:last-child {
            margin-right: 60px; }
        #progression .overview .progression ul.random li .icon {
          visibility: hidden; }
      #progression .overview .progression .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 93%;
        width: 100%;
        border-radius: 30px; }
      #progression .overview .progression .avatar {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(5%, -50%); }
        #progression .overview .progression .avatar .container-casumo {
          height: 80px;
          width: 70px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain; }
  #progression .details {
    height: 100%; }
    #progression .details > div {
      align-items: center;
      justify-content: center; }
    #progression .details ul {
      align-items: center;
      justify-content: center;
      flex: 1;
      flex-wrap: wrap;
      align-content: center; }
      #progression .details ul li {
        align-items: center;
        position: relative; }
        #progression .details ul li.avatar {
          min-width: 100%; }
          @media (max-height: 375px) and (orientation: landscape) {
            #progression .details ul li.avatar {
              min-width: auto; } }
        #progression .details ul li .avatar {
          width: 100%;
          min-height: 100px; }
        #progression .details ul li.description {
          align-items: flex-start; }

#overlay {
  background-color: rgba(0, 0, 0, 0.9);
  height: calc(100% - 50px);
  margin-bottom: 50px;
  z-index: 2; }
  #overlay .close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5; }

div.shop .header .wrapper {
  width: 100%;
  margin: 3em auto 0; }

div.shop .header ul {
  width: 50%;
  margin: 0 auto; }
  div.shop .header ul li {
    border-radius: 1.5em;
    align-items: center;
    justify-content: center;
    line-height: 3em; }
    div.shop .header ul li.selected {
      background-color: rgba(255, 255, 255, 0.3); }

div.shop .content ul {
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-wrap: wrap;
  align-content: center; }
  div.shop .content ul .wrapper {
    width: 100%; }
  @media (max-height: 900px) and (orientation: portrait) {
    div.shop .content ul {
      margin: 0 10%; } }
  div.shop .content ul li {
    align-items: center;
    position: relative; }
    div.shop .content ul li .title {
      position: absolute;
      bottom: -2em;
      text-align: center;
      font-size: 0.7em;
      width: 100%; }
    @media (max-height: 375px) and (orientation: landscape) {
      div.shop .content ul li.description {
        max-width: 50%; } }
    div.shop .content ul li .cost {
      position: absolute;
      bottom: 10px;
      font-size: 0.8em;
      text-align: center;
      width: 100%; }
    div.shop .content ul li.item {
      border: 3px solid #ffffff;
      margin: 10px 10px 3em 10px;
      border-radius: 10px;
      min-width: 100px;
      max-width: 100px;
      min-height: 100px;
      background-color: rgba(255, 255, 255, 0.3);
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center 30%;
      flex: 1 0 auto; }
      div.shop .content ul li.item.empty {
        border-style: dotted; }

.game-view {
  width: 100%;
  height: 100%;
  transition: width 0.2s ease; }
  .transition-open .game-view {
    width: 85%; }

.overlay-game-launcher {
  z-index: 20;
  background: #303838; }

.overlay-game-launcher-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 22; }

.center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

/* Fonts */
/* Layout */
/* Fonts */
/* Touch */
/* Animations */
.page-404 {
  background-color: #eff6f6;
  min-height: 400px;
  padding: 100px 0; }
  .page-404 img {
    width: 80%; }

.top-banner {
  padding-bottom: 60%;
  background-size: cover;
  position: relative; }

.selling-points {
  background: #004854; }
  .selling-points ul {
    text-align: left;
    display: inline-block;
    list-style-type: disc; }
  .selling-points img {
    width: 25%;
    height: auto;
    margin-bottom: 5px; }

.selling-point-row {
  padding: 20px 0;
  margin: 0 2.5em;
  border-top: 2px solid #3ebeba; }
  .selling-point-row:first-child {
    border: 0; }

.start-page .logos {
  display: flex;
  flex-direction: column;
  align-items: center; }

.start-page .logo-group {
  text-align: center;
  width: 100%; }

.start-page .award-logos img {
  width: 25%;
  margin-right: 5%;
  max-width: 70px; }
  .start-page .award-logos img:last-child {
    margin-right: 0; }

.start-page .payment-logos {
  max-width: 420px;
  margin-top: 10px; }
  .start-page .payment-logos img {
    margin: 2%; }

@media (min-width: 1024px) and (orientation: landscape) {
  .start-page .logos {
    flex-direction: row; }
  .start-page .logo-group {
    text-align: left;
    width: 50%; }
  .start-page .payment-logos {
    margin-top: 0;
    text-align: right; }
    .start-page .payment-logos img {
      text-align: right; } }

#splash {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 29;
  background: no-repeat #d36442;
  background-size: cover;
  background-position-y: 75%;
  text-align: center; }
  #splash .logo {
    max-width: 32%;
    max-height: 6em;
    vertical-align: top; }
  #splash .close-button {
    position: absolute;
    top: 1em;
    right: 1em; }
  #splash .tagline, #splash .welcome-bonus-text {
    margin: 0 auto;
    width: 70%;
    font-family: Circular-Pro-Black, Helvetica, Arial, sans-serif; }
  #splash .tagline {
    font-size: 18px; }
  #splash .welcome-bonus-text {
    margin-bottom: 20px;
    color: #ffffff; }
    #splash .welcome-bonus-text * {
      font-family: Circular-Pro-Book, Helvetica, Arial, sans-serif;
      font-size: 14px; }
  #splash .learn-more-link {
    color: #222;
    text-decoration: underline;
    margin-top: 0.2em;
    display: block; }
  #splash .button {
    width: 200px; }
  #splash .foreground-image {
    max-width: 69%;
    max-height: 45%; }
  #splash .bottom-section {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0; }
  #splash .payment-providers {
    width: 80%; }

@media (min-width: 768px) and (orientation: portrait) {
  #splash .tagline {
    font-size: 2em; }
  #splash .foreground-image {
    height: 33em; }
  #splash .button {
    width: 350px;
    height: 4em; } }

@media (min-width: 1024px) and (orientation: landscape) {
  #splash {
    background-position-y: 77%; }
    #splash .tagline {
      width: 45%;
      font-size: 2em; }
    #splash .foreground-image {
      height: 20em; }
    #splash .button {
      width: 390px;
      height: 3.5em;
      border-radius: 3em;
      font-size: 21px; }
    #splash .payment-providers {
      width: 50%;
      margin: 0 auto; } }

@media (max-height: 667px) and (orientation: portrait) {
  #splash .foreground-image {
    margin-top: 10px; }
  #splash .welcome-bonus-text {
    margin-bottom: 10px; }
  #splash .tagline {
    font-size: 20px; } }

@media (max-height: 480px) and (orientation: portrait) {
  #splash .foreground-image {
    max-height: 25%;
    margin-top: 10px; }
  #splash .tagline {
    font-size: 16px; }
  #splash .welcome-bonus-text {
    margin-bottom: 10px; }
  #splash .button {
    width: 140px; }
  #splash .buttons div {
    display: inline-block; }
  #splash .buttons div:first-child {
    margin-right: 1em; } }

@media (max-height: 375px) and (orientation: landscape) {
  #splash {
    background-size: 100% 100%; }
    #splash .logo {
      float: left;
      margin-left: 1em; }
    #splash .foreground-image {
      max-height: 35%;
      margin-top: 10px; }
    #splash .tagline-wrapper {
      width: 67%;
      float: left; }
    #splash .tagline {
      display: inline-block;
      text-align: center;
      margin-top: 1em;
      width: 90%; }
    #splash .payment-providers {
      width: 50%; }
    #splash .button {
      min-height: 40px;
      width: 160px; }
    #splash .learn-more-link {
      text-align: center; }
    #splash .buttons div {
      display: inline-block; }
    #splash .buttons div:first-child {
      margin-right: 1em; }
    #splash .welcome-bonus-text {
      margin-bottom: 0; } }

@media (min-height: 569px) and (orientation: portrait) {
  #splash .welcome-bonus-text {
    margin-bottom: 40px; }
  #splash .foreground-image {
    margin-top: 20px; } }

.page-dashboard {
  padding-bottom: 50px; }

.login-form {
  background: url("/images/backgrounds/space.png") 50% 0;
  background-size: 100% auto; }
  .login-form input {
    color: #ffffff !important; }
  .login-form .password-visibility-toggle {
    font-size: 1.28571em; }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .login-form .welcome-text {
      margin-top: 20px; } }
  @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
    .login-form .login-fields {
      padding: 78px 0; } }

.login-page .list-item {
  height: 4.42857em; }

.adventure-page {
  position: relative;
  padding-bottom: 50px; }

.player-top-header-container {
  height: 200px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .player-top-header-container {
      overflow: hidden;
      width: 100%;
      height: 324px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .player-top-header-container {
      width: 100%;
      height: 240px; } }
  .player-top-header-container .progress-donut {
    margin-top: 4%; }
  .player-top-header-container .player-top-image-space {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute; }
    @media (min-width: 1024px) and (orientation: landscape) {
      .player-top-header-container .player-top-image-space {
        height: 578px;
        width: 100%; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .player-top-header-container .player-top-image-space {
        height: 430px;
        width: 100%; } }
  .player-top-header-container .player-top-image-planet {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 100%; }
  .player-top-header-container .header {
    color: #ffffff;
    z-index: 1;
    position: absolute;
    width: 100%;
    padding-top: 10px; }
    @media (min-width: 1024px) and (orientation: landscape) {
      .player-top-header-container .header {
        position: relative;
        top: 25px; } }
    @media (min-width: 768px) and (orientation: portrait) {
      .player-top-header-container .header {
        position: relative;
        top: 25px; } }

@media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
  .page-payments {
    padding-top: 20px; } }

.page-payments .open-external-account {
  margin-top: 20px;
  color: #aab8b8;
  text-decoration: underline; }

.payments-toolbar {
  height: 49px;
  display: flex;
  font-size: 0.85714em;
  position: relative;
  z-index: 1; }
  .payments-toolbar > * {
    background-color: #eff6f6; }
  .payments-toolbar .payment-method-selector-container {
    flex: 1; }
  .payments-toolbar .payment-method-selector {
    height: 49px;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .payments-toolbar .payment-method-selector .icon {
        color: #aab8b8; } }
  .payments-toolbar .selected-deposit-bonus {
    border-left: 0;
    width: 16%;
    height: 49px;
    margin-left: 2px; }
    @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
      .payments-toolbar .selected-deposit-bonus {
        margin-left: 12px; } }
    .payments-toolbar .selected-deposit-bonus img {
      height: 62%; }

.receipt {
  overflow-y: auto; }
  .receipt dl {
    overflow: hidden; }
  .receipt dt,
  .receipt dd {
    padding: 5px 0; }
  .receipt dt {
    float: left;
    clear: left; }
  .receipt dd {
    float: right; }

.actions,
.receipt-statement {
  padding: 20px 48px; }

.receipt-statement {
  font-size: 0.85714em;
  background-color: #aab8b8;
  text-align: center;
  color: #ffffff; }
  .receipt-statement--alert {
    background-color: #ff8a50; }
  .receipt-statement--success {
    background-color: #19ac51; }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .cash-wizard {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    top: 75px;
    margin-top: 49px; } }
  @media (max-width: 767px) and (orientation: portrait) and (max-height: 480px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) and (max-height: 480px) and (orientation: portrait) {
    .cash-wizard {
      top: 64px; } }
  @media (max-width: 767px) and (orientation: portrait) and (max-height: 375px) and (orientation: landscape), (max-width: 1023px) and (orientation: landscape) and (max-height: 375px) and (orientation: landscape) {
    .cash-wizard {
      top: 64px; } }
  @media (max-width: 767px) and (orientation: portrait) and (min-width: 768px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) and (min-width: 768px) and (orientation: portrait), (max-width: 767px) and (orientation: portrait) and (min-width: 1024px) and (orientation: landscape), (max-width: 1023px) and (orientation: landscape) and (min-width: 1024px) and (orientation: landscape) {
    .cash-wizard {
      top: 100px; } }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .cash-wizard .step {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; } }

@media (max-height: 900px) and (orientation: portrait) {
  .cash-wizard .step {
    display: flex;
    flex-direction: column;
    justify-content: space-between; } }

.cash-wizard .name-step .suggested-name {
  display: inline-block;
  font-size: 1.28571em;
  color: #aab8b8;
  border-bottom: 2px dotted #aab8b8;
  padding: 0 6px; }

.cash-wizard .amount-step .display-value {
  font-size: 1.5em;
  color: #47bd74;
  border-bottom: 1px solid #47bd74; }
  .cash-wizard .amount-step .display-value.is-bad {
    color: #f05e5e;
    border-color: #f05e5e; }
  .cash-wizard .amount-step .display-value:empty::after {
    color: #47bd74;
    content: attr(data-currency-symbol); }

.cash-wizard .amount-step .amount-message-box {
  height: 2em; }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .cash-wizard .amount-step .fixed-bottom-mobile {
    position: fixed;
    bottom: 0; } }

.cash-wizard .basic-fields-step {
  min-height: 100%;
  position: relative;
  padding-bottom: 70px; }
  .cash-wizard .basic-fields-step button {
    position: absolute;
    bottom: 0; }

.valuables .valuables-header,
.valuables .no-valuables-message {
  height: 84px;
  background-color: #eff6f6;
  border-bottom: 1px solid #cad7d7; }

@media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
  .valuables .valuables-header {
    display: none; } }

.page-play {
  position: relative; }
  .page-play .balance-container {
    border-bottom: 1px solid #aab8b8; }
  .page-play .play-options {
    position: relative;
    min-height: 150px; }
  .page-play .game-thumb {
    width: 110px;
    height: 110px;
    background-size: cover;
    background-position: 50%;
    border-radius: 50%;
    overflow: hidden; }
    .page-play .game-thumb .game-logo {
      width: 100%; }
  .page-play .game-button-text-spacer {
    width: 100%; }
  .page-play .zero-width {
    width: 0; }

.wizard .step {
  min-height: 10em; }

.wizard .summary .is-good .value {
  color: #19ac51; }

.wizard .summary .is-bad .value,
.wizard .summary .is-bad .edit {
  color: #f05e5e; }

.wizard .summary .errors {
  padding: 10px 12px;
  border-radius: 2em;
  background-color: #f05e5e;
  color: #ffffff;
  text-align: center; }

.welcome-offer {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
    .welcome-offer .casumo-holding-valuables {
      transform: scale(0.7); } }
  .welcome-offer .text {
    flex: 1; }

.terms-dialog h4 {
  font-size: 1.28571em; }

.terms-dialog p {
  margin: 1em 0; }

.games-search .input {
  background-color: #eff6f6;
  padding: 20px 24px; }
  .games-search .input input {
    width: 80%;
    border: 0;
    background-color: #eff6f6;
    height: 24px; }

.games-search .search-results li {
  border-bottom: 2px solid #eff6f6; }

.games-search .search-results .search-list-item {
  height: 60px; }
  .games-search .search-results .search-list-item span.icon {
    padding: 20px; }
  .games-search .search-results .search-list-item .game-name {
    width: 80%; }
    .games-search .search-results .search-list-item .game-name:active {
      background-color: #303838;
      color: #eff6f6 !important; }
      .games-search .search-results .search-list-item .game-name:active + .game-link {
        color: #eff6f6 !important; }
  .games-search .search-results .search-list-item .game-link {
    width: 20%; }
    .games-search .search-results .search-list-item .game-link:active {
      background-color: #303838;
      color: #eff6f6 !important; }
    .games-search .search-results .search-list-item .game-link span.icons-more {
      text-align: center; }

@media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
  .top-bar.faq {
    background-color: #f05e5e;
    width: 100%;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==") !important;
    border: none !important;
    background-size: 40px;
    background-repeat: repeat-x;
    background-position: bottom; }
    .top-bar.faq .top-bar-text {
      width: 100%;
      color: #503254; } }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .top-bar.faq .top-bar-text {
    display: none; } }

.page-faq .headline-bar {
  height: 100px; }

.page-faq .header-wave {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==");
  background-size: 40px;
  background-repeat: repeat-x;
  background-position: bottom;
  height: 100%;
  display: flex; }

.page-faq .page-heading {
  align-self: center; }

.page-faq .item-block {
  height: 75px;
  border-bottom: 1px solid #cad7d7;
  padding-left: 36px;
  padding-right: 20px; }

.page-faq .question-group-container {
  margin-top: 40px; }
  .page-faq .question-group-container .question-group {
    counter-reset: my-counter; }
    .page-faq .question-group-container .question-group .questions-and-answers {
      min-height: 30px;
      border-bottom: 1px solid #cad7d7;
      margin: 10px 0;
      padding-bottom: 10px; }
      .page-faq .question-group-container .question-group .questions-and-answers .question-title:before {
        content: counter(my-counter) ".";
        counter-increment: my-counter;
        position: relative;
        left: 0;
        top: 0;
        width: 25px; }

.page-faq .question-title, .modal-faq .question-title {
  align-items: stretch; }
  .page-faq .question-title .question-text, .modal-faq .question-title .question-text {
    flex: 1 100%;
    padding-right: 5px; }

@media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
  .top-bar.playokay {
    background-color: #19ac51;
    width: 100%;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==") !important;
    border: none !important;
    background-size: 40px;
    background-repeat: repeat-x;
    background-position: bottom; }
    .top-bar.playokay .top-bar-text {
      width: 100%;
      color: #503254; } }

@media (max-width: 767px) and (orientation: portrait), (max-width: 1023px) and (orientation: landscape) {
  .top-bar.playokay .top-bar-text {
    display: none; } }

.page-playokay .headline-bar {
  background-color: #19ac51;
  height: 100px; }

.page-playokay .header-wave {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1heFlNaWQgc2xpY2UiPgogIDxwYXRoIGNsYXNzPSJmcm9udCIgZD0iTTAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBDMTAwLDEwMCAxMDAsMCAyNTAsMAogICAgICAgICAgICAgICAgICAgICAgICAgTTI1MCwwCiAgICAgICAgICAgICAgICAgICAgICAgICBDNDAwLDAgNDAwLDEwMCA1MDAsMTAwCiAgICAgICAgICAgICAgICAgICAgICAgICBMMCwxMDAiIGZpbGw9IndoaXRlIj48L3BhdGg+Cjwvc3ZnPg==");
  background-size: 40px;
  background-repeat: repeat-x;
  background-position: bottom;
  height: 100%;
  display: flex; }

.page-playokay .page-heading {
  align-self: center; }

.page-playokay .item-block {
  height: 75px;
  border-bottom: 1px solid #cad7d7; }

.page-playokay .items .item:last-child .link, .modal-playokay .items .item:last-child .link {
  margin-bottom: 0; }

.page-playokay .item, .modal-playokay .item {
  display: flex; }
  .page-playokay .item .title, .modal-playokay .item .title {
    margin: 5px 0; }

.page-playokay .list-item, .modal-playokay .list-item {
  color: #0085c1;
  border-left: solid 4px #0085c1;
  margin: 4px -24px;
  padding: 12px 14px;
  flex-direction: column; }

.page-playokay .link-item, .modal-playokay .link-item {
  border: none; }
  .page-playokay .link-item .text, .modal-playokay .link-item .text {
    color: black; }
  .page-playokay .link-item .link, .modal-playokay .link-item .link {
    margin: 20px -24px; }

.page.settings .right-list-item {
  width: 70px; }

.page-history a {
  color: #303838; }

.page-history .date-picker-container {
  height: 50px;
  border-bottom: 1px solid #eff6f6;
  margin: 2px 0 0 15px; }
  .page-history .date-picker-container input {
    font-size: 0.85714em; }

.page-history table th, .page-history table td {
  width: 20%; }
  .page-history table th:first-child, .page-history table td:first-child {
    width: 40%; }

.page-history .game-name {
  border-top: 1px solid #eff6f6;
  height: 40px;
  padding: 0 7px;
  margin: 5px 0;
  background-color: #eff6f6; }

.modal-coupon .redeem-options {
  overflow: auto;
  width: 100%; }
  .modal-coupon .redeem-options li {
    line-height: 1.6em;
    text-align: center; }
  .modal-coupon .redeem-options.longer-list {
    display: inline-block;
    column-count: 2;
    column-rule: 1px solid #eff6f6; }
    .modal-coupon .redeem-options.longer-list li {
      text-align: left;
      padding-left: 10px; }

.modal-ticket {
  min-height: 100%;
  height: auto; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .modal-ticket {
      min-height: 480px; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .modal-ticket {
      min-height: 500px; } }

.modal-faq {
  counter-reset: my-counter; }
  .modal-faq .v-pad:first-child {
    padding-top: 0; }
  .modal-faq .v-pad .question-title:before {
    content: counter(my-counter) ".";
    counter-increment: my-counter;
    position: relative;
    left: 0;
    top: 0;
    width: 25px; }
  .modal-faq .v-pad .answer {
    padding: 20px 0 5px 21px; }
  .modal-faq .grey-line {
    bottom: 0;
    left: -24px;
    position: relative;
    width: calc(100% + 48px);
    border-bottom: 1px solid #cad7d7; }

.mobile-number .title {
  flex-grow: 2;
  margin-left: 20px; }

.modal-setup-touchid .touchid-image {
  position: relative;
  height: 80px; }
  .modal-setup-touchid .touchid-image img {
    height: 80px;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 500ms ease; }
    .modal-setup-touchid .touchid-image img.touchid-image-base {
      opacity: 1; }

.modal-setup-touchid .success .touchid-image img.touchid-image-base {
  opacity: 0; }

.modal-setup-touchid .success .touchid-image img.touchid-image-success {
  opacity: 1; }

.modal-setup-touchid .failure .touchid-image img.touchid-image-base {
  opacity: 0; }

.modal-setup-touchid .failure .touchid-image img.touchid-image-failed {
  opacity: 1; }
