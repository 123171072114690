.language-selector-item {
    padding: 4px 0 2px;
    border-bottom: 2px solid transparent;

    &.selected {
        border-bottom-color: palette($grey, light);
    }
}

.main-menu {
    .language-selector-items {
        padding: 0 em(10);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .language-selector-item.selected {
        border-bottom-color: palette($grey, light);
    }
}

.page-footer {
    .language-selector-item {
        display: inline-block;
        margin: 0 em(6);

        &.selected {
            border-bottom-color: palette($grey, darkest);
        }
    }
}
