.form-field {

    padding-top: 15px;
    position: relative;

    > .password-visibility-toggle {
        position: absolute;
        right: 0;
        bottom: 50%;
        transform: translateY(50%);
        padding-top: 23px;
    }

    &.multi-part {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > * {
            flex: 1;
            position: relative;
            margin-right: $gutter-horizontal / 4;
        }

        > .prefix-part{
            flex: none;
            margin-right: -3px;
        }

        > .short-part {
            flex: none;
            width: 45px;
        }

        > :last-child {
            margin-right: 0;
        }

        label {
            top: -4px;
        }
    }

    .phone-prefix-sign{
        text-align: right;
        height: 2.2rem;
        margin-top: 0.8em;
        color: lighten($default-text-color, 50%);
        padding: 7px 0 0;
    }

    input{
        border: 0;
        border-bottom: 1px solid palette($grey, lighter);
        display: block;
        width: 100%;
        padding: 5px ($gutter-horizontal / 4) ($gutter-horizontal / 4);
        border-radius: 0;
        background-color: transparent;
        position: relative;
        height: 2.2rem;
        margin-top: 0.8rem;

        &:focus {
            border-color: palette($grey);
        }

        .light & {
            color: $white;
            border-color: palette($grey);

            &:focus {
                border-color: $white;
            }
        }

        &.is-good {
            color: palette($green);
        }

        &.is-bad {
            color: palette($red);
        }
    }

    label {
        top: 11px;
        color: palette($grey);
        position: absolute;
        left: 5px;
        opacity: 0;
        transition: opacity 300ms;
        font-size: $font-size-smaller;

        &.active {
            opacity: 1;
        }

        &.lower {
            position: relative;
            margin: 0;
        }

        .light & {
            color: palette($grey, lighter);
        }
    }

}

.password-visibility-toggle {
    min-width: 30px;
    color: palette($grey, darker);
    text-align: center;

    .light & {
        color: palette($yellow);
    }
}

.form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > * {
        flex: 1;
        position: relative;
        margin-right: $gutter-horizontal / 2;
    }

    > button,
    .button {
        flex: none;
    }

    > :last-child {
        margin-right: 0;
    }
}

.progress-bar {
    left: 0;
    height: 2px;
    width: 100%;
    background-color: palette($grey, lighter);

    &.baseline {
        position: absolute;
        bottom: 0;
    }

    > div {
        height: 2px;
        width: 0;
        background-color: palette($green);
    }
}

//On/off toggler
.flip-switch {
    display: inline-block;
    border: 2px solid palette($grey, light);
    border-radius: 1.2em;
    color: palette($grey, dark);
    cursor: pointer;
    @include no-user-select;
    @include transition(background 0.3s ease, border-color 0.3s ease);

    input {
        opacity: 0;
    }

    .flip-switch-inner {
        position: relative;
        overflow: hidden;
        margin: 2px;
        width: em(62);
        height: em(24);
    }

    .flip-switch-slider {
        position: absolute;
        top: 0;
        left: -2.7em;
        overflow: hidden;
        width: 8em;
        @include transition(left 0.1s ease-out);
    }

    &.on-state {
        border-color: palette($green);
        background: palette($green);
        color: $white;

        .switch {
            background: $white;
        }

        .flip-switch-slider {
            left: 0;
        }
    }

    .on, .off {
        float: left;
        width: 2.7em;
        height: em(24);
        text-align: center;
        text-transform: uppercase;
    }

    .text {
        padding: 0 5px;
        line-height: em(24);
    }

    .switch {
        float: left;
        width: em(24);
        height: em(24);
        border-radius: 1em;
        background: palette($grey, light);
        @include css-accelerate;
    }
}

//FlipSwitch is a widget. I want to keep the styling outside of it.
//So the `form--inverted` class is applied to a parent, not the element.
.form--inverted .flip-switch {
    color: $white;

    .switch {
        background: $white;
    }

    &.on-state {
        border-color: $white;
        background: $white;
        color: palette($green);

        .switch {
            background: palette($green);
        }

        .flip-switch-slider {
            left: 0;
        }
    }
}
