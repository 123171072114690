@import 'toolkit';

@font-face {
    font-family: 'Circular-Pro-Book';
    src: url('../../fonts/lineto-circular-pro-book.eot');
    src: url('../../fonts/lineto-circular-pro-book.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/lineto-circular-pro-book.woff') format('woff'),
    url('../../fonts/lineto-circular-pro-book.ttf') format('truetype'),
    url('../../fonts/lineto-circular-pro-book.svg#lineto-circular-pro-book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Circular-Pro-Black';
    src: url('../../fonts/lineto-circular-pro-black.eot');
    src: url('../../fonts/lineto-circular-pro-black.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/lineto-circular-pro-black.woff') format('woff'),
    url('../../fonts/lineto-circular-pro-black.ttf') format('truetype'),
    url('../../fonts/lineto-circular-pro-black.svg#lineto-circular-pro-black') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Circular-Pro-BlackItalic";
    src: url("../../fonts/lineto-circular-pro-boldItalic.eot");
    src: url("../../fonts/lineto-circular-pro-boldItalic.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/lineto-circular-pro-boldItalic.woff") format("woff"),
    url("../../fonts/lineto-circular-pro-boldItalic.ttf") format("truetype"),
    url("../../fonts/lineto-circular-pro-boldItalic.svg#lineto-circular-pro-blackItalic") format("svg");
    font-weight: normal;
    font-style: normal;
}
