//This file is auto generated by the `icon-fonts` gulp task.

@font-face {
    font-family: 'casumo-icons';
    src:    url('/fonts/casumo-icons.eot?147435353700014743535370005ugh0u');
    src:    url('/fonts/casumo-icons.eot?5ugh0u#iefix') format('embedded-opentype'),
        url('/fonts/casumo-icons.woff2?14743535370005ugh0u') format('woff2'),
        url('/fonts/casumo-icons.ttf?14743535370005ugh0u') format('truetype'),
        url('/fonts/casumo-icons.woff?14743535370005ugh0u') format('woff'),
        url('/fonts/casumo-icons.svg?14743535370005ugh0u#casumo-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

$icons-chat: "\e902";
$icons-send: "\e906";
$icons-question-mark-white: "\e901";
$icons-question-mark-filled: "\e903";
$icons-triple-big-win: "\e900";
$icons-triple-win: "\e907";
$icons-prize: "\e905";
$icons-playforfun: "\e611";
$icons-back-button: "\e62d";
$icons-keypad: "\e629";
$icons-bells: "\e625";
$icons-bell: "\e626";
$icons-arrows-spin: "\e61e";
$icons-avatar-head: "\e61f";
$icons-big: "\e620";
$icons-mega: "\e621";
$icons-quest: "\e622";
$icons-star: "\e623";
$icons-win: "\e624";
$icons-valuable-tick: "\e61d";
$icons-eye-open: "\e61c";
$icons-eye-closed: "\e61b";
$icons-pending: "\e616";
$icons-money-stack: "\e62a";
$icons-alert: "\e62b";
$icons-allseeingeye: "\e627";
$icons-arrow-down: "\e628";
$icons-arrow-left-curved: "\e90c";
$icons-arrow-left: "\e600";
$icons-arrow-right-curved: "\e90d";
$icons-arrow-right: "\e601";
$icons-arrow-up: "\e90e";
$icons-avatar-naked: "\e613";
$icons-avatar: "\e602";
$icons-belt: "\e62c";
$icons-bill-list: "\e603";
$icons-bill-minus: "\e604";
$icons-bill-plus: "\e605";
$icons-briefcase: "\e618";
$icons-chat-bubble: "\e90f";
$icons-christmas-tree: "\e90a";
$icons-cog: "\e909";
$icons-coupon: "\e908";
$icons-cross: "\e606";
$icons-diamond: "\e910";
$icons-direction-down: "\e612";
$icons-direction-right: "\e607";
$icons-direction-up: "\e61a";
$icons-dribbble: "\e911";
$icons-e-mail: "\e912";
$icons-exclamation-mark: "\e615";
$icons-eye: "\e913";
$icons-facebook: "\e914";
$icons-fullscreen: "\e915";
$icons-googleplus: "\e90b";
$icons-guage: "\e608";
$icons-heart: "\e609";
$icons-info: "\e617";
$icons-instagram: "\e916";
$icons-level: "\e619";
$icons-linkedin: "\e917";
$icons-list: "\e60a";
$icons-logout: "\e918";
$icons-menu: "\e60b";
$icons-mobile: "\e919";
$icons-more: "\e60c";
$icons-pending2: "\e91a";
$icons-place: "\e91b";
$icons-planet: "\e904";
$icons-play: "\e60d";
$icons-rocket: "\e614";
$icons-search: "\e62e";
$icons-shapes: "\e91c";
$icons-skype: "\e91d";
$icons-tick: "\e60e";
$icons-trophy: "\e60f";
$icons-tumblr: "\e91e";
$icons-twitter: "\e91f";
$icons-valuable: "\e610";
$icons-vimeo: "\e920";
$icons-wheel: "\e921";
$icons-win2: "\e922";
$icons-youtube: "\e923";
$icons-lock: "\e924";

$icons: $icons-chat $icons-send $icons-question-mark-white $icons-question-mark-filled $icons-triple-big-win $icons-triple-win $icons-prize $icons-playforfun $icons-back-button $icons-keypad $icons-bells $icons-bell $icons-arrows-spin $icons-avatar-head $icons-big $icons-mega $icons-quest $icons-star $icons-win $icons-valuable-tick $icons-eye-open $icons-eye-closed $icons-pending $icons-money-stack $icons-alert $icons-allseeingeye $icons-arrow-down $icons-arrow-left-curved $icons-arrow-left $icons-arrow-right-curved $icons-arrow-right $icons-arrow-up $icons-avatar-naked $icons-avatar $icons-belt $icons-bill-list $icons-bill-minus $icons-bill-plus $icons-briefcase $icons-chat-bubble $icons-christmas-tree $icons-cog $icons-coupon $icons-cross $icons-diamond $icons-direction-down $icons-direction-right $icons-direction-up $icons-dribbble $icons-e-mail $icons-exclamation-mark $icons-eye $icons-facebook $icons-fullscreen $icons-googleplus $icons-guage $icons-heart $icons-info $icons-instagram $icons-level $icons-linkedin $icons-list $icons-logout $icons-menu $icons-mobile $icons-more $icons-pending2 $icons-place $icons-planet $icons-play $icons-rocket $icons-search $icons-shapes $icons-skype $icons-tick $icons-trophy $icons-tumblr $icons-twitter $icons-valuable $icons-vimeo $icons-wheel $icons-win2 $icons-youtube $icons-lock;
$iconNames: icons-chat icons-send icons-question-mark-white icons-question-mark-filled icons-triple-big-win icons-triple-win icons-prize icons-playforfun icons-back-button icons-keypad icons-bells icons-bell icons-arrows-spin icons-avatar-head icons-big icons-mega icons-quest icons-star icons-win icons-valuable-tick icons-eye-open icons-eye-closed icons-pending icons-money-stack icons-alert icons-allseeingeye icons-arrow-down icons-arrow-left-curved icons-arrow-left icons-arrow-right-curved icons-arrow-right icons-arrow-up icons-avatar-naked icons-avatar icons-belt icons-bill-list icons-bill-minus icons-bill-plus icons-briefcase icons-chat-bubble icons-christmas-tree icons-cog icons-coupon icons-cross icons-diamond icons-direction-down icons-direction-right icons-direction-up icons-dribbble icons-e-mail icons-exclamation-mark icons-eye icons-facebook icons-fullscreen icons-googleplus icons-guage icons-heart icons-info icons-instagram icons-level icons-linkedin icons-list icons-logout icons-menu icons-mobile icons-more icons-pending2 icons-place icons-planet icons-play icons-rocket icons-search icons-shapes icons-skype icons-tick icons-trophy icons-tumblr icons-twitter icons-valuable icons-vimeo icons-wheel icons-win2 icons-youtube icons-lock;