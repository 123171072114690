.page-play {
    position: relative;

    .balance-container {
        border-bottom: 1px solid palette($grey);
    }

    .play-options {
        position: relative;
        min-height: 150px;
    }

    .game-thumb {
        width: 110px;
        height: 110px;
        background-size: cover;
        background-position: 50%;
        border-radius: 50%;
        overflow: hidden;

        .game-logo {
            width: 100%;
        }
    }

    .game-button-text-spacer {
        width: 100%;
    }

    .zero-width {
        width: 0;
    }

}
