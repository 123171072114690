.fade {
    transition: top 300ms ease;
    @include position(fixed, 0, 0, 0, 0);
    background-color: rgba(palette($grey, lighter), 0.9);
    z-index: $z-index-modals-fade;
}

.modal-context {
    transition: left 300ms ease, top 300ms ease;
    z-index: $z-index-modals;
    @include position(fixed, 0, 0, 0, 0);
    @include respond-with($widths-menu, padding-left);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal {
    font-size: $default-font-size;
    transition: all 300ms ease;
    background: $white;
    display: flex;
    flex-direction: column;
    position: relative;
    @include respond-with($widths-modal, width);
    @include respond-with($heights-modal, min-height);

    &.big {
        @include respond-with($widths-modal-large, width);
        @include respond-with($heights-modal-large, height);
    }

    .close {
        padding: 10px; // give the icon a better touch area
        margin-right: -10px;
        display: block;
    }

    .choices a {
        margin-top: $gutter-vertical / 2;

        :first-child {
            margin-top: 0;
        }
    }

    @include respond-to(tablet) {

        border-radius: 5px;

        .modal-header {

            border-radius: 5px 5px 0 0;
        }

        .close {

            position: fixed;
            top: $gutter-horizontal;
            right: $gutter-horizontal;
            background-color: palette($grey);
            border-radius: 5px;
            margin: 0;
            color: $white;
            padding: 6px;

        }

        .modal-header {
            text-align: center;
        }

    }
}

.modal-header {
    padding: 0 $gutter-horizontal;
    color: $white;

    @include respond-with($heights-modal-header, height);
    background-color: palette($blue, light);
    vertical-align: middle;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include font($bold);

    &.alert {
        background-color: palette($orange);
    }

}

.modal-content {
    flex: 1 1 0;

    &.fit {
        @include position(absolute, 0, 0, 0, 0);
        @include respond-with($heights-modal-header, top);
        overflow: hidden;
    }
}

// alerts
.alert-context {
    z-index: $z-index-alerts;
}

.jurisdiction-alert {
    @include position(fixed, 0, 0, 0, 0);
    z-index: $z-index-alerts;
    width: 100%;
    height: 100%;
    display: flex;
    background: palette($grey, darkest);
    align-items: center;
    flex-direction: column;

    .modal-content {
        padding: 10%;
    }
}
