.modal-setup-touchid {

    .touchid-image {
        position: relative;
        height: 80px;

        img {
            height: 80px;
            position: absolute;
            opacity: 0;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: opacity 500ms ease;

            &.touchid-image-base {
                opacity: 1;
            }
        }
    }

    .success {
        .touchid-image img {

            &.touchid-image-base {
                opacity: 0;
            }

            &.touchid-image-success {
                opacity: 1;
            }
        }
    }

    .failure {
        .touchid-image img {

            &.touchid-image-base {
                opacity: 0;
            }

            &.touchid-image-failed {
                opacity: 1;
            }
        }
    }
}
