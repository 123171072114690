@import "toolkit";
@import "fontIcons";

[class^='icons-'],
[class*=' icons-'] {
    font-family: 'casumo-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: $base-icon-font-size;
    vertical-align: middle;

    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@for $i from 1 through length($icons) {
    $icon: nth($icons, $i);
    $iconName: nth($iconNames, $i);

    .#{$iconName}:before {
        content: $icon;
    }
}
