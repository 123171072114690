.markdown {
    line-height: 1.6;

    ul {
        margin: $gutter 0;
        list-style-type: disc;
        list-style-position: outside;
        padding-left: $gutter;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    * + p {
        margin-top: $gutter;
    }
}