@import 'brands/casumo/helpers';

.block {
    width: 100%;
}

// Scroll helpers - only use these to add scrolling to elements
.scroll-y {
    overflow-y: auto;
    @include touch-scroll;
}

.scroll-x {
    overflow-x: auto;
    @include touch-scroll;
}

.fit {
    @include position(absolute, 0, 0, 0, 0);
}

.absolute-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-horizontal {
    display: flex;
}

.flex-vertical {
    display: flex;
    flex-direction: column;

    > * {
        position: relative;
    }
}

.fj-space-between {
    justify-content: space-between;
}

.fj-space-around {
    // scss-lint:disable DuplicateProperty
    justify-content: space-between; //Android 2.3 flex spec fallback
    justify-content: space-around;
    // scss-lint:enable DuplicateProperty
}

.fj-begin {
    justify-content: flex-start;
}

.fj-center {
    justify-content: center;
}

.fj-end {
    justify-content: flex-end;
}

.spaced-row {
    // scss-lint:disable DuplicateProperty
    display: flex;
    justify-content: space-between; //Android 2.3 flex spec fallback
    justify-content: space-around;
    align-items: center;
    // scss-lint:enable DuplicateProperty
}

.spread-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.media-flex {
    display: flex;
    align-items: center;
}

.media-flex-image {
    margin-right: 1rem;
}

.media-flex-content {
    flex: 1;
}

.pad {
    padding: $gutter-vertical $gutter-horizontal;
}

.pad-big {
    padding: ($gutter-vertical * 2) ($gutter-horizontal * 2);
}

.pad-small {
    padding: ($gutter-vertical / 2) ($gutter-horizontal / 2);
}

.pad-mini {
    padding: ($gutter-vertical / 4) ($gutter-horizontal / 4);
}

.pad-top {
    padding-top: $gutter-vertical;
}

.pad-bottom {
    padding-bottom: $gutter-vertical;
}

.h-pad {
    padding-left: $gutter-horizontal;
    padding-right: $gutter-horizontal;
}

.v-pad {
    padding-top: $gutter-vertical;
    padding-bottom: $gutter-vertical;
}

.pad-top-small {
    padding-top: $gutter-vertical/2;
}

.h-pad-big {
    padding-left: $gutter-horizontal * 1.5;
    padding-right: $gutter-horizontal * 1.5;
}

.h-pad-small {
    padding-left: $gutter-horizontal / 2;
    padding-right: $gutter-horizontal / 2;
}

.v-pad-big {
    padding-top: $gutter-vertical * 1.5;
    padding-bottom: $gutter-vertical * 1.5;
}

.pad-left {
    padding-left: $gutter-horizontal;
}

.pad-right {
    padding-right: $gutter-horizontal;
}

.pad-right-small {
    padding-right: $gutter-horizontal / 2;
}

.pad-left-small {
    padding-left: $gutter-horizontal / 2;
}

.v-gutter-mini {
    margin-top: $gutter-vertical / 4;
    margin-bottom: $gutter-vertical / 4;
}

.v-gutter-small {
    margin-top: $gutter-vertical / 2;
    margin-bottom: $gutter-vertical / 2;
}

.v-gutter {
    margin-top: $gutter-vertical;
    margin-bottom: $gutter-vertical;
}

.h-gutter {
    margin-left: $gutter-horizontal;
    margin-right: $gutter-horizontal;
}

.h-gutter-small {
    margin-left: $gutter-horizontal / 2;
    margin-right: $gutter-horizontal / 2;
}

.gutter-top {
    margin-top: $gutter-vertical;
}

.gutter-top-big {
    margin-top: $gutter-vertical * 2;
}

.gutter-bottom {
    margin-bottom: $gutter-vertical;
}

.gutter-bottom-big {
    margin-bottom: $gutter-vertical * 2;
}

.gutter-small {
    margin-top: $gutter-vertical / 2;
    margin-bottom: $gutter-vertical / 2;
}

.gutter-top-small {
    margin-top: $gutter-vertical / 2;
}

.gutter-bottom-small {
    margin-bottom: $gutter-vertical / 2;
}

.gutter-top-mini {
    margin-top: $gutter-vertical / 4;
}

.gutter-bottom-mini {
    margin-bottom: $gutter-vertical / 4;
}

.gutter-right {
    margin-right: $gutter-horizontal;
}

.gutter-right-small {
    margin-right: $gutter-horizontal / 2;
}

.gutter-right-mini {
    margin-right: $gutter-horizontal / 4;
}

.gutter-left {
    margin-left: $gutter-horizontal;
}

@for $i from 0 through 10 {
    .flex-#{$i} {
        flex: $i;
    }
}

.icon-14 {
    font-size: 16px !important;
}

.icon-24 {
    font-size: 24px !important;
}

.icon-32 {
    font-size: 32px !important;
}

.icon-64 {
    font-size: 64px !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.round-slightly {
    border-radius: 4px;
}

@include all-color-helpers(background-color, 'bg-');

.bg-img-centred {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
}

.bg-cover-center {
    background-position: center center;
    background-size: cover;
}

.margin-auto {
    margin: auto;
}

.margin-left-auto {
    margin-left: auto
}

.full-height {
    height: 100%;
}
