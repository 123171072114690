.tournament-campaign {

    padding: $gutter-vertical $gutter-horizontal 0;

    .next-tournaments {
        background: $white;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        max-width: 340px;
        margin-right: auto;
        margin-left: auto;

        .list-item {
            padding: em(11);
        }

        .tournament-label {
            font-size: em(16);
            margin-bottom: $gutter-vertical/4;
        }
    }
}

.tournament-points-info {

    .points-info-item {
        display: flex;
        min-height: em(63);
        align-items: center;
        padding: $gutter-horizontal/2;
    }

    .powerup-icon {
        width: 22%;
    }

    p {
        width: 78%;
    }

}

.who-it-works {
    padding-top: 160px;

    .buttons{
        .button{
            width:200px;
        }
    }
}

.reel-races-buttons{
    text-align: center;
    padding: 2em 0 2em;
    border-top:1px solid #eff6f6;

    .button{
        width:200px;
    }
}


.tournament-tracker {

    .tournament-countdown {
        font-size: em(16);
    }

    .tournament-details-box {
        background: $white;
        border-radius: 4px;
        width: 100%;
        margin: auto;
    }

    .icons-box {
        width: 60px;
        border-left: 1px solid palette($grey, lighter);

        .border-top {
            border-top: 1px solid palette($grey, lighter);
        }
    }

    .tournament-details {

        .stats {
            align-items: center;
            justify-content: center;

            .icon-14 {
                margin-right: 0.3em;
            }
        }

        .rank, .points {
            border-right: 1px solid palette($grey, lighter);
        }
    }
}

.tournament-card {

    .tournament-actions a {
        margin-right: $gutter-horizontal;

        &:last-child {
            margin-right: 0;
        }
    }

    // &.joined .tournament-actions a {
    //     margin-right: 0;
    // }

    .starts-when {
        font-size: $font-size-large;
    }

    .sub-info {
        font-size: $font-size-small;
        color: palette($grey);
    }

    .tournament-hints ul {
        text-align: left;
        display: inline-block;
        list-style-type: disc;

        color: palette($green, light)
    }

    &.promoted {
        color: palette($yellow);

        .sub-info {
            color: palette($yellow, light);
        }

        .tournament-hints ul {
            color: palette($orange, light);
        }
    }
}

.tournament-schedule {

    li {
        position: relative;
    }

    .time {
        min-width: 65px;
        text-align: right;
        @include font($bold-italic);
    }

    .points {
        font-style: italic;
    }

    .details .time {
        text-align: left;
    }

    .hide-element {
        display: none;
    }

    li:first-child .indicator-container:before,
    li:last-child .indicator-container:after {
        background: inherit;
    }

    .indicator-container {
        min-width: 40px;

        &:after,
        &:before {
            display: block;
            content: '';
            background: palette($grey, 'lighter');
            position: absolute;
            width: 2px;
            margin-left: 19px;
        }

        &:after {
            top: 50%;
            bottom: 0;
            margin-top: 5px;
        }

        &:before {
            top: 0%;
            bottom: 50%;
            margin-bottom: 5px;
        }

        &.big-indicator {
            &:after {
                margin-top: 10px;
            }

            &:before {
                margin-bottom: 10px;
            }
        }
    }

    .indicator {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 15px;
        &.big-indicator {
            width: 20px;
            height: 20px;
            margin: 0 10px;
        }
    }

}

.tournament-details-dialog {

    .time {
        color: palette($green);
        font-size: em(32);
        text-align: center;
    }

    .remaining-spins {
        color: palette($grey, darker);
        font-weight: bold;
    }

}

.blue-banner-icon-container {
    margin: auto $gutter-horizontal auto auto;
}

.tournament-standings {

    .booster {

        position: relative;
        margin: 0 auto;
        width: 64px;
        height: 64px;
        text-align: center;

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }

        .icon {
            font-size: 32px;
            line-height: 64px;
        }

        &.triple-win-booster {

            circle {

                fill: none;
                stroke: palette($grey);
                stroke-width: 15;
                stroke-linecap: round;
                stroke-miterlimit: 10;

                &:nth-child(2) {
                    stroke: palette($green);
                    stroke-dasharray: 0% 300%;
                    transform: rotate(-90deg);
                    transform-origin: 50% 50%;
                }
            }

            &[data-streak="1"] {

                circle:nth-child(2) {
                    stroke-dasharray: 100% 300%;
                }

            }

            &[data-streak="2"] {

                circle:nth-child(2) {
                    stroke-dasharray: 200% 300%;
                }

            }

        }

        &.double-big-win-booster {

            circle {

                fill: none;
                stroke: palette($grey);
                stroke-width: 15;
                stroke-linecap: round;
                stroke-miterlimit: 10;
                stroke-dasharray: 128% 300%;
                transform-origin: 50% 50%;

                &:nth-child(1) {
                    transform: rotate(-82deg);
                }

                &:nth-child(2) {
                    transform: rotate(98deg);
                }

            }

            &[data-streak="1"], &[data-streak="2"] {

                circle {
                    &:nth-child(1) {
                        stroke: palette($green);
                    }
                }

            }

            &[data-streak="2"] {

                circle {
                    &:nth-child(2) {
                        stroke: palette($green);
                    }
                }

            }
        }

        &.mega-win-booster {

            path {
                fill: none;
                stroke: palette($grey);
                stroke-width: 15;
                stroke-linecap: round;
                stroke-miterlimit: 10;
            }

        }
    }

    .tournament-prizes {
        img:nth-child(1) {
            width: 54px;
            height: 54px;
            margin-left: -45px;
        }

        img:nth-child(2) {
            width: 54px;
            height: 54px;
            margin-left: $gutter-horizontal * 2;
        }

        img:nth-child(3) {
            width: 64px;
            height: 64px;
            margin-left: -112px;
            margin-top: -5px;
        }
    }

    .leaderboard {
        text-align: left;
        padding-top: 10px;
    }

    .board-item, .board-header {
        padding: 15px $gutter-horizontal * 1.5;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .board-header {
        padding-bottom: 0;
        padding-top: 0;
    }

    &.finished {
        .board-item, .board-header {
            padding-left: $gutter-horizontal;
            padding-right: $gutter-horizontal;
        }
    }

    .prize {
        text-align: center;
        width: 30%;
    }

    .rank {
        width: 10%;
        text-align: right;
        position: relative;
    }

    .player {
        width: 45%;
        position: relative;
        text-overflow: ellipsis;
    }

    .spins-badge {
        padding: 2px 5px;
        border-radius: 50px;
        min-width: 60px;
    }

    .score {
        width: 15%;
        text-align: right;
        display: inline-block;
        color: palette($grey);
        position: relative;
    }

    .you,
    .you .score {
        background: #ffc930;
        color: #965c30;
        font-weight: bold;
        font-family: Circular-Pro-Black;
        position: relative;
        overflow: hidden;
    }

}

.reel-races {
    .modal-tabs .selected-x {
        border-bottom: 2px solid palette($grey, darker);
    }
}
