#splash {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: $z-index-splash;
    background: no-repeat palette($orange, dark);
    background-size: cover;
    background-position-y: 75%;
    text-align: center;

    .logo {
        max-width: 32%;
        max-height: 6em;
        vertical-align: top;
    }

    .close-button {
        position: absolute;
        top: 1em;
        right: 1em;
    }

    .tagline, .welcome-bonus-text {
        margin: 0 auto;
        width: 70%;
        font-family: $font-bold;
    }
    .tagline{
        font-size: 18px;
    }
    .welcome-bonus-text{
        margin-bottom: 20px;
        color:palette($grey, lightest);

        *{
            font-family: $font-light;
            font-size: 14px;
        }

    }

    .learn-more-link{
        color:#222;
        text-decoration:underline;
        margin-top:0.2em;
        display:block;
    }

    .button {
        width: 200px;
    }

    .foreground-image {
        max-width: 69%;
        max-height: 45%;
    }

    .bottom-section {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .payment-providers {
        width: 80%;
    }
}

@include respond-to(tablet-portrait) {
    #splash {
        .tagline {
            font-size: 2em;
        }

        .foreground-image {
            height: 33em;
        }

        .button {
            width: 350px;
            height: 4em;
        }
    }
}

@include respond-to(tablet-landscape) {
    #splash {
        background-position-y: 77%;

        .tagline {
            width: 45%;
            font-size: 2em;
        }

        .foreground-image {
            height: 20em;
        }

        .button {
            width: 390px;
            height: 3.5em;
            border-radius: 3em;
            font-size: 21px;
        }

        .payment-providers {
            width: 50%;
            margin: 0 auto;
        }
    }
}

@include respond-to(mobile-portrait-large) {
    #splash {

        .foreground-image {
            margin-top: 10px;
        }

        .welcome-bonus-text{
            margin-bottom: 10px;
        }

        .tagline{
            font-size: 20px;
        }
    }
}


@include respond-to(mobile-portrait) {
    #splash {
    }
}

@include respond-to(mobile-portrait-short) {
    #splash {
        .foreground-image {
            max-height: 25%;
            margin-top: 10px;
        }

         .tagline{
            font-size: 16px;
        }

        .welcome-bonus-text{
            margin-bottom: 10px;
        }

         .button{
            width: 140px;
        }

        .buttons div{
            display: inline-block;
        }

        .buttons div:first-child{
            margin-right: 1em;
        }
    }
}


@include respond-to(mobile-landscape) {
    #splash {

        background-size: 100% 100%;

        .logo{
            float: left;
            margin-left: 1em;
        }

        .foreground-image {
            max-height: 35%;
            margin-top: 10px;

        }
        .tagline-wrapper{
            width: 67%;
            float: left;
        }

        .tagline{
            display: inline-block;
            text-align: center;
            margin-top: 1em;
            width: 90%;
        }

        .payment-providers {
            width: 50%;
        }

        .button{
            min-height: 40px;
            width: 160px;
        }
        .learn-more-link{
            text-align: center;
        }

        .buttons div{
            display: inline-block;
        }

        .buttons div:first-child{
            margin-right: 1em;
        }
        .welcome-bonus-text{
            margin-bottom: 0;
        }
    }
}

@media (min-height: 569px) and (orientation: portrait){
    #splash .welcome-bonus-text {
        margin-bottom: 40px;
    }

    #splash .foreground-image {
        margin-top: 20px;
    }
}
