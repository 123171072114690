@import 'toolkit';

.pop-in {
    animation: pop-in #{map-get($transition-timings, pop-in)}ms ease-in-out;
    transform-origin: 50% 50%;
}

.pop-out {
    transform-origin: 50% 50%;
    animation: pop-out #{map-get($transition-timings, pop-out)}ms ease-out forwards;
}

.fade-in {
    animation: fade-in #{map-get($transition-timings, fade-in)}ms ease forwards;
}

.fade-out {
    animation: fade-out #{map-get($transition-timings, fade-out)}ms ease forwards;
}

// Animations

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes pop-in {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pop-out {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(0.9);
    }
}

@keyframes fade-in-down {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-out-down {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(15px);
    }
}
