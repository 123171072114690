.page-payments {
    @include respond-to(tablet) {
        padding-top: $gutter-vertical;
    }

    .open-external-account {
        margin-top: $gutter-vertical;
        color: palette($grey);
        text-decoration: underline;
    }
}

.payments-toolbar {
    @include respond-with($heights-payments-toolbar, height);
    display: flex;
    font-size: $font-size-small;
    position: relative;
    z-index: 1;

    > * {
        background-color: palette($grey, lighter);
    }

    .payment-method-selector-container {
        flex: 1;
    }

    .payment-method-selector {
        @include respond-with($heights-payments-toolbar, height);
        padding-left: $gutter-horizontal / 2;
        padding-right: $gutter-horizontal / 2;
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;

        @include respond-to(tablet) {
            .icon {
                color: palette($grey);
            }
        }
    }

    .selected-deposit-bonus {
        border-left: 0;
        width: 16%;
        @include respond-with($heights-payments-toolbar, height);
        @include respond-with($gutters-payments-bar, margin-left);

        img {
            height: 62%;
        }
    }
}

.receipt {
    overflow-y: auto;

    dl {
        overflow: hidden;
    }

    dt,
    dd {
        padding: ($gutter-vertical / 4) 0;
    }

    dt {
        float: left;
        clear: left;
    }

    dd {
        float: right;
    }

}

.actions,
.receipt-statement {
    padding: $gutter-vertical ($gutter-horizontal * 2);
}

.receipt-statement {
    font-size: $font-size-small;
    background-color: palette($grey);
    text-align: center;
    color: $white;

    &--alert {
        background-color: palette($orange);
    }

    &--success {
        background-color: palette($green);
    }
}

.cash-wizard {
    @include respond-to(mobile) {
        @include position(absolute, 0, 0, 0, 0);
        @include respond-with($top-bar-heights, top);
        @include respond-with($heights-payments-toolbar, margin-top);
    }

    .step {
        @include respond-to(mobile) {
            @include position(absolute, 0, 0, 0, 0);
        }

        @include respond-to(mobile-portrait) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .name-step .suggested-name {
        display: inline-block;
        font-size: $font-size-large;
        color: palette($grey);
        border-bottom: 2px dotted palette($grey);
        padding: 0 $gutter-horizontal / 4;
    }

    .amount-step {
        .display-value {
            font-size: $font-size-larger;
            color: palette($green, light);
            border-bottom: 1px solid palette($green, light);

            &.is-bad {
                color: palette($red);
                border-color: palette($red);
            }

            &:empty {
                &::after {
                    color: palette($green, light);
                    content: attr(data-currency-symbol);
                }
            }

        }

        .amount-message-box {
            height: 2em;
        }

        // This is a bug-fix for layout problems on android
        // When coming back from games, the height of the page
        // is calculated wrongly and the button is hidden.
        .fixed-bottom-mobile {
            @include respond-to(mobile) {
                position: fixed;
                bottom: 0;
            }

        }
    }

    .basic-fields-step {
        min-height: 100%;
        position: relative;
        padding-bottom: 70px;

        button {
            position: absolute;
            bottom: 0;
        }
    }
}

