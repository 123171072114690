.phone-number-keypad {
    border-bottom: solid 2px #eff6f6;
    padding-bottom: $gutter-vertical / 4;

    .light & {
        color: $white;
        border-color: palette($grey);

        &:focus {
            border-color: $white;
        }
    }

    .is-good {
        color: palette($green);
    }

    .is-bad {
        color: palette($red);
    }

}