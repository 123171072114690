@import 'toolkit';

body {
    color: $default-text-color;
}

a {
    color: palette($blue);
}

br {
    height: 0;
}

*:not(input):not(textarea) {
    user-select: none;
    touch-callout: none;
    @include disable-tap-highlight;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input[type="checkbox"] {
    vertical-align: 1px;
}

* {
    @include flicker-fix;
}
