.icons-custom-menu {
    width: 48px;
    height: 48px;
    text-align: center;
    position: relative;

    span,
    span::before,
    span::after {
        content: '';
        display: block;
        height: 4px;
        width: 18px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 0;
    }

    span {
        left: 50%;
        margin: -2px 0 0 -9px;
    }

    span::before {
        margin-top: -10px;
    }

    span::after {
        margin-top: 6px;
    }
}

.main-menu {
    background: palette($grey, lighter);

    .divider {
        border-top: 2px solid palette($grey, lighter);
        margin: 0 (-$gutter-horizontal);
    }

    .icons-custom-menu {
        position: absolute;
        z-index: 2;
        top: 38px;
        left: 10px;
        transform: translateY(-50%);

        @include respond-to(mobile-landscape) {
            top: 32px;
        }

        @include respond-to(tablet) {
            display: none;
        }

        span,
        span::before,
        span::after {
            background: palette($teal, dark);
        }
    }
}

.main-nav {
    background: palette($grey, darker);

    .casumo-logo {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 120px;
        min-height: 75px;
        width: 100%;
    }

    li {
        position: relative;
        overflow: hidden;
    }

    .nav-icon {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: palette($grey, darkest);
        margin-bottom: 5px;

        .avatar {
            height: 34px;
            width: 34px;
        }
    }

    .nav-item a {
        @include font($bold);
        @include respond-with($heights-menu-item, height);
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        transition: color 0.2s ease;

        .nav-label {
            display: block;
        }

        @include respond-to(tablet) {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 2%;
                height: 100%;
                transition: width 100ms ease-in;
            }
        }
    }

    .nav-item-home a {
        @include respond-with($top-bar-heights, height);
    }

    .nav-item-player a {
        color: palette($teal);

        &::after {
            background: palette($teal);
        }
    }

    .nav-item-cash a {
        color: palette($yellow);

        &::after {
            background: palette($yellow);
        }
    }

    .nav-item-games a {
        color: palette($orange);

        &::after {
            background: palette($orange);
        }
    }

}

.nav-item a {
    * {
        position: relative;
        z-index: 1;
    }

    &.selected {
        color: palette($grey, darker);

        &::after {
            transition-delay: 0;
            width: 100%;
        }
    }
}

@include respond-to(mobile) {
    .main-nav .nav-icon {
        margin-bottom: 2px;
    }

    .nav-item-home a,
    .nav-item-player a {
        background-color: palette($teal);

        &:active {
            background-color: palette($teal, light);
        }
    }

    .nav-item-cash a {
        background-color: palette($yellow);

        &:active {
            background-color: palette($yellow, light);
        }
    }

    .nav-item-games a {
        background-color: palette($orange);

        &:active {
            background-color: palette($orange, light);
        }
    }

    .main-nav .nav-item a {
        color: $default-text-color;
    }
}

.sub-nav {
    .nav-item a {
        @include font($bold);
        @include respond-with($heights-sub-menu-item, height);
        color: palette($grey);
        display: flex;
        align-items: center;
        justify-content: center;

        &:active {
            color: palette($grey, dark);
            background-color: palette($grey, light);
        }
    }
}
