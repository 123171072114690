@mixin greyscale($opacity: 1, $on: true) {
    @if $on == true {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+, IE10 */
        -webkit-filter: grayscale(100%); /* Google Chrome & Webkit Nightlies */
        -moz-opacity: $opacity;
        opacity: $opacity;
    }
    @else {
        filter: none;
        -webkit-filter: grayscale(0%);
        -moz-opacity: 1;
        opacity: 1;
    }
}

@mixin flicker-fix() {
    -webkit-backface-visibility: hidden;
}

@mixin css-accelerate() {
  transform: translate3d(0,0,0);
}

/* Layout */

@mixin position($position, $top, $right, $bottom, $left) {
    position: $position;
    top: $top;
    left: $left;
    bottom: $bottom;
    right: $right;
}

/* Fonts */

@mixin font($font) {
    font-family: nth($fonts, $font);
    font-style: normal;
    font-weight: 400;
}

@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


//Printing helper classes
//--------------------------------------------------------------------------------------------
//Parameters:
//
//    - Property: Any css color property, like `color` or `background-color`
//    - Prefix (optional): Prepend a string to the class name
//    - Separator (optional): Charater between the color and the shade
//
//Usages:
//
//    @include all-color-helpers(color, "color-");
//
//    Prints:
//
//    .color-grey { color: ... }
//    .color-grey-lightest { color: ... }
//    .color-grey-lighter { color:  ... }
//    ... etc
//
//    Notes:
//    - It will print ".color-grey" rather than ".color-grey-base".

@mixin all-color-helpers($property: "color", $prefix: "", $separator: "-") {
    //Loop and print all colors
    @each $colors in $palette {
        //Get color name to generate the class name
        $colorIndex: index($palette, $colors);
        $colorName: nth($colorNames, $colorIndex);
        //Print the class name
        .#{$prefix}#{$colorName} {
            //Print the color
            #{$property}: nth($colors, $shadeBaseIndex) !important;
        }
        //Loop each shade
        @each $color in $colors {
            $shadeIndex: index($colors, $color);
            //Skip base shade
            @if $shadeIndex != $shadeBaseIndex {
                //Get shade name to generate the class name
                $shadeName: nth($shadeNames, $shadeIndex);
                //Construct the selector
                .#{$prefix}#{$colorName}#{$separator}#{$shadeName} {
                    //Print the color
                    #{$property}: nth($colors, $shadeIndex) !important;
                }
            }
        }
    }
    //Workaround for the white alias for now:
    .#{$prefix}white {
        #{$property}: $white !important;
    }
}

/* Touch */
@mixin touch-scroll($enabled: true) {
    @if $enabled {
        -webkit-overflow-scrolling: touch;
    }
    @else {
        -webkit-overflow-scrolling: none;
    }
}

@mixin tap-highlight($color) {
    -webkit-tap-highlight-color: $color;
}

@mixin disable-tap-highlight() {
    @include tap-highlight(rgba(0,0,0,0));
}