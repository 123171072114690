
@mixin vendor($name, $argument) {
    -webkit-#{$name}: $argument;
    -moz-#{$name}: $argument;
    -ms-#{$name}: $argument;
    -o-#{$name}: $argument;
    #{$name}: $argument;
}

@mixin prefix($name, $argument) {
    -webkit-#{$name}: $argument;
    -moz-#{$name}: $argument;
    -ms-#{$name}: $argument;
    -o-#{$name}: $argument;
    #{$name}: $argument;
}

@mixin linear-gradient($from, $to) {
    background: $from;
    background: -moz-linear-gradient($from, $to);
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0, $from),color-stop(1, $to));
    background: -webkit-linear-gradient($from, $to);
    background: -o-linear-gradient($from, $to);
    background: -ms-linear-gradient("top", $from, $to);
}

@mixin box-sizing($value: border-box) {
    -webkit-box-sizing: $value;
    -moz-box-sizing: $value;
    box-sizing: $value;
}

@mixin transition($values...) {
    @if length($values) == 0 {
        $values: all 0.3s ease;
    }
    -webkit-transition: $values;
    -moz-transition: $values;
    -o-transition: $values;
    -ms-transition: $values;
    transition: $values;
}

@mixin transform($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -o-transform: $value;
    -ms-transform: $value;
    transform: $value;
}

@mixin placeholder {
    &::-moz-placeholder { opacity: 1; @content }
    &:-moz-placeholder { opacity: 1; @content }
    &::-webkit-input-placeholder { @content }
    &:-ms-input-placeholder { @content }
}

@mixin selection {
    ::-webkit-selection { @content }
    ::-moz-selection { @content }
    ::selection { @content }
}

@mixin no-user-select {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}


/* Animations */

@mixin animation($values...) {
    -webkit-animation: $values;
    animation: $values;
};

@mixin keyframe($animation_name) {
    @-webkit-keyframes #{$animation_name} {
        @content;
    }

    @-moz-keyframes #{$animation_name} {
        @content;
    }

    @-o-keyframes #{$animation_name} {
        @content;
    }

    @keyframes #{$animation_name} {
        @content;
    }
}