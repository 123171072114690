@mixin generate-icons-state($color) {
    &:active {
        .more-info {
            .icon {
                color: palette($color);
                background-color: palette($color, dark);
                border-color: palette($color, dark);
            }
        }

        .dismiss-notifications:active + .more-info .icon {
            color: palette($color, dark);
            background-color: transparent;
        }
    }

    .dismiss-notifications,
    .more-info {
        transition: all 300ms ease;
        color: palette($color, dark);

        &:active .icon {
            color: palette($color);
            background-color: palette($color, dark);
            border-color: palette($color, dark);
        }
    }
}

.notifications {
    position: absolute;
    z-index: $z-index-notifications;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: palette($green);
    transform: translateY(100%);
    transition: all 150ms ease;

    .notifications-inner {
        position: relative;
        @include respond-with($widths-main-content, width);

        @include respond-to(tablet-portrait) {
            width: 100%;
        }

        margin: 0 auto;
    }

    @include generate-icons-state($green);

    .button {
        transition: all 300ms ease;
        color: palette($green);
    }

    &.alert {
        background-color: palette($orange);

        @include generate-icons-state($orange);

        .button {
            color: palette($orange);
        }
    }

    @include respond-with($widths-menu, left);

    &.active {
        transition: all 300ms ease;
        transform: translateY(0);
    }

    .dismiss-notifications,
    .more-info {
        position: absolute;
        top: 15px;
        z-index: 2;
    }

    .dismiss-notifications {
        right: 10px;
    }

    .more-info {
        left: 10px;
    }

    .notification-body,
    .button {
        margin-top: $gutter-vertical;
    }

    .notification-image {
        .icon {
            font-size: 48px;
        }

        min-height: 48px;
    }

    @include respond-to(tablet) {
        .notifications-inner {
            display: flex;
            justify-content: center;
        }

        .notification {
            padding-top: 65px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            > * {
                margin-left: $gutter-horizontal;

            }

            :first-child {
                margin-left: 0;
            }

            .notification-body {
                flex: 1;
                text-align: left;
            }

            .notification-body,
            .button {
                margin-top: 0;
            }
        }

        .dismiss-notifications {
            right: 50%;
            transform: translateX(150%);
        }

        .more-info {
            left: 50%;
            transform: translateX(-150%);
        }

    }
}

.notification-dialog {
    .notification-action {
        width: em(50);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: $font-size-smaller;
    }
}
