@import 'toolkit/functions';
@import 'mixins';

$block-height: 200px;

.dgrid-widget {
    display: flex;
    flex-wrap: wrap;

    .dgrid-block {
        min-height: $block-height;
        width: 50%;

        .left-side {
            width: 50%;
        }

        .right-side {
            width: 50%;
        }

        &.full-width-block {
            width: 100%;
        }

        .game-thumb {
            width: 135px;
            height: 135px;
            background-size: cover;
            background-position: 50%;
            border-radius: 50%;
            overflow: hidden;

            .game-logo {
                background-size: cover;
                height: 100%;
            }
        }

        // Lastplayed block
        .last-played-block {
            height: $block-height;
            background-color: palette($yellow);
        }

        // Valuable block
        .valuable-block {
            height: $block-height;
            color: $white;

            &.click-state {
                height: auto;
                min-height: $block-height;
            }

            .valuable-icon {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 64px;
            }

            .deposit-button {
                background-color: palette($green);
                border-color: palette($green);
                color: $white;
            }
        }

        // Reel races block
        .reel-races-block {
            height: $block-height;
            background-color: palette($orange);
            color: $white;

            .valuable-icon {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 64px;
            }
        }

        // Reel races big block
        .reel-races-big-block {
            height: $block-height;
            color: $white;

            .reel-races-logo {
                overflow: hidden;
                height: $block-height;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .number-box {
                width: 25px;
                line-height: 35px;
                text-align: center;
                display: inline-block;
                background: palette($teal, 'light');
                border-radius: 5px;
            }

            .number-box:nth-child(3) {
                background: none;
                width: 5px;
            }

            .left-side-60 {
                width: 60%;
            }

            .right-side-40 {
                width: 40%;
            }
        }

        // Trophies block
        .trophies-block {
            background-color: palette($blue);
            color: $white;
            height: $block-height;

            .valuable-icon {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 64px;
            }
        }

        // RecommendedGame Block
        .recommended-game-block {
            height: $block-height;
            background-color: palette($purple);

            &.alternative-bg-color {
                background-color: palette($yellow);
            }
        }

        // CuratedContent block
        .curated-content-block {
            height: 100%;

            .image {
                height: $block-height;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

            &.background-image {
                background-size: cover;
            }
        }

        // All Games block
        .all-games-block {
            height: $block-height;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            background-position-y: 54%;
        }

    }
}
