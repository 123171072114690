%fill-page {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

$hud-height: 50px;

.flex-horizontal,
.flex-vertical {
    .flex-item {
        flex: 1;
        display: flex;
    }
}

#hud {
    height: $hud-height;
    background-color: black;
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 3;

    button {
        background: none;
        border: none;
        float: left;
        border-radius: 0;

        &.active {
            border-bottom: 2px solid $white;
        }
        &.shop {
            float: right;
        }
        &.xpRandomMilestones,
        &.xpMilestones {

            .container-casumo {
                height: 50px;
                width: 45px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
            .random .status {
                align-self: flex-end;
            }
            .milestone .status {
                align-self: center;
            }
        }
    }
}

#game {
    z-index: 0;
    height: calc(100% - #{$hud-height});
    margin-bottom: $hud-height;
}

#shop-overlay {
    @extend %fill-page;
    text-align: center;
    z-index: 3;
    margin: 0 auto;
    height: auto;
    width: 500px;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    padding: 5px;

    @include respond-to(mobile-landscape) {
        width: 100%;
    }

    @include respond-to(mobile-portrait) {
        width: 100%;
    }

    .wrapper {
        background-color: $white;
        border-radius: 5px;
    }
}

#progression {
    @extend %fill-page;
    z-index: 2;

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
    }

    .progress-donut {

        @include respond-to(mobile-landscape) {
            width: 100px;
        }

        .level {
            top: 50%;
            transform: translateY(-50%);

            @include respond-to(tablet-landscape) {
                width: calc(32% + 15px);
                left: -16%;
            }
        }
    }

    .overview {
        height: 100%;

        > div {
            align-items: center;
            justify-content: center;
        }
        .belt-info {
            text-align: center;
        }
        .legend {
            font-size: 0.8em;

            > .flex-item {
                min-width: 100px;
            }
        }
        .progression {
            position: relative;
            display: inline-block;

            ul {
                color: $white;
                overflow: hidden;
                text-align: center;
                display: inline-block;
                border: 1px solid $white;
                padding: 6px 35px;
                border-radius: 30px;
                position: relative;

                li {
                    float: left;
                    margin-left: 35px;

                    &:first-child {
                        margin: 0;
                    }

                    &:last-child {
                        margin-right: 60px;
                    }
                }

                &.random {
                    li .icon {
                        visibility: hidden;
                    }
                }
            }
            .progress {
                position: absolute;
                top: 0;
                left: 0;
                height: 93%;
                width: 100%;
                border-radius: 30px;
            }
            .avatar {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(5%,-50%);

                .container-casumo {
                    height: 80px;
                    width: 70px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

    }

    .details {
        height: 100%;

        > div {
            align-items: center;
            justify-content: center;
        }
        ul {
            align-items: center;
            justify-content: center;
            flex: 1;
            flex-wrap: wrap;
            align-content: center;

            li {
                align-items: center;
                position: relative;

                &.avatar {
                    min-width: 100%;

                    @include respond-to(mobile-landscape) {
                        min-width: auto;
                    }
                }

                .avatar {
                    width: 100%;
                    min-height: 100px;
                }

                &.description {
                    align-items: flex-start;
                }

            }
        }
    }
}

#overlay {
    @extend %fill-page;
    background-color: rgba(0,0,0,0.9);
    height: calc(100% - #{$hud-height});
    margin-bottom: $hud-height;
    z-index: 2;

    .close {
        position: absolute;
        top: 15px;
        right: 15px;
        z-index: 5;
    }
}

div.shop {
    @extend %fill-page;

    .header {
        .wrapper {
            width: 100%;
            margin: 3em auto 0;
        }
        ul {
            width: 50%;
            margin: 0 auto;

            li {
                border-radius: 1.5em;
                align-items: center;
                justify-content: center;
                line-height: 3em;
                &.selected {
                    background-color: rgba(255,255,255,0.3);
                }
            }
        }
    }
    .content ul {
        align-items: center;
        justify-content: center;
        flex: 1;
        flex-wrap: wrap;
        align-content: center;

        .wrapper {
            width: 100%;
        }

        @include respond-to(mobile-portrait) {
            margin: 0 10%;
        }

        li {
            align-items: center;
            position: relative;

            .title {
                position: absolute;
                bottom: -2em;
                text-align: center;
                font-size: 0.7em;
                width: 100%;
            }
            &.description {
                @include respond-to(mobile-landscape) {
                    max-width: 50%;
                }
            }
            .cost {
                position: absolute;
                bottom: 10px;
                font-size: 0.8em;
                text-align: center;
                width: 100%;
            }
            &.item {
                border: 3px solid $white;
                margin: 10px 10px 3em 10px;
                border-radius: 10px;
                min-width: 100px;
                max-width: 100px;
                min-height: 100px;
                background-color: rgba(255,255,255,0.3);
                background-size: 50%;
                background-repeat: no-repeat;
                background-position: center 30%;
                flex: 1 0 auto;

                &.empty {
                    border-style: dotted;
                }
            }
        }
    }
}

.game-view {
    width: 100%;
    height: 100%;
    transition: width 0.2s ease;

    .transition-open & {
        width: 85%;
    }
}

.overlay-game-launcher {
    @extend %fill-page;
    z-index: 20;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: stretch;
    // align-content: stretch;
    background: palette($grey, 'darkest');
}

.overlay-game-launcher-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 22;
}

.center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


.page-layer {
    @extend %fill-page;
}
